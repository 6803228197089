import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"
import cara1 from "../../assets/images/Asset_1.svg"
import cara2 from "../../assets/images/Asset_2.svg"
import cara3 from "../../assets/images/Asset_3.svg"
import cara4 from "../../assets/images/Asset_4.svg"
import cara5 from "../../assets/images/Asset_5.svg"


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
class HistorialUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      clases: [],
      sizePerPage: 10
    }
  }

  listarClases(){
    const token = JSON.parse(localStorage.getItem("token"))
    
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    //console.log("ano: ",ano, "/mes: ", mes, "/dia: ",dia)
    const hasta = ano+"-"+mes+"-"+dia;
    //const hasta = "2021-12-12"

    axios.get(API_BASE_URL+`class/me/2021-01-01/${hasta}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        this.setState({clases: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  formatFecha = (cell, row, rowIndex, formatExtraData) => {
    var fecha ='';
    if(row.date!=null){
      var fecha = row.date.substr(0,10); 
      //console.log("fecha: ",row.User_Has_Patient[0].dateofbirth);
    }
    return (
      <>{fecha}</>
    );
  };

  componentDidMount(){
    this.listarClases();
  }

  formatAsistencia = (cell, row, rowIndex, formatExtraData) => {
    //0:no asistio, 1:asistio
    if(row.Classdate_Has_Historical[0].attendance==0){
      var asistencia = "No";
    }
    else{
      var asistencia = "Si";
    }
    return (
      <>{asistencia}</>
    );
  };

  formatEvaluacion1 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.Classdate_Has_Evaluation[0]){
      evaluacion = "Respuesta: "+ row.Classdate_Has_Evaluation[0].point_id
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };
  formatEvaluacion2 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.Classdate_Has_Evaluation[1]){
      var cara = ''
      if(row.Classdate_Has_Evaluation[1].point_id==1){
        cara = cara1
      }
      if(row.Classdate_Has_Evaluation[1].point_id==2){
        cara = cara2
      }
      if(row.Classdate_Has_Evaluation[1].point_id==3){
        cara = cara3
      }
      if(row.Classdate_Has_Evaluation[1].point_id==4){
        cara = cara4
      }
      if(row.Classdate_Has_Evaluation[1].point_id==5){
        cara = cara5
      }
      evaluacion = <img
          src={cara}
          alt=""
          height="19"
        />
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };

  formatTiempo= (cell, row, rowIndex, formatExtraData) => {
    var tiempo=0
    if(row.Classdate_Has_Historical[0].time>0){
      tiempo = Math.round(row.Classdate_Has_Historical[0].time/60)
    }
    return (
      <>{tiempo+" minutos"}</>
    );
  };

  render() {
    const columns = [{
      dataField: 'classdate_id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'Classdates_belongsTo_class.name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'Classdates_belongsTo_class.description',
      text: 'Detalle',
      headerStyle: () => {
        return { maxWidth: '10px' };
        },
      sort: true
    }, {
      dataField: 'Classdate_Has_Historical[0].attendance',
      text: 'Asistencia',
      formatter: this.formatAsistencia,
      sort: true
    }, {
      dataField: 'Classdate_Has_Historical[0].time',
      text: 'Tiempo asistido',
      formatter: this.formatTiempo,
      sort: true
    }, {
      dataField: 'Classdate_Has_Evaluation2',
      text: 'Pregunta 1',
      formatter: this.formatEvaluacion1,
      sort: true
    }, {
      dataField: 'Classdate_Has_Evaluation1',
      text: 'Pregunta 2',
      formatter: this.formatEvaluacion2,
      sort: true
    }, {
      dataField: 'date',
      text: 'Fecha',
      formatter: this.formatFecha,
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'classdate_id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      //totalSize: clases.length, // replace later with size(customers),
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Historial</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Historial" breadcrumbItem="Historial" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='classdate_id'
                      columns={columns}
                      data={this.state.clases}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='classdate_id'
                          columns={columns}
                          data={this.state.clases}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder={"Buscar"}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"classdate_id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      //rowEvents={this.eventosRowOnClick} 
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default HistorialUser