import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Redirect } from "react-router-dom"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class PacientesLista extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      usuarios: [],
      sizePerPage: 10,
      ver: false,
      editar: false
    }
  }

  listarPacientes(){
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})

    axios.get(API_BASE_URL+`patient`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data.data);
        this.setState({usuarios: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  formatfechaNacimiento = (cell, row, rowIndex, formatExtraData) => {
    var fecha_nacimiento ='';
    if(row.User_Has_Patient[0].dateofbirth!=null){
      var fecha_nacimiento = row.User_Has_Patient[0].dateofbirth.substr(0,10); 
      //console.log("fecha: ",row.User_Has_Patient[0].dateofbirth);
    }
    return (
      <>{fecha_nacimiento}</>
    );
  };

  formatEstado = (cell, row, rowIndex, formatExtraData) => {
    //1:en espera, 2:activado, 3:desactivado
    if(row.userstatus_id==1){
      var estado = "En espera";
    }
    else{
      if(row.userstatus_id==2){
        var estado = "Activado";
      }
      else{
        var estado = "Desactivado";
      }
    }
    return (
      <>{estado}</>
    );
  };

  editarPaciente(id) {
    this.setState({ editar: true });
    this.setState({ paciente_id: id });
  }

  cambiarEstadoPaciente(id) {
    //1:en espera, 2:activado, 3:desactivado
    var textBoton = document.getElementById("botonId_"+id).innerHTML;
    if(textBoton=="Activar"){
      axios.put(API_BASE_URL+`applications/${id}/patient/2`, null, {headers: {Authorization: `Bearer ${this.state.token}`}})
      .then(res => {
        document.getElementById("botonId_"+id).className = "btn btn-danger";
        document.getElementById("botonId_"+id).innerHTML = "Desactivar";
        var row_index = this.state.usuarios.findIndex( patiente_id => patiente_id.User_Has_Patient[0].id === id);
        this.state.usuarios[row_index].userstatus_id=2;
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
    }
    if(textBoton=="Desactivar"){
      axios.put(API_BASE_URL+`applications/${id}/patient/3`, null, {headers: {Authorization: `Bearer ${this.state.token}`}})
      .then(res => {
        document.getElementById("botonId_"+id).className = "btn btn-info";
        document.getElementById("botonId_"+id).innerHTML = "Activar";
        var row_index = this.state.usuarios.findIndex( patiente_id => patiente_id.User_Has_Patient[0].id === id);
        this.state.usuarios[row_index].userstatus_id=3;
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
    }
  }

  verPaciente(id) {
    this.setState({ ver: true });
    this.setState({ paciente_id: id });
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.User_Has_Patient[0].id;
    //1:en espera, 2:activado, 3:desactivado
    if(row.userstatus_id==1){

    }
    else{
      if(row.userstatus_id==2){
        return (
          <Col>
            <Button className="btn btn-success"
              onClick={() => {
                this.verPaciente(id);
              }}
            >
              Ver
            </Button>&nbsp;
            <Button className="btn btn-warning"
              onClick={() => {
                this.editarPaciente(id);
              }}
            >
              Editar
            </Button>&nbsp;
            <Button id={"botonId_"+id} className="btn btn-danger"
              onClick={() => {
                this.cambiarEstadoPaciente(id);
              }}
            >
              Desactivar
            </Button>
          </Col>
        );
      }
      else{
        return (
          <Col>
            <Button className="btn btn-success"
              onClick={() => {
                this.verPaciente(id);
              }}
            >
              Ver
            </Button>&nbsp;
            <Button className="btn btn-warning"
              onClick={() => {
                this.editarPaciente(id);
              }}
            >
              Editar
            </Button>&nbsp;
            <Button id={"botonId_"+id} className="btn btn-info"
              onClick={() => {
                this.cambiarEstadoPaciente(id);
              }}
            >
              Activar
            </Button>
          </Col>
        );
      }
    }
  };

  componentDidMount(){
    this.listarPacientes();
  }

  render() {
    if (this.state.ver) {
      return(
        <Redirect
          push to={{ pathname: `/pacientes-detalle/${this.state.paciente_id}`}}
        />
      )
    }
    if (this.state.editar) {
      return(
        <Redirect
          push to={{ pathname: `/pacientes-editar/${this.state.paciente_id}`}}
        />
      )
    }

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'lastname',
      text: 'Apellido',
      sort: true
    }, {
      dataField: 'rut',
      text: 'RUT',
      sort: true
    }, {
      dataField: 'User_Has_Patient[0].dateofbirth',
      text: 'Fecha nacimiento',
      formatter: this.formatfechaNacimiento,
      sort: true
    }, {
      //1:en espera, 2:activado, 3:desactivado
      dataField: 'userstatus_id',
      text: 'Estado',
      formatter: this.formatEstado,
      sort: true,
      hidden: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      sort: false
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      //totalSize: usuarios.length, // replace later with size(customers),
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pacientes / Lista</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Pacientes / Lista" breadcrumbItem="Pacientes / Lista" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.usuarios}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.usuarios}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder={"Buscar"}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      //rowEvents={this.eventosRowOnClick} 
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PacientesLista