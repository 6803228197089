import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import DualListBox from 'react-dual-listbox';
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { API_BASE_URL } from "helpers/url_helper"

import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Alert,
  Input,
  InputGroup
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class EncuestasCrear extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fechaIni: null,
      fechaFin: null,
      questions: [{
        question:null,
        alternative:[""]
      }],
      cesfams: []
    }
  }

  crearEncuesta(){
    this.setState({mensaje_error: null})
    this.setState({mensaje_exito: null})

    const data = {
      name: this.state.nombre,
      initial_date: this.state.fechaIni,
      end_date: this.state.fechaFin,
      cesfam: this.state.selectedFilter,
      questions: this.state.questions
    };

    console.log("ENCUESTA: ",data);

    const token = JSON.parse(localStorage.getItem("token"))

    axios.post(API_BASE_URL+`poll`, {data}, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data);
        this.setState({mensaje_exito: "Encuesta creada exitosamente"})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
          this.setState({mensaje_error: "Ups, error... revise que todos los datos ingresados"})
          if(error.response.data.errors){
            for (var i = 0; i < error.response.data.errors.length; i++) {
              if (error.response.data.errors[i]["data.name"]) {
                this.setState({mensaje_error: error.response.data.errors[i]["data.name"]})
              }
              if (error.response.data.errors[i]["data.initial_date"]) {
                this.setState({mensaje_error: error.response.data.errors[i]["data.initial_date"]})
              }
              if (error.response.data.errors[i]["data.cesfam"]) {
                this.setState({mensaje_error: error.response.data.errors[i]["data.cesfam"]})
              }
              if (error.response.data.errors[i]["data.questions"]) {
                this.setState({mensaje_error: error.response.data.errors[i]["data.questions"]})
              }
            }
          }
        }
      });
  }

  listarCesfams(){
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})

    axios.get(API_BASE_URL+`cesfam`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("CESFAMS: ",res.data.data);
        for (var i = 0; i < res.data.data.length; i++) {
          var cesfam = {};
          cesfam["value"] = res.data.data[i].id;
          if(res.data.data[i].cesfamstatus_id==2){
            cesfam["label"] = res.data.data[i].name+" (Desactivado)";
          }
          else{
            cesfam["label"] = res.data.data[i].name;
          }
          this.state.cesfams.push(cesfam);
        }
        this.setState({cesfams: this.state.cesfams});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  onFilterChange = (selectedFilter) => {
    this.setState({ selectedFilter });
    console.log("selectedFilter: ",selectedFilter);
  };

  handleChangeNombre = event => {
    this.setState({ nombre: event.target.value });
  }

  componentDidMount(){
    this.listarCesfams();
  }

  addPregunta = (indexPregunta) => {
    console.log("Add preg indexPregunta:",indexPregunta)
    const pregunta = { question: null, alternative:[""] }
    this.setState({ questions: [...this.state.questions,  pregunta] })
  }
  addAlternativa = (indexPregunta, indexAlternativa) => {
    console.log("Add alt indexPregunta:",indexPregunta, "/ indexAlternativa:",indexAlternativa)
    const preguntas = [...this.state.questions]
    preguntas[indexPregunta]["alternative"][indexAlternativa+1] = ""
    this.setState({ questions: preguntas })
  }
  removePregunta = (indexPregunta) => {
    console.log("Remover N°: ",indexPregunta)
    const preguntas = [...this.state.questions]
    preguntas.splice(indexPregunta, 1);
    this.setState({ questions: preguntas })
  }
  removeAlternativa = (indexPregunta, indexAlternativa) => {
    console.log("Remover indexPregunta:",indexPregunta, "/indexAlternativa:",indexAlternativa)
    const preguntas = [...this.state.questions]
    preguntas[indexPregunta]["alternative"].splice(indexAlternativa, 1);
    this.setState({ questions: preguntas })
  }
  handleChangePregunta = (event, indexPregunta) => {
    console.log("Pregunta: ",event.target.value, " /index:",indexPregunta, "/name:",event.target.name)
    const preguntas = [...this.state.questions]
    preguntas[indexPregunta][event.target.name] = event.target.value
    this.setState({ questions: preguntas })
  }
  handleChangeAlternativa = (event, indexAlternativa, indexPregunta) => {
    console.log("Alternativa: ",event.target.value, " /indexAlternativa:",indexAlternativa," /indexPregunta:",indexPregunta,"/name:",event.target.name)
    const preguntas = [...this.state.questions]
    preguntas[indexPregunta][event.target.name][indexAlternativa] = event.target.value
    this.setState({ questions: preguntas })
  }
  handleChangeFechas = (event, cuando) => {
    console.log("fecha: ",event, "cuando: ",cuando)
    if(cuando=="ini"){
      this.setState({ fechaIni: event.fechaIni });
    }
    if(cuando=="fin"){
      this.setState({ fechaFin: event.fechaFin });
    }
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Encuesta / Crear</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Encuesta / Crear" breadcrumbItem="Encuesta / Crear" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Col className="col-12">
                          <h4>Nombre encuesta</h4>
                        </Col>
                        <Col className="col-sm-12">
                          <AvField
                            name="nombre"
                            placeholder="Ingrese nombre de la encuesta"
                            type="text"
                            errorMessage="Ingrese nombre de la encuesta"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeNombre}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Col className="col-12">
                          <h4>Rango de fechas en que se aplica encuesta</h4>
                        </Col>
                        <Col className="col-12 mb-1">
                          <Label className="col-2">
                            Fecha inicio
                          </Label>
                          <Col className="col-4">
                            <InputGroup className="col-4">
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Ingrese de inicio encuesta"
                                onChange={(date, fechaIni) => {this.handleChangeFechas({fechaIni}, "ini" )}}
                                options={{
                                  dateFormat: "Y-m-d",
                                  allowInput: true,
                                  locale: {
                                    ...Spanish
                                  }
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-calendar-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </Col>
                        </Col>
                        <Col className="col-12 mb-1">
                          <Label className="col-2">
                            Fecha término
                          </Label>
                          <Col className="col-4">
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Ingrese de término encuesta"
                                onChange={(date, fechaFin) => {this.handleChangeFechas({fechaFin}, "fin" )}}
                                options={{
                                  dateFormat: "Y-m-d",
                                  allowInput: true,
                                  locale: {
                                    ...Spanish
                                  }
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-calendar-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </Col>
                        </Col>
                      </FormGroup>
                      <Row>
                        <Col className="col-12">
                          <h4>Cesfams en los que se aplica encuesta</h4>
                        </Col>
                        <Col className="col-6">
                          <Label
                            htmlFor="clasenombre"
                            className="col-12 col-form-label"
                          >
                            Cesfams disponibles
                          </Label>
                        </Col>
                        <Col className="col-6">
                          <Label
                            htmlFor="clasenombre"
                            className="col-12 col-form-label"
                          >
                            Cesfams seleccionados
                          </Label>
                        </Col>
                      </Row>
                      <DualListBox
                        canFilter
                        filterPlaceholder="Buscar..."
                        options={this.state.cesfams}
                        selected={this.state.selectedFilter}
                        onChange={this.onFilterChange}

                      />
                      <hr></hr>
                      <Col className="col-12">
                        <h4>Preguntas - Alternativas</h4>
                      </Col>
                      {
                        this.state.questions.map((pregunta, indexPregunta) => {
                          return (

                            <FormGroup key={indexPregunta} className="row mb-1">
                              <div className="row mb-1">
                              <Label className="col-2 col-form-label">
                                Pregunta N° {indexPregunta+1}
                              </Label>
                              <Col className="col-8">
                                <Input
                                  type="text"
                                  name="question"
                                  value={pregunta.question}
                                  placeholder="Escriba una pregunta"
                                  onChange={event => this.handleChangePregunta(event, indexPregunta)}
                                />
                              </Col>
                            {this.state.questions.length-1 === indexPregunta &&
                              <Col className="col-1">
                                <div className="d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    onClick={() => this.addPregunta(indexPregunta)}
                                  ><i class="fa fa-plus" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </Col>
                            }
                            {this.state.questions.length > 1 &&
                              <Col className="col-1">
                                <div className="d-grid">
                                  <button
                                    className="btn btn-danger btn-block"
                                    onClick={() => this.removePregunta(indexPregunta)}
                                  >
                                  <i class="fa fa-minus" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </Col>
                            }
                              </div>
                            {
                              pregunta.alternative.map((alternativa, indexAlternativa) => {
                                return (
                                  <FormGroup key={indexAlternativa} className="row mb-1">
                                    <Label className="col-sm-2 offset-2 col-form-label">
                                      Alternativa N° {indexPregunta+1}.{indexAlternativa+1}
                                    </Label>
                                    <Col className="col-6">
                                      <Input
                                        type="text"
                                        name="alternative"
                                        value={alternativa}
                                        placeholder="Escriba una alternativa"
                                        onChange={event => this.handleChangeAlternativa(event, indexAlternativa, indexPregunta)}
                                      />
                                    </Col>
                                    {pregunta.alternative.length-1 === indexAlternativa &&
                                      <Col className="col-1">
                                          <div className="d-grid">
                                              <button
                                                  className="btn btn-outline-primary btn-block"
                                                  onClick={() =>  this.addAlternativa(indexPregunta, indexAlternativa)}
                                              >
                                              <i class="fa fa-plus" aria-hidden="true"></i>
                                              </button>
                                          </div>
                                      </Col>
                                    }
                                    {pregunta.alternative.length > 1 &&
                                      <Col className="col-1">
                                          <div className="d-grid">
                                              <button
                                                  className="btn btn-outline-danger btn-block"
                                                  onClick={() => this.removeAlternativa(indexPregunta, indexAlternativa)}
                                              >
                                              <i class="fa fa-minus" aria-hidden="true"></i>
                                              </button>
                                          </div>
                                      </Col>
                                    }

                                  </FormGroup>
                                )
                              })
                            }
                            <hr></hr>
                            </FormGroup>
                          )
                        })
                      }
                    </AvForm>
                    <Col className="col-12">
                        <div className="mt-3 d-grid">
                            <button
                                className="btn btn-primary btn-block"
                                onClick={() => this.crearEncuesta()}
                            >
                            Crear encuesta
                            </button>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_error && <Alert color="danger" >{this.state.mensaje_error}</Alert>}
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_exito && <Alert color="success" >{this.state.mensaje_exito}</Alert>}
                        </div>
                      </Col>
                    {/* <pre>
                      {JSON.stringify(this.state.questions, null, 2)}
                    </pre> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default EncuestasCrear