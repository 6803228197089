import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Redirect } from "react-router-dom"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class EncuestasLista extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      encuestas: [],
      sizePerPage: 10,
      ver: false,
      editar: false
    }
  }

  listarEncuestas(){
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})

    axios.get(API_BASE_URL+`poll`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        this.setState({encuestas: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  formatEstado = (cell, row, rowIndex, formatExtraData) => {
    //1:activo, 2:inactivo, 3:en espera, 4: finalizado
    if(row.pollstatus_id==1){
      var estado = "Activa";
    }
    if(row.pollstatus_id==2){
      var estado = "Inactiva";
    }
    if(row.pollstatus_id==3){
      var estado = "En espera";
    }
    if(row.pollstatus_id==4){
      var estado = "Finalizado";
    }
    return (
      <>{estado}</>
    );
  };

  editarEncuesta(id) {
    this.setState({ editar: true });
    this.setState({ encuesta_id: id });
  }

  cambiarEstadoEncuesta(id) {
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    var fechaActual = ano+"-"+mes+"-"+dia+" 00:00:00";
    var textBoton = document.getElementById("botonId_"+id).innerHTML;
    //1:activo, 2:inactivo, 3:en espera, 4: finalizado
    if(textBoton=="Activar"){
      if (confirm('Está seguro que desea adelantar la fecha de inicio de la encuesta?')) {
        axios.get(API_BASE_URL+`poll/${id}`, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(res => {
          console.log("activar: ",res.data.data," /ini: ",res.data.data.initial_date," /actual: ",fechaActual);
          if(res.data.data.initial_date>fechaActual){
            console.log("activando encuesta antes de tiempo")
            axios.put(API_BASE_URL+`poll/${id}/pollstatus/1`, null, {headers: {Authorization: `Bearer ${this.state.token}`}})
            .then(res => {
              /*document.getElementById("botonId_"+id).className = "btn btn-danger";
              document.getElementById("botonId_"+id).innerHTML = "Finalizar";
              var row_index = this.state.encuestas.findIndex( encuestaId => encuestaId.id === id);
              this.state.encuestas[row_index].pollstatus_id=1;*/
              this.listarEncuestas()
            })
            .catch(error => {
              if (error.response) {
                console.log("ERROR: ",error.response.data); 
              }
            });
          }else{
            console.log("ERROR: no se puede activar")
          }
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });
      }
    }
    if(textBoton=="Finalizar"){
      if (confirm('Está seguro que desea finalizar la encuesta? Una vez finalizada la encuesta no podrá volver a activarla')) {
        axios.get(API_BASE_URL+`poll/${id}`, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(res => {
          console.log("finalizar: ",res.data.data," /end: ",res.data.data.end_date," /actual: ",fechaActual);
          if(res.data.data.end_date>fechaActual){
            console.log("finalizando encuesta")
            axios.put(API_BASE_URL+`poll/${id}/pollstatus/4`, null, {headers: {Authorization: `Bearer ${this.state.token}`}})
            .then(res => {
              /*document.getElementById("botonId_"+id).className = "btn btn-info";
              document.getElementById("botonId_"+id).innerHTML = "Activar";
              var row_index = this.state.encuestas.findIndex( encuestaId => encuestaId.id === id);
              this.state.encuestas[row_index].pollstatus_id=2;*/
              this.listarEncuestas()
            })
            .catch(error => {
              if (error.response) {
                console.log("ERROR: ",error.response.data); 
              }
            });
          }else{
            console.log("ERROR: no se puede finalizar")
          }
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });
      }
    }
  }

  verEncuesta(id) {
    this.setState({ ver: true });
    this.setState({ encuesta_id: id });
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.id;
    //1:activo, 2:inactivo, 3:en espera, 4: finalizado
    if(row.pollstatus_id==1){
      return (
        <Col>
          <Button className="btn btn-success"
            onClick={() => {
              this.verEncuesta(id);
            }}
          >
            Detalle
          </Button>&nbsp; 
          <Button className="btn btn-warning"
            onClick={() => {
              this.editarEncuesta(id);
            }}
          >
            Editar
          </Button>&nbsp;
          <Button id={"botonId_"+id} className="btn btn-danger"
            onClick={() => {
              this.cambiarEstadoEncuesta(id);
            }}
          >
            Finalizar
          </Button>
        </Col>
      );
    }
    if(row.pollstatus_id==2 || row.pollstatus_id==3){
      return (
        <Col>
          <Button className="btn btn-success"
            onClick={() => {
              this.verEncuesta(id);
            }}
          >
            Detalle
          </Button>&nbsp; 
          <Button className="btn btn-warning"
            onClick={() => {
              this.editarEncuesta(id);
            }}
          >
            Editar
          </Button>&nbsp;
          <Button id={"botonId_"+id} className="btn btn-info"
            onClick={() => {
              this.cambiarEstadoEncuesta(id);
            }}
          >
            Activar
          </Button>
        </Col>
      );
    }
    if(row.pollstatus_id==4){
      return (
        <Col>
          <Button className="btn btn-success"
            onClick={() => {
              this.verEncuesta(id);
            }}
          >
            Detalle
          </Button>
        </Col>
      );
    }
  };

  formatFechaIni = (cell, row, rowIndex, formatExtraData) => {
    var fecha ='';
    if(row.initial_date!=null){
      var fecha = row.initial_date.substr(0,10); 
    }
    return (
      <>{fecha}</>
    );
  };

  formatFechaFin = (cell, row, rowIndex, formatExtraData) => {
    var fecha ='';
    if(row.end_date!=null){
      var fecha = row.end_date.substr(0,10); 
    }
    return (
      <>{fecha}</>
    );
  };

  componentDidMount(){
    this.listarEncuestas();
  }

  render() {
    if (this.state.ver) {
      return(
        <Redirect
          push to={{ pathname: `/encuestas-detalle/${this.state.encuesta_id}`}}
        />
      )
    }
    if (this.state.editar) {
      return(
        <Redirect
          push to={{ pathname: `/encuestas-editar/${this.state.encuesta_id}`}}
        />
      )
    }

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: false
    }, {
      dataField: 'name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'initial_date',
      text: 'Fecha inicio',
      formatter: this.formatFechaIni,
      sort: true
    }, {
      dataField: 'end_date',
      text: 'Fecha término',
      formatter: this.formatFechaFin,
      sort: true
    }, {
      //1:activo, 2:inactivo, 3:en espera, 4: finalizado
      dataField: 'pollstatus_id',
      text: 'Estado',
      formatter: this.formatEstado,
      sort: true,
      hidden: false
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      sort: false
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      //totalSize: encuestas.length, // replace later with size(customers),
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Encuestas / Lista</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Encuestas / Lista" breadcrumbItem="Encuestas / Lista" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.encuestas}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.encuestas}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder={"Buscar"}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      //rowEvents={this.eventosRowOnClick} 
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default EncuestasLista