import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import { API_BASE_URL } from "helpers/url_helper"

import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Alert,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class CesfamsCrear extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  crearCesfam(){
    this.setState({mensaje_error_name: null})
    this.setState({mensaje_error_comuna: null})
    this.setState({mensaje_error: null})
    this.setState({mensaje_exito: null})

    const data = {
      name: this.state.nombre,
      commune_id: this.state.comuna
    };

    const token = JSON.parse(localStorage.getItem("token"))

    axios.post(API_BASE_URL+`cesfam`, {data}, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data);
        this.setState({mensaje_exito: "Cesfam creado exitosamente"})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
          for (var i = 0; i < error.response.data.errors.length; i++) {
            if (error.response.data.errors[i]["data.name"]) {
              this.setState({mensaje_error_name: error.response.data.errors[i]["data.name"]})
            }
            if (error.response.data.errors[i]["data.commune_id"]) {
              this.setState({mensaje_error_comuna: error.response.data.errors[i]["data.commune_id"]})
            }
          }
          this.setState({mensaje_error: "Ups, error..."})
        }
      });
  }

  handleChangeNombre = event => {
    this.setState({ nombre: event.target.value });
  }
  handleChangeComuna = (event) => {
    //alert("Grupo id: "+event.value+"/ label: "+event.label);
    this.setState({comuna: event.value})
  };

  componentDidMount(){
    this.listarComunas();
  }

  listarComunas(){
    this.state.comunas=[]
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`region`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data.data);
        var largoLista = res.data.data.communes.length;
        for (var i = 0; i < largoLista; i++) {
          var comuna = {};
          comuna["value"] = res.data.data.communes[i].id;
          comuna["label"] = res.data.data.communes[i].name;
          this.state.comunas.push(comuna);
        }
        this.setState({comunas: this.state.comunas});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Cesfams / Crear</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Cesfams / Crear" breadcrumbItem="Cesfams / Crear" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientenombre"
                          className="col-sm-2 col-form-label"
                        >
                          Nombre
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="nombre"
                            placeholder="Ingrese nombre"
                            type="text"
                            errorMessage="Ingrese nombre"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeNombre}
                          />
                          {this.state.mensaje_error_name && <Alert color="danger">{this.state.mensaje_error_name}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteapellido"
                          className="col-sm-2 col-form-label"
                        >
                          Comuna
                        </Label>
                        <Col className="col-sm-10">
                          <Select
                            //value={this.state.grupoSeleccionado}
                            onChange={this.handleChangeComuna}
                            options={this.state.comunas}
                            placeholder="Seleccione..."
                          />
                          {this.state.mensaje_error_comuna && <Alert color="danger">{this.state.mensaje_error_comuna}</Alert>}
                        </Col>
                      </FormGroup>
                      <Col className="col-12">
                          <div className="mt-3 d-grid">
                              <button 
                                  className="btn btn-primary btn-block"
                                  onClick={() => this.crearCesfam()}
                              >
                              Crear
                              </button> 
                          </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_error && <Alert color="danger" >{this.state.mensaje_error}</Alert>}
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_exito && <Alert color="success" >{this.state.mensaje_exito}</Alert>}
                        </div>
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default CesfamsCrear