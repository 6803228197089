import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"
import cara1 from "../../assets/images/Asset_1.svg"
import cara2 from "../../assets/images/Asset_2.svg"
import cara3 from "../../assets/images/Asset_3.svg"
import cara4 from "../../assets/images/Asset_4.svg"
import cara5 from "../../assets/images/Asset_5.svg"
import ReactApexChart from "react-apexcharts"
import SyncLoader from "react-spinners/SyncLoader";
import Select from "react-select"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  InputGroup,
  Button,
  Alert,
  Modal,
  Input,
  Form,
} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class PacientesPiloto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      usuarios: [],
      sizePerPage: 10,
      showSesiones: false,
      showGraph: false,
      showZonas: false,
      loading: false,
      loading_upload: false,
      modal_standard: false,
      selectedFiles: [],
      mensaje_error_sesiones: null,
    }
    this.tog_standard = this.tog_standard.bind(this)
  }

  subirSesion(){
    console.log("selectedFiles: ",this.state.selectedFiles[0])
    this.setState({mensaje_error_modal: null })
    this.setState({loading_upload: true })
    if(this.state.clase==null || this.state.selectedFiles[0]==null){
      this.setState({mensaje_error_modal: "Debe seleccionar una clase y subir un archivo"})
    }
    else{
      const formData = new FormData();
      formData.append("file", this.state.selectedFiles[0]);
      formData.append("classdate_id", this.state.clase);
      formData.append("patient_id", this.state.patient_id);
  
      const token = JSON.parse(localStorage.getItem("token"))
      axios.post(API_BASE_URL+`statistics/upload-csv-file`, formData, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          console.log("res.data: ",res.data);
          this.setState({mensaje_exito_modal: "Sesión subida exitosamente"})
          this.setState({loading_upload: false })
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data);
            this.setState({mensaje_error_modal: "Ups error, algo salió mal..."})
            this.setState({loading_upload: false })
          }
        });
    }
  }

  handleAcceptedFiles = files => {
    console.log("CSV arrastrado: ",files)
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )
    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  tog_standard(patient_id) {
    this.setState({mensaje_error_modal: null})
    this.setState({mensaje_exito_modal: null})
    this.setState({descripcion: null})
    this.setState({selectedFiles: []})
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    console.log("patient_id: ",patient_id)
    this.setState({patient_id: patient_id})
    this.listarClases(patient_id)
  }

  listarClases(patient_id){
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    fecha = ano+"-"+mes+"-"+dia;

    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`classdate/patient/${patient_id}/2021-01-01/${fecha}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("clases res.data: ",res.data.data);
        var clases=[];
        for (var i = 0; i < res.data.data.length; i++) {
          var clase = {};
          clase["value"] = res.data.data[i].Classdate.id;
          clase["label"] = res.data.data[i].Classdate.Classdates_belongsTo_class.name+" ("+res.data.data[i].Classdate.date+")";
          clase["descripcion"] = res.data.data[i].Classdate.Classdates_belongsTo_class.description;
          clases.push(clase);
        }
        this.setState({clases: clases});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  handleChangeClase = event => {
    this.setState({ clase: event.value})
    this.setState({ clase_label: event.label})
    this.setState({ descripcion: event.descripcion});
  }

  listarPacientes(){
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`patient`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
      //console.log("res.data: ",res.data.data);
        this.setState({usuarios: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.User_Has_Patient[0].id;
    return (
      <Col>
        <Button id={"botonId_"+id} className="btn btn-success"
          onClick={() => {
            this.verSesiones(id);
          }}
        >
          Ver sesiones
        </Button>&nbsp;
        <Button id={"botonId_"+id} className="btn btn-info"
          onClick={() => {
            this.tog_standard(id);
          }}
        >
          Subir sesión
        </Button>
      </Col>
    )
  };

  linkAccionesDetalle = (cell, row, rowIndex, formatExtraData) => {
    var id = row.id;
    return (
      <Col>
        <Button id={"botonId_"+id} className="btn btn-success"
          onClick={() => {
            this.verDetalle(id);
          }}
        >
          Ver detalle
        </Button>
      </Col>
    )
  };

  verDetalle(sesion_id){
    this.setState({ loading: true }) 
    this.setState({ showGraph: false })
    this.setState({ showZonas: false })
    this.setState({ seriesPulso: null })
    this.setState({ optionsPulso: null })
    this.setState({ seriesVelocidad: null })
    this.setState({ optionsVelocidad: null })
    this.setState({ seriesAltitud: null })
    this.setState({ optionsAltitud: null })
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`statistic/trainingsession/${sesion_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        var seriesPulso = []
        var seriesVelocidad = []
        var seriesAltitud = []
        var time = []
        var maxTime = 0
        for (var i = 0; i < res.data.data[0].Trainingdata.length; i++) {
          time.push(res.data.data[0].Trainingdata[i].time);
          maxTime = res.data.data[0].Trainingdata[i].time
          seriesPulso.push(res.data.data[0].Trainingdata[i].heart_rate);
          seriesVelocidad.push(res.data.data[0].Trainingdata[i].speed);
          seriesAltitud.push(res.data.data[0].Trainingdata[i].altitude);
        }
        var horas = maxTime.substring(0,2)  
        var minutos = maxTime.substring(3,5) 
        var tickAmount = (parseInt(horas, 10)*360+parseInt(minutos, 10)*60)/60
  
        this.setState({ seriesPulso: [{ data: seriesPulso, name: 'Pulso'}] });
        this.setState({ optionsPulso: 
          {chart: {
              id: 'pulso',
              group: 'social',
              type: 'line',
              height: 160,
            },
            colors: ['#008FFB'],
            yaxis: {
              labels: {
                minWidth: 40,
              }
            },
            xaxis: {
              type: 'time',
              categories: time,
              labels: {
                show: false,
              }
            },
            stroke: {
              curve: 'smooth',
              lineCap: 'round',
              width: 3,
            },
            tooltip: {
              x: {
                show: false
              },
            },
          }
        })
        this.setState({ seriesVelocidad: [{ data: seriesVelocidad, name: 'Velocidad'}] });
        this.setState({ optionsVelocidad: 
            {chart: {
              id: 'velocidad',
              group: 'social',
              type: 'line',
              height: 160
            },
            colors: ['#546E7A'],
            yaxis: {
              labels: {
                minWidth: 40
              }
            },
            stroke: {
              curve: 'smooth',
              lineCap: 'round',
              width: 3,
            },
            xaxis: {
              type: 'time',
              categories: time,
              labels: {
                show: false,
              }
            },
            tooltip: {
              x: {
                show: false
              },
            },
          },
        })
        this.setState({ seriesAltitud: [{ data: seriesAltitud, name: 'Altitud'}] });
        this.setState({ optionsAltitud: 
          {chart: {
              id: 'altitud',
              group: 'social',
              type: 'area',
              height: 160
            },
            colors: ['#00E396'],
            yaxis: {
              labels: {
                minWidth: 40
              }
            },
            stroke: {
              curve: 'smooth',
              lineCap: 'round',
              width: 3,
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              type: 'time',
              categories: time,
              tickAmount: tickAmount,
            },
            tooltip: {
              x: {
                show: false
              },
            },
          },
        })
        this.setState({ showGraph: true })
        this.setState({ loading: false }) 
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
      axios.get(API_BASE_URL+`statistic/intensitylevels/${sesion_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        var level_1 = res.data.data.level_1
        var level_2 = res.data.data.level_2
        var level_3 = res.data.data.level_3
        var level_4 = res.data.data.level_4
        var level_5 = res.data.data.level_5

        this.setState({ seriesZonas: [level_1.percentage, level_2.percentage, level_3.percentage, level_4.percentage, level_5.percentage] });
        this.setState({ optionsZonas: {
            labels: ["Zona 1- "+level_1.time, "Zona 2- "+level_2.time, "Zona 3- "+level_3.time, "Zona 4- "+level_4.time, "Zona 5- "+level_5.time],
            colors: ["#888B8D", "#008AD8", "#00AE58", "#FEDD00", "#F4364C"],
          },
        })
        this.setState({ showZonas: true }) 
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  verSesiones(patient_id){
    this.setState({ showSesiones: false });
    this.setState({ mensaje_error_sesiones: null }); 
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`statistic/trainingsessions/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("sesiones: ",res.data.data);
        this.setState({ sesiones: res.data.data });
        this.setState({ showSesiones: true });
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
          this.setState({ mensaje_error_sesiones: error.response.data.message }); 
        }
      });
  }

  componentDidMount(){
    this.listarPacientes();
  }

  formatAsistencia = (cell, row, rowIndex, formatExtraData) => {
    //0:no asistio, 1:asistio
    if(row.attendance==0){
      var asistencia = "No";
    }
    else{
      var asistencia = "Si";
    }
    return (
      <>{asistencia}</>
    );
  };

  formatEvaluacion1 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.evaluations[0]){
      evaluacion = "Respuesta: "+ row.evaluations[0].point_id
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };
  formatEvaluacion2 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.evaluations[1]){
      var cara = ''
      if(row.evaluations[1].point_id==1){
        cara = cara1
      }
      if(row.evaluations[1].point_id==2){
        cara = cara2
      }
      if(row.evaluations[1].point_id==3){
        cara = cara3
      }
      if(row.evaluations[1].point_id==4){
        cara = cara4
      }
      if(row.evaluations[1].point_id==5){
        cara = cara5
      }
      evaluacion = <img
          src={cara}
          alt=""
          height="19"
        />
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };

  formatFecha = (cell, row, rowIndex, formatExtraData) => {
    var fecha ='';
    if(row.date!=null){
      var fecha = row.date.substr(0,10); 
      //console.log("fecha: ",row.User_Has_Patient[0].dateofbirth);
    }
    return (
      <>{fecha}</>
    );
  };

  formatTiempo = (cell, row, rowIndex, formatExtraData) => {
    var tiempo=0
    if(row.time_in_class>0){
      tiempo = Math.round(row.time_in_class/60)
    }
    return (
      <>{tiempo+" minutos"}</>
    );
  };

  formatClassdate = (cell, row, rowIndex, formatExtraData) => {
    var name = row.Classdate.Classdates_belongsTo_class.name
    return (
      <>{name}</>
    );
  };

  render() {
    const { ExportCSVButton } = CSVExport;

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'lastname',
      text: 'Apellido',
      sort: true
    }, {
      dataField: 'rut',
      text: 'RUT',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      sort: false
    }];

    const columnsDetalle = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'date',
      text: 'Fecha',
      sort: true
    }, {
      dataField: 'Classdate',
      text: 'Clase',
      formatter: this.formatClassdate,
      sort: true
    }, {
      dataField: 'average_hr',
      text: 'Pulso promedio',
      sort: true
    }, {
      dataField: 'max_hr',
      text: 'Pulso máximo',
      sort: true
    }, {
      dataField: 'total_distance',
      text: 'Distancia total (km)',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAccionesDetalle,
      sort: false
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pacientes / Importar datos</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Pacientes / Importar datos" breadcrumbItem="Pacientes / Importar datos" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4>Lista pacientes</h4><hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.usuarios}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={this.state.usuarios}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    {this.state.mensaje_error_sesiones &&
                        <Col className="col-12">
                          <div className="mt-1 d-grid">
                            <Alert color="danger" >{this.state.mensaje_error_sesiones}</Alert>
                          </div>
                        </Col>
                      }
                    <Col className="col-12">
                    {this.state.showSesiones &&
                      <div>
                        <br></br>
                        <Col className="col-12">
                          <h4>Lista sesiones</h4><hr></hr>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField='id'
                            columns={columnsDetalle}
                            data={this.state.sesiones}
                          >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columnsDetalle}
                              data={this.state.sesiones}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                            placeholder={"Buscar"}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />

                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                              }
                            </ToolkitProvider>
                          )
                          }</PaginationProvider>
                        </Col>
                      </div>
                    }
                    {this.state.loading &&
                      <Col className="col-6 offset-6">
                        <Label>Cargando </Label>
                        <SyncLoader color={"#11998f"} loading={this.state.loading} />
                      </Col>
                    }
                    {this.state.showGraph &&
                      <div>
                        <br></br>
                        <Col className="col-12">
                          <h4>Gráficos</h4><hr></hr>
                          <div id="chart-pulso">
                            <ReactApexChart options={this.state.optionsPulso} series={this.state.seriesPulso} type="line" height={160} />
                          </div>
                          <div id="chart-velocidad">
                            <ReactApexChart options={this.state.optionsVelocidad} series={this.state.seriesVelocidad} type="line" height={160} />
                          </div>
                          <div id="chart-altitud">
                            <ReactApexChart options={this.state.optionsAltitud} series={this.state.seriesAltitud} type="area" height={160} />
                          </div>
                        </Col>
                      </div>
                    }
                    {this.state.showZonas &&
                      <ReactApexChart
                        options={this.state.optionsZonas}
                        series={this.state.seriesZonas}
                        type="pie"
                        height="250"
                        className="apex-charts"
                      />
                    }
                  </Col>
                  <Modal
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myModalLabel">
                        Subir sesión
                      </h5>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ modal_standard: false })
                        }
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <FormGroup className="row mb-2">
                        <Label>Seleccione la clase a la cual desea vincular la sesión</Label>
                        <Select
                          onChange={this.handleChangeClase}
                          options={this.state.clases}
                          placeholder="Seleccione..."
                        />
                      </FormGroup>
                      <FormGroup className="row mb-2">
                        <Label>
                          Descripción de la clase
                        </Label>
                        <Col className="col-sm-12">
                          <Input
                            type="textarea"
                            className="form-control"
                            value={this.state.descripcion ? this.state.descripcion : ""}
                            disabled
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-2">
                        <Label>
                          Archivo CSV
                        </Label>
                        <Col className="col-sm-12">
                          <Form>
                            <Dropzone
                              onDrop={acceptedFiles =>
                                this.handleAcceptedFiles(acceptedFiles)
                              }
                              maxFiles={1}
                              accept=".csv"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload"/>
                                      </div>
                                      <h4>Arrastre el archivo aquí o haga click para buscar</h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {this.state.selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </Form>
                        </Col>
                      </FormGroup>
                      {this.state.loading_upload &&
                        <Col className="col-6 offset-6">
                          <Label>Cargando </Label>
                          <SyncLoader color={"#11998f"} loading={this.state.loading_upload} />
                        </Col>
                      }
                      {this.state.mensaje_error_modal &&
                        <Col className="col-12">
                          <div className="mt-1 d-grid">
                            <Alert color="danger" >{this.state.mensaje_error_modal}</Alert>
                          </div>
                        </Col>
                      }
                      {this.state.mensaje_exito_modal &&
                        <Col className="col-12">
                          <div className="mt-1 d-grid">
                            <Alert color="success" >{this.state.mensaje_exito_modal}</Alert>
                          </div>
                        </Col>
                      }
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {this.subirSesion();}}
                        className="btn btn-success"
                        data-dismiss="modal"
                      >
                        Subir sesion
                      </button>
                      <button
                        type="button"
                        onClick={this.tog_standard}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Cerrar
                      </button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )}
}

export default PacientesPiloto