import React, { Component } from "react"
//import PropTypes from 'prop-types'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

import lightlogo from "../../assets/images/logo-light.svg"
import logo from "../../assets/images/personalizadas/logo_healt.png"

class RecuperarContrasena extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      errorMessage: null,
      successMessage: null
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
    console.log("email: ",this.state.email);
  }

  handleValidSubmit = event => {
    event.preventDefault();

    this.setState({ errorMessage: null });
    this.setState({ successMessage: null });
    

    const data = {
      email: this.state.email,
    };
    console.log("email: ",data);
  
    axios.post(API_BASE_URL+`user/password/recover`, {data})
      .then(res => {
        console.log("res.data: ",res.data);
        this.setState({ successMessage: res.data.message });
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
          this.setState({ errorMessage: error.response.data.message })
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden" id="login_fondo">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-5">
                          <h5 className="text-bienvenida">Recuperar Contraseña</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        {/* <img src={profile} alt="" className="img-fluid"/> */}
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="72"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.state.errorMessage && <Alert color="danger" >{this.state.errorMessage}</Alert>}
                        {this.state.successMessage && <Alert color="success" >{this.state.successMessage}</Alert>}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            type="email"
                            required
                            placeholder="Ingrese email"
                            errorMessage="Ingrese email"
                            onChange={this.handleChangeEmail}
                          />
                        </div>

                        <div className="mt-3 d-grid" id="login_gridbotoningresar">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Enviar
                          </button>
                        </div>
                        <div className="row justify-content-md-center col-6 offset-3">
                          <Link to="/login" className="btn btn-secondary btn-block" >
                            Volver
                          </Link>
                          </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Necesitas ayuda? 
                    <Link to="/soporte">
                      &nbsp;Soporte
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} <a href="https://www.usercode.cl" target="_blank">UserCode SpA</a> todos los derechos reservados.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RecuperarContrasena
