import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"
import cara1 from "../../assets/images/Asset_1.svg"
import cara2 from "../../assets/images/Asset_2.svg"
import cara3 from "../../assets/images/Asset_3.svg"
import cara4 from "../../assets/images/Asset_4.svg"
import cara5 from "../../assets/images/Asset_5.svg"
import ReactApexChart from "react-apexcharts"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  InputGroup,
  Button,
  Alert
} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class ReportesClase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      clases: [],
      sizePerPage: 10,
      mensaje_error: null,
      showGraph: false,
      classdate_id: null,
      showGraphBorg: false,
      showGraphMood: false,
    }
  }

  listarClases(){
    //console.log("ini: ",this.state.ini_date, " /end: ",this.state.end_date)
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    var hasta = ano+"-"+mes+"-"+dia;
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`class/2021-01-01/${hasta}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        this.setState({clases: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.classdate_id;
    return (
      <Col>
        <Button id={"botonId_"+id} className="btn btn-success"
          onClick={() => {
            this.verEstadistica(id);
          }}
        >
          Ver estadística
        </Button>
      </Col>
    )
  };

  verEstadistica(classdate_id){
    if(classdate_id){
      this.setState({ seriesBorg: null })
      this.setState({ optionsBorg: null })
      this.setState({ modeBorg: null })
      this.setState({ seriesMood: null })
      this.setState({ labelsMood: null })
      this.setState({ modeMood: null })
      this.setState({ showGraphBorg: false }) 
      this.setState({ showGraphMood: false }) 
      this.setState({ showGraph: false })
      this.setState({ classdate_id })
      this.setState({ mensaje_error: null }) 
      const token = JSON.parse(localStorage.getItem("token"))
      axios.get(API_BASE_URL+`statistic/classdate/${classdate_id}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          //console.log("classdate_id: ",classdate_id, " /ini_date: ",this.state.ini_date, "/end_date: ",this.state.end_date);
          //console.log("clases: ",res.data.data);
          //this.setState({ clases: res.data.data.class_history });
          this.setState({ attendance_percentage: res.data.data.attendance_percentage });
          if(res.data.data.average_time_in_class==null){
            this.setState({ average_time_in_class: 0 });
          }else{
            this.setState({ average_time_in_class: Math.round(res.data.data.average_time_in_class/60) });
          }
          this.setState({ showGraph: true });
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
            this.setState({ mensaje_error: error.response.data.message }) 
          }
        });

      axios.get(API_BASE_URL+`statistic/borgscale/${classdate_id}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          //console.log("BORG: ",res.data.data)
          
          var series = []
          var labels = []
          var modes = []
          var colors = []
          for (var i = 0; i < res.data.data.byScale.length; i++) {
            var label = ''
            var color = ''
            if(res.data.data.byScale[i].scale==0){
              label = "0- Reposo total"
              color = "#e6e6e6"
            }
            if(res.data.data.byScale[i].scale==1){
              label = "1- Esfuerzo muy suave"
              color = "#e6e6e6"
            }
            if(res.data.data.byScale[i].scale==2){
              label = "2- Suave"
              color = "#e6e6e6"
            }
            if(res.data.data.byScale[i].scale==3){
              label = "3- Esfuerzo moderado"
              color = "#fffd38"
            }
            if(res.data.data.byScale[i].scale==4){
              label = "4- Un poco duro"
              color = "#fffd38"
            }
            if(res.data.data.byScale[i].scale==5){
              label = "5- Duro"
              color = "#fdd86f"
            }
            if(res.data.data.byScale[i].scale==6){
              label = "6- Duro"
              color = "#fdd86f"
            }
            if(res.data.data.byScale[i].scale==7 ){
              label = "7- Muy duro"
              color = "#febf2d"
            }
            if(res.data.data.byScale[i].scale==8){
              label = "8- Muy duro"
              color = "#febf2d"
            }
            if(res.data.data.byScale[i].scale==9){
              label = "9- Muy duro"
              color = "#febf2d"
            }
            if(res.data.data.byScale[i].scale==10){
              label = "10- Esfuerzo máximo"
              color = "#fb0d1b"
            } 
            series.push(res.data.data.byScale[i].count)
            labels.push(label)
            colors.push(color)
          }
          for (var i = 0; i < res.data.data.mode.length; i++) {
            modes.push("["+res.data.data.mode[i]+"] ")
          }
          this.setState({ seriesBorg: series })
          this.setState({ optionsBorg: {labels: labels, colors: colors} })
          this.setState({ modeBorg: modes })
          this.setState({ showGraphBorg: true }) 

          /*this.setState({ options: {
            labels: ["No", "Sí"],
            colors: ["#f46a6a","#34c38f"],
          },
        })*/
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });

      axios.get(API_BASE_URL+`statistic/mood/${classdate_id}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          //console.log("MOOD: ",res.data.data)

          var series = []
          var labels = []
          var modes = []
          for (var i = 0; i < res.data.data.frequencies.length; i++) {
            var label = ''
            if(res.data.data.frequencies[i].scale==1){
              label = '<img src="'+cara1+'" height="34" />'
            }
            if(res.data.data.frequencies[i].scale==2){
              label = '<img src="'+cara2+'" height="34" />'
            }
            if(res.data.data.frequencies[i].scale==3){
              label = '<img src="'+cara3+'" height="34" />'
            }
            if(res.data.data.frequencies[i].scale==4){
              label = '<img src="'+cara4+'" height="34" />'
            }
            if(res.data.data.frequencies[i].scale==5){
              label = '<img src="'+cara5+'" height="34" />'
            }
            labels.push(label)
            series.push(res.data.data.frequencies[i].count)
          }
          for (var i = 0; i < res.data.data.mode.length; i++) {
            var mode = ''
            if(res.data.data.mode[i]=="La distribución no tiene moda"){
              mode = res.data.data.mode[i]
            }
            if(res.data.data.mode[i]==1){
              mode = cara1
            }
            if(res.data.data.mode[i]==2){
              mode = cara2
            }
            if(res.data.data.mode[i]==3){
              mode = cara3
            }
            if(res.data.data.mode[i]==4){
              mode = cara4
            }
            if(res.data.data.mode[i]==5){
              mode = cara5
            }
            modes.push(mode)
          }
          this.setState({ seriesMood: series })
          this.setState({ labelsMood: labels })
          this.setState({ modeMood: modes })
          this.setState({ showGraphMood: true }) 
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });
    }
  }
  
  handleChangeIniDate = event => { 
    this.setState({ ini_date: event.ini_date })
    this.verEstadistica(this.state.classdate_id)
  }
  handleChangeEndDate = event => { 
    this.setState({ end_date: event.end_date })
    this.verEstadistica(this.state.classdate_id)
  }

  componentDidMount(){
    this.listarClases()
  }

  formatFecha = (cell, row, rowIndex, formatExtraData) => {
    var fecha = row.date.substring(0, 10)
    var hora = row.date.substring(11, 16)
    return (
      <>{fecha+" / "+hora}</>
    );
  };

  render() {
    const { ExportCSVButton } = CSVExport;

    const columns = [{
      dataField: 'classdate_id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'date',
      text: 'Fecha / Hora',
      formatter: this.formatFecha,
      sort: true
    }, {
      dataField: 'Classdates_belongsTo_class.name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'Classdates_belongsTo_class.description',
      text: 'Descripción',
      headerStyle: { width: '50%' },
      style: { textAlign: 'justify' },
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      sort: false
    }];

    const defaultSorted = [{
      dataField: 'classdate_id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Reportes / Clase</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Reportes / Clase" breadcrumbItem="Reportes / Clase" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4>Lista Clases</h4><hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.clases}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={this.state.clases}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              {/* <Col className="col-md-2 offset-6">
                                <div className="d-grid">
                                  <ExportCSVButton className="btn btn-outline-secondary" { ...toolkitProps.csvProps }>Exportar</ExportCSVButton>
                                </div>
                              </Col> */}
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle" /*table-nowrap*/
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    <Col className="col-12">
                        {this.state.mensaje_error ? 
                          <div className="mt-3 d-grid">
                            <Alert color="danger" >{this.state.mensaje_error}</Alert>
                          </div>
                          :
                          this.state.showGraph &&
                            <div>
                              <br></br>
                              <h4>Estadística</h4><hr></hr>
                              <Col className="col-12">
                                <FormGroup className="row mb-3">
                                  <Label className="col-sm-12 col-form-label">
                                    Porcentaje de asistencia: {this.state.attendance_percentage}%
                                  </Label>
                                  <Label className="col-sm-12 col-form-label mb-1">
                                    Promedio tiempo en clases: {this.state.average_time_in_class} minutos
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col className="col-12">
                                <Label className="col-sm-12 col-form-label mb-1">
                                  Como valorarías la percepción del esfuerzo durante la clase?
                                </Label>
                              </Col>
                              <Row>
                                <Col className="col-10">
                                  {this.state.showGraphBorg &&
                                    <ReactApexChart
                                      options={this.state.optionsBorg}
                                      series={this.state.seriesBorg}
                                      type="pie"
                                      height="300"
                                      className="apex-charts"
                                    />
                                  }
                                </Col>
                                <Col className="col-2">
                                  <Label>*Moda(s): {this.state.modeBorg}</Label>
                                </Col>
                              </Row>
                              <Col className="col-12">
                                <Label>¿Cual es el estado de ánimo, luego de la clase?</Label>
                              </Col>
                              <Row>
                                <Col className="col-10">
                                  {this.state.showGraphMood &&
                                    <ReactApexChart
                                      options={{labels: this.state.labelsMood}}
                                      series={this.state.seriesMood}
                                      type="pie"
                                      height="300"
                                      className="apex-charts"
                                    />
                                  }
                                </Col>
                                <Col className="col-2">
                                  <Label>*Moda(s): </Label>
                                  {this.state.modeMood &&
                                    this.state.modeMood.map((mood, index) => {
                                      return (
                                        mood=="La distribución no tiene moda" ? 
                                        mood
                                        :
                                        <img
                                            id={index}
                                            src={mood}
                                            height="34"
                                          />
                                      )
                                    })
                                  }
                                </Col>
                              </Row>
                            </div>
                        }
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ReportesClase