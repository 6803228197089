import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { API_BASE_URL } from "helpers/url_helper"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  Alert,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class PacientesRegistrar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edad:0
    }
  }

  registrarPaciente(){
    this.setState({mensaje_error_name: null})
    this.setState({mensaje_error_apellido: null})
    this.setState({mensaje_error_rut: null})
    this.setState({mensaje_error_email: null})
    this.setState({mensaje_error_telefono: null})
    this.setState({mensaje_error_fechanacimiento: null})
    this.setState({mensaje_error_direccion: null})
    this.setState({mensaje_error: null})
    this.setState({mensaje_exito: null})

    const data = {
      name: this.state.nombre,
      lastname: this.state.apellido,
      rut: this.state.rut,
      address: this.state.direccion,
      email: this.state.email,
      password: "Aa12345678",
      telephone: this.state.telefono,
      dateofbirth: this.state.fechanacimiento,
      age: 1
    };

    console.log("paciente ",data);

    const token = JSON.parse(localStorage.getItem("token"))

    axios.post(API_BASE_URL+`patient`, 
      {data}, 
      {headers: {
        Authorization: `Bearer ${token}`
      }})
      .then(res => {
        console.log("res.data: ",res.data);
        console.log("paciente registrado")
        this.setState({mensaje_exito: "Paciente registrado exitosamente"})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);

          for (var i = 0; i < error.response.data.errors.length; i++) {
            if (error.response.data.errors[i]["data.name"]) {
              this.setState({mensaje_error_name: error.response.data.errors[i]["data.name"]})
            }
            if (error.response.data.errors[i]["data.lastname"]) {
              this.setState({mensaje_error_apellido: error.response.data.errors[i]["data.lastname"]})
            }
            if (error.response.data.errors[i]["data.rut"]) {
              this.setState({mensaje_error_rut: error.response.data.errors[i]["data.rut"]})
            }
            if (error.response.data.errors[i]["data.email"]) {
              this.setState({mensaje_error_email: error.response.data.errors[i]["data.email"]})
            }
            if (error.response.data.errors[i]["data.telephone"]) {
              this.setState({mensaje_error_telefono: error.response.data.errors[i]["data.telephone"]})
            }
            if (error.response.data.errors[i]["data.dateofbirth"]) {
              this.setState({mensaje_error_fechanacimiento: error.response.data.errors[i]["data.dateofbirth"]})
            }
            if (error.response.data.errors[i]["data.address"]) {
              this.setState({mensaje_error_direccion: error.response.data.errors[i]["data.address"]})
            }
          }
          this.setState({mensaje_error: "Ups, error..."})
        }
      });
  }


  handleChangeNombre = event => {
    this.setState({ nombre: event.target.value });
  }
  handleChangeApellido = event => {
    this.setState({ apellido: event.target.value });
  }
  handleChangeRut = event => {
    this.setState({ rut: event.target.value });
  }
  handleChangeDireccion = event => {
    this.setState({ direccion: event.target.value });
  }
  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  }
  handleChangeTelefono = event => {
    this.setState({ telefono: event.target.value });
  }
  handleChangeFechanacimiento = event => {
    /*var fecha = new Date(event.date);
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    fecha = ano+"-"+mes+"-"+dia;
    this.setState({ fechanacimiento: fecha });
    console.log("fecha: ",fecha);*/

    this.setState({ fechanacimiento: event.fechanacimiento });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pacientes / Registrar</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Pacientes / Registrar" breadcrumbItem="Pacientes / Registrar" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientenombre"
                          className="col-sm-2 col-form-label"
                        >
                          Nombre
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="nombre"
                            placeholder="Ingrese nombre"
                            type="text"
                            errorMessage="Ingrese nombre"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeNombre}
                            id="paciente_nombre"
                          />
                          {this.state.mensaje_error_name && <Alert color="danger">{this.state.mensaje_error_name}</Alert>}
                        </Col>
                        
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteapellido"
                          className="col-sm-2 col-form-label"
                        >
                          Apellido
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="apellido"
                            placeholder="Ingrese apellido"
                            type="text"
                            errorMessage="Ingrese apellido"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeApellido}
                            id="paciente_apellido"
                          />
                          {this.state.mensaje_error_apellido && <Alert color="danger">{this.state.mensaje_error_apellido}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienterut"
                          className="col-sm-2 col-form-label"
                        >
                          RUT
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="rut"
                            placeholder="Ingrese RUT"
                            type="text"
                            errorMessage="Ingrese RUT"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeRut}
                            id="paciente_rut"
                          />
                          {this.state.mensaje_error_rut && <Alert color="danger">{this.state.mensaje_error_rut}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientedireccion"
                          className="col-sm-2 col-form-label"
                        >
                          Direccion
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="direccion"
                            placeholder="Ingrese direccion"
                            type="text"
                            errorMessage="Ingrese direccion"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeDireccion}
                            id="paciente_direccion"
                          />
                          {this.state.mensaje_error_direccion && <Alert color="danger">{this.state.mensaje_error_direccion}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteemail"
                          className="col-sm-2 col-form-label"
                        >
                          Email
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="email"
                            placeholder="Ingrese email"
                            type="text"
                            errorMessage="Ingrese email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeEmail}
                            id="paciente_email"
                          />
                          {this.state.mensaje_error_email && <Alert color="danger">{this.state.mensaje_error_email}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientetelefono"
                          className="col-sm-2 col-form-label"
                        >
                          Teléfono
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="telefono"
                            placeholder="Ingrese teléfono"
                            type="text"
                            errorMessage="Ingrese teléfono"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeTelefono}
                            id="paciente_telefono"
                          />
                          {this.state.mensaje_error_telefono && <Alert color="danger">{this.state.mensaje_error_telefono}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientefechanacimiento"
                          className="col-sm-2 col-form-label"
                        >
                          Fecha nacimiento
                        </Label>
                        <Col className="col-sm-10">
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Ingrese fecha nacimiento"
                              //onChange={date => {this.handleChangeFechanacimiento({date})}}
                              onChange={(date, fechanacimiento) => {this.handleChangeFechanacimiento({fechanacimiento})}}
                              options={{
                                dateFormat: "Y-m-d",
                                allowInput: true,
                                locale: {
                                  ...Spanish
                                }
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-calendar-outline" />
                              </span>
                            </div>
                          </InputGroup>
                          {this.state.mensaje_error_fechanacimiento && <Alert color="danger">{this.state.mensaje_error_fechanacimiento}</Alert>}
                        </Col>
                      </FormGroup>
                      <Col className="col-12">
                          <div className="mt-3 d-grid">
                              <button 
                                  className="btn btn-primary btn-block"
                                  onClick={() => this.registrarPaciente()}
                              >
                              Registrar
                              </button> 
                          </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_error && <Alert color="danger" >{this.state.mensaje_error}</Alert>}
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_exito && <Alert color="success" >{this.state.mensaje_exito}</Alert>}
                        </div>
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default PacientesRegistrar