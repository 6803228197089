import React, { Component, useState  } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Alert,
  Collapse,
  Modal
} from "reactstrap"
import { API_BASE_URL } from "helpers/url_helper"

import Select from "react-select"
import axios from "axios"
import ReactPlayer from 'react-player'
import FacebookLogin from 'react-facebook-login'
import SyncLoader from "react-spinners/SyncLoader";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


class ClasesTransmitir extends Component {
  constructor(props) {
    super(props)
    this.state = {
      botonLogin: false,
      mostarVideo: false,
      /* VERSION ANTIGUA
      fbTokenChecked: false, */
      mensaje_error: null,
      form_data: { option_id: null },
      response_upload_videos: {start_offset:0},
      percent: 0,
      options: [
        { value: 1, label: "Transmisión" },
        { value: 2, label: "Subir archivo" },
        { value: 3, label: "Ingresar ID de Facebook" },
      ],
      mensaje_error_video: null,
      loading: false,
      collapse: false,
      upload_video_status: false,
      save_video_id: false,
      video_upload_proccess: false,
    }
  }

  componentDidMount() {
    this.listarClases()
  }

  listarClases() {
    var fecha = new Date()
    var ano = fecha.getFullYear()
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2)
    var dia = ("0" + fecha.getDate()).slice(-2)
    fecha = ano + "-" + mes + "-" + dia

    const token = JSON.parse(localStorage.getItem("token"))

    axios
      .get(API_BASE_URL + `class/teacher/${fecha}/${fecha}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log("res.data: ", res.data.data)
        var clases = []
        var largoLista = res.data.data.length
        for (var i = 0; i < largoLista; i++) {
          var clase = {}
          clase["value"] = res.data.data[i].classdate_id
          clase["label"] =
            res.data.data[i].Classdates_belongsTo_class.name +
            " - " +
            res.data.data[i].status
          clase["descripcion"] =
            res.data.data[i].Classdates_belongsTo_class.description
          clase["grupo_id"] =
            res.data.data[i].Classdates_belongsTo_class.group_id
          clase["status"] =
            res.data.data[i].status
          clases.push(clase)
        }
        this.setState({ clases: clases })
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ", error.response.data)
        }
      })
  }

  hanldeCollapse = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  handleChangeClaseNombre = event => {
    this.setState({ classdate_id: event.value })
    this.setState({ descripcion: event.descripcion })
    this.setState({ class_status_video: event.status === "Subida" ? true : false })
    this.setState({ mensaje_error: null })

    const token = JSON.parse(localStorage.getItem("token"))

    axios
      .get(API_BASE_URL + `group/${event.grupo_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log("GRUPO res.data: ", res.data.data)
        this.setState({ grupo: res.data.data.group })
      })
      .catch(error => {
        if (error.response) {
         
          console.log("ERROR: ", error.response.data)
        }
      })

    axios
      .get(API_BASE_URL + `classdate/${event.value}/broadcast`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log("CONSULTA link: ",res.data.data);
        if (res.data.data.link != null) {
          this.setState({
            mensaje_error:
              "La transmisión de esta clase ya se encuentra iniciada. Si vuelve a generar una clave de transmisión, se borrará la transmisión de la clase anterior.",
              link: res.data.data.link
          })
        }
      })
      .catch(error => {
        if (error.response) {
           this.setState({
             video_upload_proccess: true,
             video_upload_proccess_message: error.response.data.message,
           })
          console.log("ERROR: ", error.response.data)
        }
      })

    /* VERSION ANTIGUA
      if(this.state.fbTokenChecked==false){
        this.checkToken()
      }*/

    /** VERSION NUEVA: no se llama la funcion checkToken(), solo se muestra el boton */
    this.setState({ botonLogin: true })
  }

  handleChangeSelect = async (event, name) => {
    await this.setState({
      form_data: { [name]: event.value },
    })
  }

  handleFile = async event => {
    await this.setState(state => ({
      form_data: {
        ...state.form_data,
        video_size: event[0].size,
        video_file: event[0],
        video_name: event[0].name,
      },
    }))
  }
  
  handleChangeInput = async event => {
    await this.setState(state => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.value,
      },
    }))
    console.log(this.state.form_data)
  }

  /* VERSION ANTIGUA: checkea si esta guardado el token para logear o no
  checkToken(){
    this.setState({fbTokenChecked: true});
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`cesfam/0/facebook`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("fb_token (guardado): ",res.data.data.fb_token);
        FB.api(
          '/me?access_token='+res.data.data.fb_token,
          'GET',
          null,
          response => {
            if(response.error){
              this.setState({ botonLogin: true });  
              console.log("ERROR fb0: ",response.error)
            }else{
              //console.log("response: ",response)
              this.setState({ nombreCuenta: response.name }) 
              FB.getLoginStatus( response => {
                if (response.status === 'connected') {
                  console.log(response);
                  this.setState({ botonLogin: false });
                  this.setState({ accessToken: response.authResponse.accessToken }) 
                } 
              });
              
            }
          }
        );
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR fb1: ",error.response.data); 
        }
      });
  }*/

  /* VERSION ANTIGUA: guarda token de fb para no tener que volver a logear con boton
  responseFacebook = (response) => {
    this.setState({ botonLogin: false });
    this.setState({ accessToken: response.accessToken }) 
    this.setState({ nombreCuenta: response.name }) 

    const token = JSON.parse(localStorage.getItem("token"))
    const data = {
      token: response.accessToken
    }
    axios.post(API_BASE_URL+`cesfam/0/facebook`, {data}, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data);
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }*/

  responseFacebook = response => {
    //this.setState({ botonLogin: false });
    //this.setState({ accessToken: response.accessToken })
    this.setState({ nombreCuenta: response.name })
    if (response.name) {
      this.setState({ botonLogin: false })
    }
  }

  /**Upload videos FB */
  async uploadFacebook_videos() {
    this.setState({ loading: true })
    await FB.api("me/accounts", "GET", {}, get_accounts => {
      //console.log("token: ",response_accounts.data[0].access_token," /id: ",response_accounts.data[0].id);

      FB.api(
        `105783408408077/videos/?upload_phase=start&access_token=${get_accounts.data[0].access_token}&file_size=${this.state.form_data.video_size}`, 
        "POST",
        {},
        async post_upload_videos => {
          console.log(post_upload_videos)
          this.setState({
            response_upload_videos: {
              start_offset: post_upload_videos.start_offset,
              end_offset: post_upload_videos.end_offset,
              upload_session_id: post_upload_videos.upload_session_id,
              video_id: post_upload_videos.video_id,
            },
          })
          while (
            this.state.response_upload_videos.start_offset !==
            this.state.response_upload_videos.end_offset
          ) {
            var startPointer = this.state.response_upload_videos.start_offset
            var endPointer = this.state.response_upload_videos.end_offset
            var chunks = this.state.form_data.video_file.slice(
              startPointer,
              endPointer
            )
            var file = new File([chunks], this.state.form_data.video_name, {
              type: "video/mp4",
              lastModified: Date.now(),
            })
            // console.log(file)
            var form = new FormData()

            form.append("access_token", get_accounts.data[0].access_token)
            form.append("upload_phase", "transfer")
            form.append(
              "start_offset",
              this.state.response_upload_videos.start_offset
            )
            form.append(
              "upload_session_id",
              this.state.response_upload_videos.upload_session_id
            )
            // form.append("video_file_chunk", this.state.form_data.video_file)
            form.append("video_file_chunk", file)

            const options = {
              method: "POST",
              url: "https://graph-video.facebook.com/105783408408077/videos",
              headers: {
                "Content-Type": "multipart/form-data;",
              },
              data: form,
            }

            await axios
              .request(options)
              .then(function (response) {
                startPointer = response.data.start_offset
                endPointer = response.data.end_offset
              })
              .catch(function (error) {
                console.error(error)
              })
            await this.setState(state => ({
              response_upload_videos: { 
                ...state.response_upload_videos,
                start_offset: startPointer,
                end_offset: endPointer,
              },
            }))
          }
          FB.api(
            `105783408408077/videos?upload_phase=finish&access_token=${get_accounts.data[0].access_token}&upload_session_id=${this.state.response_upload_videos.upload_session_id}`,
            "POST",
            response_upload => {
                console.log(response_upload),
                this.setState({ loading: false, upload_video_status: true })
            }
          )
        }
      )
    })
  }
  postFacebook_liveVideos() {
    this.setState({ loading: true })
    FB.api("me/accounts", "GET", {}, get_accounts => {
      //console.log("token: ",response_accounts.data[0].access_token," /id: ",response_accounts.data[0].id);

      FB.api(
        "105783408408077/live_videos",
        "POST",
        { access_token: get_accounts.data[0].access_token },
        post_live_videos => {
          //post_live_videos.stream_url
          //post_live_videos.id
          this.setState({ stram_id: post_live_videos.id })

          //STREAM URL ex:rtmps://live-api-s.facebook.com:443/rtmp
          var indexOfRtmp = post_live_videos.stream_url.indexOf("/rtmp")
          this.setState({
            stream_ingestion: post_live_videos.stream_url.substring(
              0,
              indexOfRtmp + 5
            ),
          })
          console.log(
            "STREAM URL: ",
            post_live_videos.stream_url.substring(0, indexOfRtmp + 5)
          )

          //STREAM KEY ex:103365218646960?s_bl=1&s_psm=1&s_sw=0&s_vt=api-s&a=Aby8tzn78V_-zvey
          this.setState({
            stream_key: post_live_videos.stream_url.substring(
              indexOfRtmp + 6,
              post_live_videos.stream_url.length
            ),
          })
          console.log(
            "STREAM KEY: ",
            post_live_videos.stream_url.substring(
              indexOfRtmp + 6,
              post_live_videos.stream_url.length
            )
          )

          this.setState({ loading: false })
        }
      )
    })
  }

  mostrarVideoTransmision() {
    FB.api("105783408408077/live_videos", "GET", {}, get_live_videos => {
      console.log("get_live_videos", get_live_videos.data)
      this.setState({
        mensaje_error_video: "La transmisión aún no ha sido iniciada en OBS",
      })
      for (var i = 0; i < get_live_videos.data.length; i++) {
        console.log("n: ", get_live_videos.data[i])
        if (
          get_live_videos.data[i].id == this.state.stram_id &&
          get_live_videos.data[i].status == "LIVE"
        ) {
          this.setState({ mensaje_error_video: null })
          //VIDEO URL (decoded) ex:https://www.facebook.com/100069204452309/videos/103365215313627/
          var decode = decodeURIComponent(get_live_videos.data[i].embed_html)
          var indexOfIni = decode.indexOf("href=")
          var indexOfFin = decode.indexOf('" style')
          this.setState({ link: decode.substring(indexOfIni + 5, indexOfFin) })
          console.log(
            "VIDEO URL (decoded): ",
            decode.substring(indexOfIni + 5, indexOfFin)
          )

          const data = {
            stream_ingestion: this.state.stream_ingestion,
            stream_key: this.state.stream_key,
            link: this.state.link,
          }
          //console.log("DATA: ",data)
          const token = JSON.parse(localStorage.getItem("token"))
          axios
            .post(
              API_BASE_URL + `classdate/${this.state.classdate_id}/broadcast`,
              { data },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(res => {
              console.log("Broadcast guardado: ", res.data)
            })
            .catch(error => {
              if (error.response) {
                console.log("ERROR: ", error.response.data)
              }
            })
          this.setState({ mostarVideo: true })
          break
        }
      }
    })
  }

  saveVideoID() {
    const token = JSON.parse(localStorage.getItem("token"))
    let video_id = this.state.form_data.option_id === 2
      ? this.state.response_upload_videos.video_id
      : this.state.form_data.facebook_id
    let video_uploaded =
      this.state.form_data.option_id === 2
        ? 1
        : 0
          const data = {
            classdate_id: this.state.classdate_id,
            fb_video_id: video_id,
            fb_video_id: video_id,
            video_uploaded: video_uploaded,
          }
          axios
            .post(
              API_BASE_URL + `classdate/link`,
              { data },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(res => {
              console.log("Broadcast guardado: ", res.data)
              this.setState({
                save_video_id: true,
              })
            })
            .catch(error => {
              if (error.response) {
                console.log("ERROR: ", error.response.data)
              }
            })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Clases / Transmitir</title>
          </MetaTags>

          <Container fluid>
            <Row>
              <FormGroup className="row mb-4 pr-0">
                <Label
                  htmlFor="clasenombre"
                  className="col-sm-3 col-form-label"
                >
                  Seleccione la opción que desee
                </Label>
                <Col className="col-sm-9">
                  <Select
                    onChange={e => this.handleChangeSelect(e, "option_id")}
                    options={this.state.options}
                    id="option"
                    name="option"
                    placeholder="Seleccione..."
                  />
                </Col>
              </FormGroup>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  {/* <CardBody>
                  <FormGroup className="row mb-1">
                    <Label
                      htmlFor="clasenombre"
                      className="col-sm-3 col-form-label"
                    >
                      Instrucciones
                    </Label> */}

                  <div className="accordion" id="accordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button fw-medium"
                          type="button"
                          onClick={this.hanldeCollapse}
                          style={{ cursor: "pointer" }}
                        >
                          INSTRUCCIONES
                        </button>
                      </h2>
                      <Collapse
                        isOpen={this.state.collapse}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">
                          <div className="text-muted">
                            <Row>
                              <Col className="col-6">
                                <strong className="text-dark">
                                  Como descargar e instalar OBS Studio
                                </strong>
                                <ReactPlayer
                                  url="https://youtu.be/ARnlJKoodfQ"
                                  controls={true}
                                  width="100%"
                                />
                              </Col>
                              <Col className="col-6">
                                <strong className="text-dark">
                                  Como transmitir una clase con OBS Studio
                                </strong>
                                <ReactPlayer
                                  url="https://youtu.be/5fb0CDV9W1Y"
                                  controls={true}
                                  width="100%"
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  {/* </FormGroup>
                </CardBody> */}
                </Card>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={
                this.state.form_data?.option_id === 1
                  ? "Clases / Transmitir"
                  : this.state.form_data.option_id === 2
                  ? "Clases / Subir Archivo"
                  : "Clases/"
              }
              breadcrumbItem={
                this.state.form_data?.option_id === 1
                  ? "Clases / Transmitir"
                  : this.state.form_data.option_id === 2
                  ? "Clases / Subir Archivo"
                  : this.state.form_data.option_id === 3
                  ? "Clases / Ingresar ID de Facebook"
                  : "Clases"
              }
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {this.state.form_data.option_id !== null && (
                      <>
                        <FormGroup className="row mb-4">
                          <Label
                            htmlFor="clasenombre"
                            className="col-sm-3 col-form-label"
                          >
                            Nombre de la clase
                          </Label>
                          <Col className="col-sm-9">
                            <Select
                              onChange={this.handleChangeClaseNombre}
                              options={this.state.clases}
                              id="clase_nombre"
                              placeholder="Seleccione..."
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row mb-4">
                          <Label
                            htmlFor="clasedescripcion"
                            className="col-sm-3 col-form-label"
                          >
                            Descripción de la clase
                          </Label>
                          <Col className="col-sm-9">
                            <Input
                              type="textarea"
                              className="form-control"
                              value={this.state.descripcion}
                              disabled
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row mb-4">
                          <Label
                            htmlFor="clasegrupo"
                            className="col-sm-3 col-form-label"
                          >
                            Grupo de la clase
                          </Label>
                          <Col className="col-sm-9">
                            <Input
                              name="grupo"
                              type="text"
                              className="form-control"
                              value={this.state.grupo}
                              disabled
                            />
                          </Col>
                        </FormGroup>
                      </>
                    )}

                    {this.state.form_data.option_id === 3 && (
                      <FormGroup className="row mb-4 mt-5">
                        <Label
                          htmlFor="facebook_id"
                          className="col-sm-3 col-form-label"
                        >
                          ID Facebook
                        </Label>
                        <Col className="col-sm-9">
                          <Input
                            name="facebook_id"
                            type="text"
                            onChange={e => this.handleChangeInput(e)}
                            className="form-control"
                            value={this.state.form_data.facebook_id}
                            disabled={!this.state.botonLogin}
                          />
                        </Col>
                      </FormGroup>
                    )}

                    {this.state.mensaje_error &&
                      this.state.form_data.option_id === 1 && (
                        <Col className="col-12">
                          <div className="mt-3 d-grid">
                            <Alert color="danger">
                              <b>ADVERTENCIA: </b>
                              {this.state.mensaje_error}
                            </Alert>
                          </div>
                        </Col>
                      )}
                    {this.state.nombreCuenta && this.state.mensaje_error &&
                      (this.state.form_data.option_id === 2 ||
                        this.state.form_data.option_id === 3) && (
                        <>
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              <Alert color="danger">
                                <b>ADVERTENCIA: </b>
                                Esta clase ya cuenta con un ID de video, si
                                guarda un enlace nuevo este se cambiara
                              </Alert>
                            </div>
                          </Col>
                          <Col className="col-12 offset-3">
                            <ReactPlayer
                              url={this.state.link}
                              muted={true}
                              volume={0}
                              playing
                              width="50%"
                              height="50%"
                              //onReady={() => this.isVideoReady()}
                            />
                          </Col>
                          <br />
                          <br />
                        </>
                      )}
                    { this.state.mensaje_error &&
                      
                        this.state.form_data.option_id === 3 && (
                        <>
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              <Alert color="danger">
                                <b>ADVERTENCIA: </b>
                                Esta clase ya cuenta con un ID de video, si
                                guarda un enlace nuevo este se cambiara
                              </Alert>
                            </div>
                          </Col>
                          <Col className="col-12 offset-3">
                            <ReactPlayer
                              url={this.state.link}
                              muted={true}
                              volume={0}
                              playing
                              width="50%"
                              height="50%"
                              //onReady={() => this.isVideoReady()}
                            />
                          </Col>
                          <br />
                          <br />
                        </>
                      )}

                    {this.state.video_upload_proccess && (
                      <Alert color="success">
                        {this.state.video_upload_proccess_message}
                      </Alert>
                    )}

                    {this.state.botonLogin &&
                      (this.state.form_data.option_id === 1 ||
                        this.state.form_data.option_id === 2) && (
                        <Row>
                          <Col className="col-3 offset-3">
                            <FacebookLogin
                              appId="388252979143300"
                              //appId desa: 388252979143300
                              //appId test: 344287823720615
                              autoLoad={true}
                              fields="name,email,picture"
                              callback={this.responseFacebook}
                              icon="fa-facebook"
                              textButton="Logear con Facebook"
                            />
                          </Col>
                          {!this.state.nombreCuenta && (
                            <Col className="col-6">
                              <Alert color="warning">
                                <b>ATENCIÓN: </b>Recuerde logearse con la cuenta
                                de facebook de Apptivate
                              </Alert>
                            </Col>
                          )}
                        </Row>
                      )}
                    {this.state.nombreCuenta &&
                      this.state.form_data.option_id === 2 && (
                        <FormGroup className="row mb-4">
                          <Label
                            htmlFor="file"
                            className="col-sm-3 col-form-label"
                          >
                            Archivo
                          </Label>
                          <Col className="col-sm-9">
                            <Input
                              name="file"
                              type="file"
                              className="form-control"
                              value={this.state.form_data.file}
                              onChange={e => this.handleFile(e.target.files)}
                              disabled={this.state.botonLogin}
                            />
                          </Col>
                        </FormGroup>
                      )}
                    {this.state.nombreCuenta && (
                      <FormGroup className="row mb-4">
                        <Col className="col-12">
                          <Alert color="success">
                            Cuenta de facebook logeada con éxito
                          </Alert>
                        </Col>
                        <Label
                          htmlFor="clasegrupo"
                          className="col-sm-3 col-form-label"
                        >
                          Nombre cuenta
                        </Label>
                        <Col className="col-sm-9">
                          <Input
                            name="grupo"
                            type="text"
                            className="form-control"
                            value={this.state.nombreCuenta}
                            disabled
                          />
                        </Col>
                      </FormGroup>
                    )}

                    {this.state.form_data.option_id === 1 &&
                      this.state.nombreCuenta && (
                        <Col className="col-12">
                          <div className="mt-3 d-grid">
                            <button
                              disabled={this.state.loading ? true : false}
                              className="btn btn-primary btn-block"
                              onClick={() => this.postFacebook_liveVideos()}
                            >
                              Generar clave de transmisión
                            </button>
                          </div>
                        </Col>
                      )}
                    {this.state.form_data.option_id === 2 &&
                      this.state.nombreCuenta && (
                        <Col className="col-12">
                          <div className="mt-3 d-grid">
                            <button
                              disabled={this.state.loading ? true : false}
                              className="btn btn-primary btn-block"
                              onClick={() => this.uploadFacebook_videos()}
                            >
                              Subir Video
                            </button>
                          </div>
                        </Col>
                      )}
                    {this.state.loading && (
                      <>
                      <br></br>
                        <Alert>
                          Subiendo video {((this.state.response_upload_videos.start_offset / this.state.form_data.video_size)*100).toFixed(1)}%
                        </Alert>
                      <Col className="col-6 offset-6">
                        <SyncLoader
                          color={"#11998f"}
                          loading={this.state.loading}
                        />
                        </Col>
                        </>
                    )}
                    {this.state.stream_key && (
                      <FormGroup className="row">
                        <Label
                          htmlFor="clasegrupo"
                          className="col-sm-3 col-form-label"
                        >
                          Clave de transmisión
                        </Label>
                        <Col className="col-sm-9">
                          <Input
                            name="grupo"
                            type="text"
                            className="form-control"
                            value={this.state.stream_key}
                            disabled
                          />
                        </Col>
                      </FormGroup>
                    )}
                    <br></br>
                    {this.state.stream_key && (
                      <Col className="col-12">
                        <Alert color="warning">
                          <b>ATENCIÓN:</b> Una vez iniciada la transmisión en
                          OBS con la clave entregda, se podrá mostrar el video
                          de transmisión.
                        </Alert>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={() => this.mostrarVideoTransmision()}
                          >
                            Publicar video de transmisión
                          </button>
                        </div>
                      </Col>
                    )}
                    <br></br>
                    {this.state.upload_video_status && (
                      <Col className="col-12">
                        {this.state.upload_video_status &&
                          !this.state.save_video_id && (
                            <Alert color="warning">
                              <b>ATENCIÓN:</b>Una vez que de click en
                              <b>"Publicar video"</b> el video tardará
                              aproximadamente 30 minutos en que Facebook lo
                              publique
                            </Alert>
                          )}
                        {this.state.save_video_id && (
                          <Alert color="success">
                            <b>ATENCIÓN:</b> El id video se ha guardado
                            correctamente
                          </Alert>
                        )}
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={e => this.saveVideoID(e)}
                          >
                            Publicar video
                          </button>
                        </div>
                      </Col>
                    )}
                    <br></br>
                    {this.state.form_data.option_id === 3 && (
                      <Col>
                        {this.state.save_video_id && (
                          <Alert color="success">
                            <b>ATENCIÓN:</b> El id video se ha guardado
                            correctamente
                          </Alert>
                        )}
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={e => this.saveVideoID(e)}
                          >
                            Publicar video
                          </button>
                        </div>
                      </Col>
                    )}
                    <br></br>
                    {this.state.mostarVideo /*this.state.link*/ && (
                      <Col className="col-6 offset-3">
                        <ReactPlayer
                          url={this.state.link}
                          muted={true}
                          volume={0}
                          playing
                          width="100%"
                          //onReady={() => this.isVideoReady()}
                        />
                      </Col>
                    )}
                    {this.state.mensaje_error_video && (
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          <Alert color="danger">
                            {this.state.mensaje_error_video}
                          </Alert>
                        </div>
                      </Col>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {this.state.class_status_video && (
          <Modal isOpen={this.state.class_status_video} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">Clase</h5>
            </div>
            <div className="modal-body">
              <Row>
                <Col sm={12}>
                  <Label>
                    Esta clase ya tiene un video grabado y listo para ser visto
                    por los alumnos, si transmites, subes o asocias un link de
                    otro video a esta clase, el video anterior se perderá y la
                    clase quedará con el nuevo video. ¿Estas seguro de
                    continuar?
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="d-grid">
                    <Button
                      block
                      color="success"
                      onClick={() => {
                        this.setState({
                          class_status_video: false,
                        })
                      }}
                    >
                      Si
                    </Button>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="d-grid">
                    <Button
                      block
                      color="danger"
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

export default ClasesTransmitir
