import React, { Component } from "react"
import PropTypes from 'prop-types'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"

// import images
//import profile from "../../assets/images/profile-img.png"
import lightlogo from "../../assets/images/logo-light.svg"
//import logo from "../../assets/images/logo.svg"
import logo from "../../assets/images/personalizadas/logo_healt.png"
import logo_apple from "../../assets/images/logo_apple.svg"
import logo_android from "../../assets/images/logo_android.svg"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
    console.log("email ",this.state.email);
  }
  handleChangePass = event => {
    this.setState({ password: event.target.value });
    console.log("password ",this.state.password);
  }

  getDatosCesfam(cesfamId){
    localStorage.setItem("cesfamName", JSON.stringify(null))
    localStorage.setItem("comunaName", JSON.stringify(null))
    if(cesfamId){
      const token = JSON.parse(localStorage.getItem("token"))
      axios.get(API_BASE_URL+`cesfam/${cesfamId}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          localStorage.setItem("cesfamName", JSON.stringify(res.data.data.name))
          localStorage.setItem("comunaName", JSON.stringify(res.data.data.Cesfam_BelongsTo_Commune.name))
          window.location = "/inicio";
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
            //window.location = "/inicio";
            //window.location = "/login";
          }
        });
    }else{
      window.location = "/inicio";
    }
  }

  handleValidSubmit(event, values) {
    event.preventDefault();

    const data = {
      email: this.state.email,
      password: this.state.password
    };
    console.log("user ",data);
    
    axios.post(API_BASE_URL+`login`, {data})
      .then(res => {
        console.log("res.data LOGIN: ",res.data);
        localStorage.setItem("authUser", JSON.stringify(res.data.user))
        localStorage.setItem("token", JSON.stringify(res.data.token))
        var cesfamId = null
        if(res.data.user.User_Has_Admin[0]){
          localStorage.setItem("userId", JSON.stringify(res.data.user.User_Has_Admin[0].id))
        }
        if(res.data.user.User_Has_Teacher[0]){
          cesfamId = res.data.user.User_Has_Teacher[0].cesfam_id
          localStorage.setItem("userId", JSON.stringify(res.data.user.User_Has_Teacher[0].id))
        }
        if(res.data.user.User_Has_Patient[0]){
          cesfamId = res.data.user.User_Has_Patient[0].cesfam_id
          localStorage.setItem("userId", JSON.stringify(res.data.user.User_Has_Patient[0].id))
        }
        this.getDatosCesfam(cesfamId)  
      })
      .catch((error) =>
        this.setState({errorMessage: "Error: verifique email y/o password que sean correctos"}));
  }

  componentDidMount() {
    this.props.apiError("")
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden" id="login_fondo">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-5">
                          <h5 className="text-bienvenida">Bienvenido</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        {/* <img src={profile} alt="" className="img-fluid"/> */}
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="72"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.state.errorMessage && <Alert color="danger" >{this.state.errorMessage}</Alert>}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            type="email"
                            required
                            placeholder="Igrese email"
                            errorMessage="Ingrese email"
                            onChange={this.handleChangeEmail}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Ingrese password"
                            errorMessage="Ingrese password"
                            onChange={this.handleChangePass}
                          />
                        </div>

                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Recordar datos
                          </label>
                        </div> */}

                        <div className="mt-3 d-grid" id="login_gridbotoningresar">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Ingresar
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/recuperar-contrasena" className="text-muted" id="login_forgotpassword">
                            <i className="mdi mdi-lock me-1" /> Olvidó su contraseña?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    <a href="https://play.google.com/store/apps/details?id=cl.apptivate.app" target="_blank"><img src={logo_android} /></a>
                    <a href="https://apps.apple.com/us/app/app-tivate/id1571535977" target="_blank"><img src={logo_apple} /></a>
                  </p>
                  <p>
                    Necesitas ayuda? 
                    <Link to="/soporte">
                      &nbsp;Soporte
                    </Link>
                  </p>
                  <p>
                    <Link to="/terminos-condiciones">
                      &nbsp;Terminos y condiciones
                    </Link>
                  </p>
                  <p>
                    <Link to="/politicas-privacidad">
                      &nbsp;Politicas de Privacidad
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} <a href="https://www.usercode.cl" target="_blank">UserCode SpA</a> todos los derechos reservados.
                  </p>          
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
