import React from "react"
import { Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © <a href="https://www.usercode.cl" target="_blank">UserCode SpA.</a>
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Diseñado & Desarrollado por <a href="https://www.usercode.cl" target="_blank">UserCode SpA.</a>
              </div>
              <div className="text-sm-end d-none d-sm-block">
                <a href="https://www.apptivate.cl/terminos-condiciones" target="_blank">Términos y Condiciones</a>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
