import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { } from "../../store/actions"

import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import SidebarAdmin from "./SidebarAdmin"
import SidebarOperador from "./SidebarOperador"
import SidebarUsuario from "./SidebarUsuario"

//import logo from "../../assets/images/logo.svg"
import logo from "../../assets/images/personalizadas/logo_healt.png"
//import logoLightPng from "../../assets/images/logo-light.png"
import logoLightPng from "../../assets/images/personalizadas/logo_healt.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    //console.log(obj)

    return (
      <React.Fragment>

        <div className="vertical-menu">
          <div className="navbar-brand-box" id="menu_brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="17" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightPng} alt="" height="30" />
              </span>
              <span className="logo-lg">
                <img src={logoLightPng} alt="" height="50" />
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100" id="menu_data-simplebar">
            {this.props.type !== "condensed" ? (
              obj.rol_id == 3 ? (
                <SidebarAdmin />
              ) : (
                obj.rol_id == 2 ? (
                  <SidebarOperador />
                ) : (
                  <SidebarUsuario />
                )
              )
            ) : (
              obj.rol_id == 3 ? (
                <SidebarAdmin />
              ) : (
                obj.rol_id == 2 ? (
                  <SidebarOperador />
                ) : (
                  <SidebarUsuario />
                )
              )
            )}
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    )
  }
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
