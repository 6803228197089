import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
class ClasesHistorial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      clases: [],
      sizePerPage: 10
    }
  }

  listarClases(){
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})
    
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    //console.log("ano: ",ano, "/mes: ", mes, "/dia: ",dia)
    const hasta = ano+"-"+mes+"-"+dia;
    //const hasta = "2021-12-12"

    axios.get(API_BASE_URL+`class/2021-01-01/${hasta}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        this.setState({clases: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  formatFecha = (cell, row, rowIndex, formatExtraData) => {
    var fecha ='';
    if(row.date!=null){
      var fecha = row.date.substr(0,10); 
      //console.log("fecha: ",row.User_Has_Patient[0].dateofbirth);
    }
    return (
      <>{fecha}</>
    );
  };

  componentDidMount(){
    this.listarClases();
  }

  render() {
    const columns = [{
      dataField: 'classdate_id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'date',
      text: 'Fecha',
      headerStyle: { width: '10%' },
      formatter: this.formatFecha,
      sort: true
    }, {
      dataField: 'Classdates_belongsTo_class.name',
      text: 'Nombre',
      headerStyle: { width: '20%' },
      sort: true
    }, {
      dataField: 'Classdates_belongsTo_class.description',
      text: 'Detalle',
      headerStyle: { width: '60%' },
      style: { textAlign: 'justify' },
      /*headerStyle: () => {
        return { maxWidth: '10px' };
        },*/
      sort: true
    }, {
      dataField: 'link',
      text: 'Link',
      headerStyle: { width: '10%' },
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'classdate_id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      //totalSize: clases.length, // replace later with size(customers),
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Clases / Historial</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Clases / Historial" breadcrumbItem="Clases / Historial" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='classdate_id'
                      columns={columns}
                      data={this.state.clases}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='classdate_id'
                          columns={columns}
                          data={this.state.clases}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder={"Buscar"}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"classdate_id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle" /*table-nowrap*/
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ClasesHistorial