import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { API_BASE_URL } from "helpers/url_helper"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  Alert,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class PerfilUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
  }

  handleChangeFechanacimiento = event => {
    this.setState({ fechanacimiento: event.fechanacimiento });
  }

  render() {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    console.log("obj: ", obj)

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Perfil</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Perfil" breadcrumbItem="Perfil" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientenombre"
                          className="col-sm-2 col-form-label"
                        >
                          Nombre: {obj.name}
                        </Label>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteapellido"
                          className="col-sm-2 col-form-label"
                        >
                          Apellido: {obj.lastname}
                        </Label>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienterut"
                          className="col-sm-2 col-form-label"
                        >
                          RUT: {obj.rut}
                        </Label>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientedireccion"
                          className="col-sm-2 col-form-label"
                        >
                          Direccion: {obj.User_Has_Patient[0].address}
                        </Label>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteemail"
                          className="col-sm-2 col-form-label"
                        >
                          Email: {obj.email}
                        </Label>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientetelefono"
                          className="col-sm-2 col-form-label"
                        >
                          Teléfono: {obj.User_Has_Patient[0].telephone}
                        </Label>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientefechanacimiento"
                          className="col-sm-2 col-form-label"
                        >
                          Fecha nacimiento: {obj.User_Has_Patient[0].dateofbirth && obj.User_Has_Patient[0].dateofbirth.substring(0,10)}
                        </Label>
                      </FormGroup>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default PerfilUser