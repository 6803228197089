import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { API_BASE_URL } from "helpers/url_helper"

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  InputGroup,
  Label,
  Alert,
  Input,
  FormGroup
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import axios from "axios"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import allLocales from '@fullcalendar/core/locales-all';
//css
import "@fullcalendar/bootstrap/main.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getEvents,
  addNewEvent,
  updateEvent,
  deleteEvent
} from "../../store/actions"
import DeleteModal from "./DeleteModal"

class ClasesCalendario extends Component {
  constructor(props) {
    super(props)
    this.handleDateClick = this.handleDateClick.bind(this)
    this.calendarComponentRef = React.createRef()

    this.state = {
      calendarWeekends: true,
      mostrarWeekDays: false,
      modal: false,
      deleteModal: false,
      event: {},
      grupos:[]
    }

    this.toggle = this.toggle.bind(this)
  }

  componentDidMount = () => {
    this.listarGrupos();
  }

  /**
   * Handling the modal state
   */
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  /**
   * Handling date click on calendar
   */
  handleDateClick = arg => {
    //console.log("event: ", arg)
    this.setState({ ownerClass: true })
    this.setState({ grupo_label: null })
    this.setState({ grupo: null })
    this.setState({ isEdit: false })
    this.setState({mostrarWeekDays: false})
    this.setState({mensaje_error: null})
    this.setState({lunes: false})
    this.setState({martes: false})
    this.setState({miercoles: false})
    this.setState({jueves: false})
    this.setState({viernes: false})
    this.setState({sabado: false})
    this.setState({domingo: false})
    this.toggle()
  }

  /**
   * Handling click on event on calendar
   */
  handleEventClick = arg => {
    this.setState({ grupo_label: null })
    this.setState({ nombre: null })
    this.setState({ descripcion: null })
    this.setState({ hora: null })
    this.setState({ fecha: null })
    this.setState({ mensaje_error: null })
    this.setState({ isEdit: true })
    this.toggle()

    var userId = JSON.parse(localStorage.getItem("userId"))
    console.log("arg.event: ", arg.event._def.extendedProps.teacher_id, " /userID: ",userId)
    //console.log("nombre: ", arg.event._def.extendedProps.profesor_nombre, " /apellido: ",arg.event._def.extendedProps.profesor_apellido)
    this.setState({ profesor_nombre: arg.event._def.extendedProps.profesor_nombre })
    this.setState({ profesor_apellido: arg.event._def.extendedProps.profesor_apellido })
    if(userId==arg.event._def.extendedProps.teacher_id){
      this.setState({ ownerClass: true })
    }else{
      this.setState({ ownerClass: false })
    }

    const classdate_id = arg.event._def.publicId

    const token = JSON.parse(localStorage.getItem("token"))

    axios.get(API_BASE_URL+`classdate/${classdate_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("classdate data: ",res.data.data);
        this.setState({ classdate_id_delete: res.data.data.myClass.Class_Has_Classdates[0].id })
        this.setState({ class_id_delete: res.data.data.myClass.id })
        this.setState({ nombre: res.data.data.myClass.name })
        this.setState({ descripcion: res.data.data.myClass.description })
        if(this.state.ownerClass){
          axios.get(API_BASE_URL+`group/${res.data.data.myClass.group_id}`, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
              this.setState({ grupo_label: res.data.data.group})
            })
            .catch(error => {
              if (error.response) {
                console.log("ERROR: ",error.response.data); 
              }
            });
        }
        var fecha = res.data.data.myClass.Class_Has_Classdates[0].date.substring(0, 10)
        var hora = res.data.data.myClass.Class_Has_Classdates[0].date.substring(11, 16)
        this.setState({ hora: hora })
        this.setState({ fecha: fecha })
        console.log("fecha: ",fecha, " /hora:",hora)
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  /**
   * On delete event
   */
  handleDeleteEvent = () => {
    const token = JSON.parse(localStorage.getItem("token"))

    if(this.state.eliminarTodos){
      axios.delete(API_BASE_URL+`class/${this.state.class_id_delete}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("Eliminar class: ",res.data.data);
        this.listarClases();
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
    }
    else{
      axios.delete(API_BASE_URL+`classdate/${this.state.classdate_id_delete}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("Eliminar classdate: ",res.data.data);
        this.listarClases();
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
    }
    this.setState({ class_id_delete: false })
    this.setState({ classdate_id_delete: false })
    this.setState({ deleteModal: false })
    this.toggle()

  }

  listarGrupos(){
    this.state.grupos=[]
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`group`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("GRUPOS res.data: ",res.data.data);
        var largoLista = res.data.data.length;
        for (var i = 0; i < largoLista; i++) {
          var grupo = {};
          grupo["value"] = res.data.data[i].id;
          grupo["label"] = res.data.data[i].group;
          this.state.grupos.push(grupo);
        }
        this.setState({grupos: this.state.grupos});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  crearClase(){
    this.setState({mensaje_error: null})

    var dias = []
    var fechas = []
    if(this.state.fechas!=null){
      if(this.state.fechas.includes("a")){
        var recurrente = true
        var fechaIni = this.state.fechas.substr(0,10);
        var fechaFin = this.state.fechas.substr(13,24);
        fechas.push(fechaIni+" "+this.state.hora);
        fechas.push(fechaFin+" "+this.state.hora);
        if(this.state.lunes){
          dias.push(1);
        }
        if(this.state.martes){
          dias.push(2);
        }
        if(this.state.miercoles){
          dias.push(3);
        }
        if(this.state.jueves){
          dias.push(4);
        }
        if(this.state.viernes){
          dias.push(5);
        }
        if(this.state.sabado){
          dias.push(6);
        }
        if(this.state.domingo){
          dias.push(0);
        }
      }
      else{
        var recurrente = false
        fechas.push(this.state.fechas+" "+this.state.hora);
      }
    }
    
    const data = {
      classname: this.state.nombre,
      description: this.state.descripcion,
      duration: "60",
      group_id: this.state.grupo,
      recurrent: recurrente,
      dateArray: fechas,
      weekDays: dias
    };
    console.log("DATA:",data)

    const token = JSON.parse(localStorage.getItem("token"))

    if(!this.state.isEdit){
      //Crear
      axios.post(API_BASE_URL+`class`, {data}, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          console.log("res.data: ",res.data);
          this.listarClases();
          this.toggle();
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data);
            if(error.response.data.errors){
              for (var i = 0; i < error.response.data.errors.length; i++) {
                if (error.response.data.errors[i]["data.dateArray"]) {
                  this.setState({mensaje_error: "Ups, error: "+error.response.data.errors[i]["data.dateArray"]})
                }
                if (error.response.data.errors[i]["data.weekDays"]) {
                  this.setState({mensaje_error: "Ups, error: "+error.response.data.errors[i]["data.weekDays"]})
                }
                if (error.response.data.errors[i]["data.group_id"]) {
                  this.setState({mensaje_error: "Ups, error: "+error.response.data.errors[i]["data.group_id"]})
                }
                if (error.response.data.errors[i]["data.classname"]) {
                  this.setState({mensaje_error: "Ups, error: "+error.response.data.errors[i]["data.classname"]})
                }
                if (error.response.data.errors[i]["data.description"]) {
                  this.setState({mensaje_error: "Ups, error: "+error.response.data.errors[i]["data.description"]})
                }
              }
            }
          }
        });
    }
  }

  handleChangeNombre = event => {
    this.setState({ nombre: event.target.value });
  }
  handleChangeDescripcion = event => {
    this.setState({ descripcion: event.target.value });
  }
  handleChangeGrupo = event => {
    this.setState({ grupo: event.value})
    this.setState({ grupo_label: event.label})
  }
  handleChangeFechas = event => {
    this.setState({ fechas: event.fechas });
    //rango de fechas? mostrar dias
    if(event.fechas.includes("a")){
      this.setState({mostrarWeekDays: true})
    }else{
      this.setState({mostrarWeekDays: false})
    }
  }
  handleChangeHora = event => {
    this.setState({ hora: event.hora });
  }
  handleChangeLunes = event => {
    this.setState({ lunes: event.target.checked });
  }
  handleChangeMartes = event => {
    this.setState({ martes: event.target.checked });
  }
  handleChangeMiercoles = event => {
    this.setState({ miercoles: event.target.checked });
  }
  handleChangeJueves = event => {
    this.setState({ jueves: event.target.checked });
  }
  handleChangeViernes = event => {
    this.setState({ viernes: event.target.checked });
  }
  handleChangeSabado = event => {
    this.setState({ sabado: event.target.checked });
  }
  handleChangeDomingo = event => {
    this.setState({ domingo: event.target.checked });
  }
  handleChangeFechasCalendario = event => {
    var fechaIni = event.startStr.substr(0,10);
    var fechaFin = event.endStr.substr(0,10);
    this.setState({ fechaIniCalendario: fechaIni });
    this.setState({ fechaFinCalendario: fechaFin });

    const token = JSON.parse(localStorage.getItem("token"))

    axios.get(API_BASE_URL+`class/${fechaIni}/${fechaFin}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data ini-fin: ",res.data.data);
        var userId = JSON.parse(localStorage.getItem("userId"))

        var clases=[];
        var largoLista = res.data.data.length;
        for (var i = 0; i < largoLista; i++) {
          var clase = {};
          clase["id"] = res.data.data[i].classdate_id;
          clase["title"] = res.data.data[i].Classdates_belongsTo_class.name;
          clase["date"] = res.data.data[i].date.substr(0,16);
          clase["extendedProps"] = { teacher_id: res.data.data[i].Classdates_belongsTo_class.teacher_id,
            profesor_nombre: res.data.data[i].Classdates_belongsTo_class.Class_belongsTo_Teacher.Teacher_BelongsTo_User.name,
            profesor_apellido: res.data.data[i].Classdates_belongsTo_class.Class_belongsTo_Teacher.Teacher_BelongsTo_User.lastname }
          if(userId==res.data.data[i].Classdates_belongsTo_class.teacher_id){
            clase["className"] = "bg-success text-white"
          }
          clases.push(clase);
        }
        this.setState({clases: clases});
        
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  listarClases(){
    var fechaIni = this.state.fechaIniCalendario;
    var fechaFin = this.state.fechaFinCalendario;

    const token = JSON.parse(localStorage.getItem("token"))

    axios.get(API_BASE_URL+`class/${fechaIni}/${fechaFin}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data INI-FIN: ",res.data.data);
        var userId = JSON.parse(localStorage.getItem("userId"))

        var clases=[];
        var largoLista = res.data.data.length;
        for (var i = 0; i < largoLista; i++) {
          var clase = {};
          clase["id"] = res.data.data[i].classdate_id;
          clase["title"] = res.data.data[i].Classdates_belongsTo_class.name;
          clase["date"] = res.data.data[i].date.substr(0,16);
          clase["extendedProps"] = { teacher_id: res.data.data[i].Classdates_belongsTo_class.teacher_id,
            profesor_nombre: res.data.data[i].Classdates_belongsTo_class.Class_belongsTo_Teacher.Teacher_BelongsTo_User.name,
            profesor_apellido: res.data.data[i].Classdates_belongsTo_class.Class_belongsTo_Teacher.Teacher_BelongsTo_User.lastname }
          if(userId==res.data.data[i].Classdates_belongsTo_class.teacher_id){
            clase["className"] = "bg-success text-white"
          }
          clases.push(clase);
        }
        this.setState({clases: clases});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  render() {
    const { isEdit, deleteModal } = this.state
    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteEvent}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
        <MetaTags>
            <title>Clases / Calendario</title>
          </MetaTags>
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Clases / Calendario" breadcrumbItem="Clases / Calendario" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12">
                        {/* fullcalendar control */}
                        <FullCalendar
                          ref={this.calendarComponentRef}
                          plugins={[
                            BootstrapTheme,
                            dayGridPlugin,
                            interactionPlugin, 
                          ]}
                          datesSet={(args) => this.handleChangeFechasCalendario(args)}
                          locales={allLocales}
                          locale='es'
                          slotDuration={"00:15:00"}
                          handleWindowResize={true}
                          themeSystem="bootstrap"
                          events={this.state.clases}
                          editable={true}
                          selectable={true}
                          dateClick={this.handleDateClick}
                          eventClick={this.handleEventClick}
                        />

                        {/* New/Edit event modal */}
                        <Modal
                          isOpen={this.state.modal}
                          //className={this.props.className}
                          id="event-modal"
                        >
                          <ModalHeader toggle={this.toggle} tag="h4">
                            {!!isEdit ? "Detalle clase" : "Crear clase"}
                          </ModalHeader>
                          <ModalBody>
                            <AvForm>
                              <Row>
                                <Col className="col-12 mb-3">
                                  <AvField
                                    name="nombre"
                                    label="Nombre de la clase"
                                    placeholder="Ingrese nombre"
                                    type="text"
                                    errorMessage="Ingrese nombre"
                                    onChange={this.handleChangeNombre}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={isEdit && this.state.nombre}
                                    disabled={isEdit ? true : false}
                                  />
                                </Col>
                                <Col className="col-12 mb-3">
                                  <AvField
                                    name="descripcion"
                                    label="Descripción de la clase"
                                    placeholder="Ingrese descripción"
                                    type="textarea"
                                    errorMessage="Ingrese descripción"
                                    onChange={this.handleChangeDescripcion}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={isEdit && this.state.descripcion}
                                    disabled={isEdit ? true : false}
                                  />
                                </Col>
                                {this.state.ownerClass && (
                                  <Col className="col-12 mb-3">
                                    <Label>Grupo</Label>
                                    {
                                      isEdit ?
                                        <Select
                                          value={{ label: this.state.grupo_label}}
                                          isDisabled={true}
                                        />
                                      :
                                        <Select
                                          onChange={this.handleChangeGrupo}
                                          options={this.state.grupos}
                                          placeholder="Seleccione..."
                                        />
                                    }
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col className="col-6">
                                {!isEdit ?
                                  <Label>Rango de fechas</Label> :
                                  <Label>Fecha</Label>
                                }
                                  {!isEdit &&
                                    <InputGroup>
                                      <Flatpickr
                                        onChange={(date, fechas) => {this.handleChangeFechas({fechas})}}
                                        className="form-control d-block"
                                        placeholder="Seleccione.."
                                        options={{
                                          mode: "range",
                                          dateFormat: "Y-m-d",
                                          locale: {
                                            ...Spanish
                                          }
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i className="mdi mdi-calendar-outline" />
                                        </span>
                                      </div>
                                    </InputGroup>
                                  }
                                  {isEdit &&
                                    <InputGroup>
                                      <Input
                                        value={this.state.fecha}
                                        disabled
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i className="mdi mdi-clock-outline" />
                                        </span>
                                      </div>
                                    </InputGroup>
                                  }
                                </Col>
                                <Col className="col-6">
                                <Label>Hora</Label>
                                  {!isEdit &&
                                    <InputGroup>
                                      <Flatpickr
                                        onChange={(time, hora) => {this.handleChangeHora({hora})}}
                                        className="form-control d-block"
                                        placeholder="Seleccione.."
                                        options={{
                                          enableTime: true,
                                          noCalendar: true,
                                          dateFormat: "H:i"
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i className="mdi mdi-clock-outline" />
                                        </span>
                                      </div>
                                    </InputGroup>
                                  }
                                  {isEdit &&
                                    <InputGroup>
                                      <Input
                                        value={this.state.hora}
                                        disabled
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i className="mdi mdi-clock-outline" />
                                        </span>
                                      </div>
                                    </InputGroup>
                                  }
                                </Col>
                              </Row>
                              {this.state.mostrarWeekDays &&
                                <Row>
                                  <Col className="col-12">
                                    <div className="mt-4">
                                      <Label>Días de la semana</Label>
                                      <div>
                                        <div className="form-check form-check-success">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={this.handleChangeLunes}
                                            checked={this.state.lunes}
                                          />
                                          <label className="form-check-label">
                                            Lunes
                                          </label>
                                        </div>
                                        <div className="form-check  form-check-success">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="diaMartes"
                                            onChange={this.handleChangeMartes}
                                            checked={this.state.martes}
                                          />
                                          <label className="form-check-label">
                                            Martes
                                          </label>
                                        </div>
                                        <div className="form-check form-check-success">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={this.state.miercoles}
                                            onChange={this.handleChangeMiercoles}
                                          />
                                          <label className="form-check-label">
                                            Miércoles
                                          </label>
                                        </div>
                                        <div className="form-check  form-check-success">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={this.state.jueves}
                                            onChange={this.handleChangeJueves}
                                          />
                                          <label className="form-check-label">
                                            Jueves
                                          </label>
                                        </div>
                                        <div className="form-check form-check-success">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={this.state.viernes}
                                            onChange={this.handleChangeViernes}
                                          />
                                          <label className="form-check-label">
                                            Viernes
                                          </label>
                                        </div>
                                        <div className="form-check form-check-success">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={this.state.sabado}
                                            onChange={this.handleChangeSabado}
                                          />
                                          <label className="form-check-label">
                                            Sábado
                                          </label>
                                        </div>
                                        <div className="form-check form-check-success">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={this.state.domingo}
                                            onChange={this.handleChangeDomingo}
                                          />
                                          <label className="form-check-label">
                                            Domingo
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              }
                              <br></br>
                              <Row>
                                <Col>
                                  {!this.state.ownerClass && (
                                    <Alert color="warning" >Profesor a cargo de la clase: <b>{this.state.profesor_nombre+" "+this.state.profesor_apellido}</b></Alert>
                                  )}
                                  {this.state.ownerClass & isEdit ? (
                                    <Col className="col-12">
                                      <div className="mt-1 d-grid">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-block"
                                        onClick={() =>
                                          this.setState({ deleteModal: true, eliminarTodos: false })
                                        }
                                      >
                                        Eliminar sólo esta clase
                                      </button>
                                      </div>
                                    </Col>
                                  ) : (null)}
                                  {this.state.ownerClass & isEdit ? (
                                    <Col className="col-12">
                                      <div className="mt-1 d-grid">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-block"
                                        onClick={() =>
                                          this.setState({ deleteModal: true, eliminarTodos: true })
                                        }
                                      >
                                        Eliminar ésta y todas sus clases asociadas
                                      </button>
                                      </div>
                                    </Col>
                                  ) : (null)}
                                  {!isEdit && (
                                    <Col className="col-12">
                                    <div className="mt-1 d-grid">
                                    <button
                                      type="submit"
                                      className="btn btn-success btn-block"
                                      onClick={() => this.crearClase()}
                                    >
                                      Guardar
                                    </button>
                                    </div>
                                    </Col>
                                  )}
                                  <Col className="col-12">
                                    <div className="mt-1 d-grid">
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        onClick={this.toggle}
                                      >
                                        Cerrar
                                      </button>
                                    </div>
                                  </Col>
                                </Col>
                                <Col className="col-12">
                                  <div className="mt-3 d-grid">
                                    {this.state.mensaje_error && <Alert color="danger" >{this.state.mensaje_error}</Alert>}
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </ModalBody>
                        </Modal>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ClasesCalendario.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array,
  //onAddNewEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  //onGetEvents: PropTypes.func,
  //onUpdateEvent: PropTypes.func
}

/*const mapStateToProps = ({ calendar }) => ({
  events: calendar.events
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})*/

//export default connect(mapStateToProps, mapDispatchToProps)(ClasesCalendario)
export default ClasesCalendario