import React, { Component, useState  } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  FormGroup,
  Collapse
} from "reactstrap"
import { API_BASE_URL } from "helpers/url_helper"

import Select from "react-select"
import axios from "axios"
import ReactPlayer from 'react-player'
import FacebookLogin from 'react-facebook-login'
import SyncLoader from "react-spinners/SyncLoader";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


class TutorialUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseBrazos: false,
      collapsePiernas: false,
      collapseTronco: false,
      collapseCardiovasculares: false,
      videosBrazos: [{
        name:null,
        url:null
      }],
      videosPiernas: [{
        name:null,
        url:null
      }],
      videosTronco: [{
        name:null,
        url:null
      }],
      videosCardiovasculares: [{
        name:null,
        url:null
      }]
    };
  }
  
  hanldeCollapseBrazos = () => {
    this.setState({ collapseBrazos: !this.state.collapseBrazos,
      collapsePiernas: false, collapseTronco: false, collapseCardiovasculares: false
     })
  }
  hanldeCollapsePiernas = () => {
    this.setState({ collapsePiernas: !this.state.collapsePiernas,
      collapseBrazos: false, collapseTronco: false, collapseCardiovasculares: false
    })
  }
  hanldeCollapseTronco = () => {
    this.setState({ collapseTronco: !this.state.collapseTronco,
      collapseBrazos: false, collapsePiernas: false, collapseCardiovasculares: false
    })
  }
  hanldeCollapseCardiovasculares = () => {
    this.setState({ collapseCardiovasculares: !this.state.collapseCardiovasculares,
      collapseBrazos: false, collapsePiernas: false, collapseTronco: false
    })
  }

  listarVideos(){
    const token = JSON.parse(localStorage.getItem("token"))

    axios.get(API_BASE_URL+`videos`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        //Ejercicios de brazos
        //Ejercicios de piernas
        //Ejercicios de tronco
        //Ejercicios cardiovasculares
        for (var i = 0; i < res.data.data.length; i++) {
          var video = {}
          if(res.data.data[i].Video_BelongsTo_Videocategories.category=="Ejercicios de brazos"){
            video = { name: res.data.data[i].name, url: res.data.data[i].url }
            this.setState({ videosBrazos: [...this.state.videosBrazos,  video] })
          }
          if(res.data.data[i].Video_BelongsTo_Videocategories.category=="Ejercicios de piernas"){
            video = { name: res.data.data[i].name, url: res.data.data[i].url }
            this.setState({ videosPiernas: [...this.state.videosPiernas,  video] })
          }
          if(res.data.data[i].Video_BelongsTo_Videocategories.category=="Ejercicios de tronco"){
            video = { name: res.data.data[i].name, url: res.data.data[i].url }
            this.setState({ videosTronco: [...this.state.videosTronco,  video] })
          }
          if(res.data.data[i].Video_BelongsTo_Videocategories.category=="Ejercicios cardiovasculares"){
            video = { name: res.data.data[i].name, url: res.data.data[i].url }
            this.setState({ videosCardiovasculares: [...this.state.videosCardiovasculares,  video] })
          }
        }
        this.state.videosBrazos.splice(0, 1) 
        this.state.videosPiernas.splice(0, 1)
        this.state.videosTronco.splice(0, 1)
        this.state.videosCardiovasculares.splice(0, 1)
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  componentDidMount(){
    this.listarVideos();
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
              <title>Tutorial</title>
          </MetaTags>
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Tutorial" breadcrumbItem="Tutorial" />
            <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <FormGroup className="row mb-1">
                    <div className="accordion" id="accordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button fw-medium" type="button" onClick={this.hanldeCollapseBrazos} style={{ cursor: "pointer" }}>
                            Ejercicios de brazos
                          </button>
                        </h2>
                        <Collapse isOpen={this.state.collapseBrazos} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted">
                              <Row>
                              {this.state.collapseBrazos &&
                              this.state.videosBrazos.map((video, index) => {
                                return (
                                  <Col className="col-12" key={index} >
                                    <strong className="text-dark">{video.name}</strong> 
                                    <ReactPlayer 
                                      url={video.url}
                                      controls={true}
                                    />
                                  </Col>
                                )
                              })}
                              </Row>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button fw-medium" type="button" onClick={this.hanldeCollapsePiernas} style={{ cursor: "pointer" }}>
                            Ejercicios de piernas
                          </button>
                        </h2>
                        <Collapse isOpen={this.state.collapsePiernas} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted" >
                              <Row>
                              {this.state.collapsePiernas &&
                              this.state.videosPiernas.map((video, index) => {
                                return (
                                  <Col className="col-12" key={index}>
                                  <strong className="text-dark">{video.name}</strong> 
                                  <ReactPlayer 
                                    url={video.url}
                                    controls={true}
                                  />
                                  </Col>
                                )
                              })}
                              </Row>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button fw-medium" type="button" onClick={this.hanldeCollapseTronco} style={{ cursor: "pointer" }}>
                            Ejercicios de tronco
                          </button>
                        </h2>
                        <Collapse isOpen={this.state.collapseTronco} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted" >
                              <Row>
                              {this.state.collapseTronco &&
                              this.state.videosTronco.map((video, index) => {
                                return (
                                  <Col className="col-12" key={index}>
                                  <strong className="text-dark">{video.name}</strong> 
                                  <ReactPlayer 
                                    url={video.url}
                                    controls={true}
                                  />
                                  </Col>
                                )
                              })}
                              </Row>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button fw-medium" type="button" onClick={this.hanldeCollapseCardiovasculares} style={{ cursor: "pointer" }}>
                            Ejercicios cardiovasculares
                          </button>
                        </h2>
                        <Collapse isOpen={this.state.collapseCardiovasculares} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted" >
                              <Row>
                              {this.state.collapseCardiovasculares &&
                              this.state.videosCardiovasculares.map((video, index) => {
                                return (
                                  <Col className="col-12" key={index}>
                                  <strong className="text-dark">{video.name}</strong> 
                                  <ReactPlayer 
                                    url={video.url}
                                    controls={true}
                                  />
                                  </Col>
                                )
                              })}
                              </Row>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default TutorialUser
