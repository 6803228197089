import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  InputGroup,
  Button,
  Alert
} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class ReportesPaciente extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      usuarios: [],
      sizePerPage: 10,
      mensaje_error: null,
      showGraph: false,
      patient_id: null,
    }
  }

  listarPacientes(){
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`patient`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        this.setState({usuarios: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.User_Has_Patient[0].id;
    return (
      <Col>
        <Button id={"botonId_"+id} className="btn btn-success"
          onClick={() => {
            this.verEstadistica(id);
          }}
        >
          Ver estadística
        </Button>
      </Col>
    )
  };

  verEstadistica(patient_id){
    if(patient_id){
      this.setState({ patient_id })
      this.setState({ mensaje_error: null }) 
      const token = JSON.parse(localStorage.getItem("token"))
      axios.get(API_BASE_URL+`statistic/patient/${patient_id}/${this.state.ini_date}/${this.state.end_date}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          console.log("clases: ",res.data.data);
          this.setState({ clases: res.data.data.class_history });
          this.setState({ average_permanence: Math.round(res.data.data.average_permanence/60) });
          this.setState({ classes_attended: res.data.data.classes_attended });
          this.setState({ showGraph: true });
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
            this.setState({ mensaje_error: error.response.data.message }) 
          }
        });
    }
  }
  
  handleChangeIniDate = event => { 
    this.setState({ ini_date: event.ini_date })
    this.verEstadistica(this.state.patient_id)
  }
  handleChangeEndDate = event => { 
    this.setState({ end_date: event.end_date })
    this.verEstadistica(this.state.patient_id)
  }

  componentDidMount(){
    this.listarPacientes();
    this.setState({ ini_date: "2021-01-01" })
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    var hasta = ano+"-"+mes+"-"+dia;
    this.setState({ end_date: hasta })
  }

  formatAsistencia = (cell, row, rowIndex, formatExtraData) => {
    //0:no asistio, 1:asistio
    if(row.attendance==0){
      var asistencia = "No";
    }
    else{
      var asistencia = "Si";
    }
    return (
      <>{asistencia}</>
    );
  };

  formatEvaluacion1 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.evaluations[0]){
      evaluacion = "Respuesta: "+ row.evaluations[0].point_id
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };
  formatEvaluacion2 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.evaluations[1]){
      var cara = ''
      if(row.evaluations[1].point_id==1){
        cara = cara1
      }
      if(row.evaluations[1].point_id==2){
        cara = cara2
      }
      if(row.evaluations[1].point_id==3){
        cara = cara3
      }
      if(row.evaluations[1].point_id==4){
        cara = cara4
      }
      if(row.evaluations[1].point_id==5){
        cara = cara5
      }
      evaluacion = <img
          src={cara}
          alt=""
          height="19"
        />
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };

  formatFecha = (cell, row, rowIndex, formatExtraData) => {
    var fecha ='';
    if(row.date!=null){
      var fecha = row.date.substr(0,10); 
      //console.log("fecha: ",row.User_Has_Patient[0].dateofbirth);
    }
    return (
      <>{fecha}</>
    );
  };

  formatTiempo = (cell, row, rowIndex, formatExtraData) => {
    var tiempo=0
    if(row.time_in_class>0){
      tiempo = Math.round(row.time_in_class/60)
    }
    return (
      <>{tiempo+" minutos"}</>
    );
  };

  render() {
    const { ExportCSVButton } = CSVExport;

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'lastname',
      text: 'Apellido',
      sort: true
    }, {
      dataField: 'rut',
      text: 'RUT',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      sort: false
    }];

    const columnsDetalle = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'date',
      text: 'Fecha',
      formatter: this.formatFecha,
      sort: true
    }, {
      dataField: 'class_name',
      text: 'Nombre clase',
      sort: true
    }, {
      dataField: 'description',
      text: 'Detalle',
      sort: true
    }, {
      dataField: 'Asistencia',
      text: 'Asistencia',
      formatter: this.formatAsistencia,
      sort: true
    }, {
      dataField: 'time_in_class',
      text: 'Tiempo asistido',
      formatter: this.formatTiempo,
      sort: true
    }, {
      dataField: 'evaluations1',
      text: 'Pregunta 1',
      formatter: this.formatEvaluacion1,
      sort: true
    }, {
      dataField: 'evaluations2',
      text: 'Pregunta 2',
      formatter: this.formatEvaluacion2,
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Reportes / Paciente</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Reportes / Paciente" breadcrumbItem="Reportes / Paciente" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4>Lista pacientes</h4><hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.usuarios}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={this.state.usuarios}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    <br></br>
                    <h4>Filtro fechas</h4><hr></hr>
                    <FormGroup className="row mb-1">
                      <Label className="col-sm-2 col-form-label">
                        Fecha inicio 
                      </Label>
                      <Col className="col-sm-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Ingrese fecha"
                            onChange={(date, ini_date) => {this.handleChangeIniDate({ini_date})}}
                            options={{
                              dateFormat: "Y-m-d",
                              allowInput: true,
                              locale: {
                                ...Spanish
                              }
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-calendar-outline" />
                            </span>
                          </div>
                        </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row mb-1">
                      <Label className="col-sm-2 col-form-label">
                        Fecha término
                      </Label>
                      <Col className="col-sm-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Ingrese fecha"
                            onChange={(date, end_date) => {this.handleChangeEndDate({end_date})}}
                            options={{
                              dateFormat: "Y-m-d",
                              allowInput: true,
                              locale: {
                                ...Spanish
                              }
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-calendar-outline" />
                            </span>
                          </div>
                        </InputGroup>
                        </Col>
                    </FormGroup>
                    <Col className="col-12">
                        {this.state.mensaje_error ? 
                          <div className="mt-3 d-grid">
                            <Alert color="danger" >{this.state.mensaje_error}</Alert>
                          </div>
                          :
                          this.state.showGraph &&
                            <div>
                              <br></br>
                              <h4>Estadística</h4><hr></hr>
                              <Col className="col-12">
                                <FormGroup className="row mb-3">
                                  <Label className="col-sm-12 col-form-label">
                                    Promedio de permanencia en clases: {this.state.average_permanence} minutos
                                  </Label>
                                  <Label className="col-sm-12 col-form-label mb-1">
                                    Total clases asistidas: {this.state.classes_attended}
                                  </Label>
                                </FormGroup>
                                {/* <h4>Detalle clases</h4><hr></hr>
                                <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField='id'
                                  columns={columnsDetalle}
                                  data={this.state.clases}
                                >
                                {({ paginationProps, paginationTableProps }) => (
                                  <ToolkitProvider
                                    keyField='id'
                                    columns={columnsDetalle}
                                    data={this.state.clases}
                                    search
                                  >
                                    {toolkitProps => (
                                      <React.Fragment>
                                        <Row className="mb-2">
                                          <Col md="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                              <div className="position-relative">
                                                <SearchBar
                                                  {...toolkitProps.searchProps}
                                                  placeholder={"Buscar"}
                                                />
                                                <i className="bx bx-search-alt search-icon" />
                                              </div>
                                            </div>
                                          </Col>
                                          <Col className="col-md-2 offset-6">
                                            <div className="d-grid">
                                              <ExportCSVButton className="btn btn-outline-secondary" { ...toolkitProps.csvProps }>Exportar</ExportCSVButton>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xl="12">
                                            <div className="table-responsive">
                                              <BootstrapTable
                                                keyField={"id"}
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={
                                                  "table align-middle table-nowrap"
                                                }
                                                headerWrapperClasses={"thead-light"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />

                                            </div>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-30">
                                          <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                              <SizePerPageDropdownStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                              <PaginationListStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    )
                                    }
                                  </ToolkitProvider>
                                )
                                }</PaginationProvider> */}
                              </Col>
                            </div>
                        }
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ReportesPaciente