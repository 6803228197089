export function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        //APPID debe ir ID de la que se ocupa, ya sea testing o produccion
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '388252979143300',
                //appId desa: 388252979143300
                //appId test: 344287823720615
                cookie: true,
                xfbml: true,
                version: 'v10.0'
            });
            // auto authenticate with the api if already logged in with facebook
            /*window.FB.getLoginStatus(({ authResponse }) => {
                if (authResponse) {
                    accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
                } else {
                    resolve();
                }
            });*/
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
}