import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

import { Row, Col, Card, CardBody, Button, Label, Alert, Modal } from "reactstrap"
import Select from "react-select"
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class PacientesGrupos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarios:[],
      grupos:[],
      modal_standard: false,
      modal_cambiarNombre: false,
      listaCargada: false
    }
    this.tog_standard = this.tog_standard.bind(this)
    this.tog_cambiarNombre = this.tog_cambiarNombre.bind(this)
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
    this.setState({mensaje_error_modal: null})
    this.setState({mensaje_exito_modal: null})
  }

  removeBodyCss_cambiarNombre() {
    document.body.classList.add("no_padding")
    this.setState({mensaje_error_modal_cambiarNombre: null})
    this.setState({mensaje_exito_modal_cambiarNombre: null})
  }

  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  tog_cambiarNombre() {
    this.setState(prevState => ({
      modal_cambiarNombre: !prevState.modal_cambiarNombre,
    }))
    this.removeBodyCss_cambiarNombre()
  }

  listarPacientes(){
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`patient`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("LISTAR res.data: ",res.data.data);
        var largoLista = res.data.data.length;
        for (var i = 0; i < largoLista; i++) {
          var usuario = {};
          usuario["value"] = res.data.data[i].User_Has_Patient[0].id;
          if(res.data.data[i].userstatus_id==3){
            usuario["label"] = res.data.data[i].name+" "+res.data.data[i].lastname+" (Desactivado)";
          }
          else{
            usuario["label"] = res.data.data[i].name+" "+res.data.data[i].lastname;
          }
          this.state.usuarios.push(usuario);
        }
        this.setState({usuarios: this.state.usuarios});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  listarGrupos(){
    this.state.grupos=[]
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`group`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        var largoLista = res.data.data.length;
        for (var i = 0; i < largoLista; i++) {
          var grupo = {};
          grupo["value"] = res.data.data[i].id;
          grupo["label"] = res.data.data[i].group;
          grupo["groupstatus_id"] = res.data.data[i].groupstatus_id;
          if(res.data.data[i].groupstatus_id==1){
            this.state.grupos.push(grupo);
          }
        }
        this.setState({grupos: this.state.grupos});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  componentDidMount(){
    //this.listarPacientes();
    this.listarGrupos();
  }

  actualizarGrupo(selectedFilter) {
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({mensaje_exito_general: null})
    this.setState({mensaje_error_general: null})
    console.log("selectedFilter: ",selectedFilter)
    const data = {
      name: this.state.nombreSeleccionado,
      patient_array: [{ id: [selectedFilter]}]
    };
    console.log("DATA: ",data)
    axios.put(API_BASE_URL+`group/${this.state.grupoIdSeleccionado}`, {data}, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data);
        this.setState({mensaje_exito_general: "Grupo actualizado exitosamente"})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
          this.setState({mensaje_error_general: "Ups, error..."})
        }
      });
  }

  onFilterChange = (selectedFilter) => {
    this.setState({ selectedFilter });
    //console.log("selectedFilter: ",selectedFilter);
  };

  handleChangeGrupo = (event) => {
    this.setState({nombreSeleccionado: event.label})
    this.setState({grupo_id: event.value})
    if(this.state.listaCargada==false){
      this.listarPacientes();
      this.setState({listaCargada: true})
    }
    this.listarSeleccionados(event.value);
  };

  listarSeleccionados(id){
    var seleccionados=[]
    this.setState({grupoIdSeleccionado: id})
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`group/${id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("SELECCIONADOS res.data: ",res.data.data);
        var largoLista = res.data.data.Patient.length;
        for (var i = 0; i < largoLista; i++) {
          var user_id = res.data.data.Patient[i].id;
          seleccionados.push(user_id);
        }
        this.setState({selectedFilter: seleccionados});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  cambiarNombre(selectedFilter){
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({nombre: this.state.nombre})
    this.setState({mensaje_error_modal_cambiarNombre: null})
    this.setState({mensaje_exito_modal_cambiarNombre: null})
    this.setState({nombreSeleccionado: this.state.nombre})
    const data = {
      name: this.state.nombre,
      patient_array: [{ id: [selectedFilter] }]
    };

    axios.put(API_BASE_URL+`group/${this.state.grupoIdSeleccionado}`, {data}, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data);
        this.setState({mensaje_exito_modal_cambiarNombre: "Nombre de grupo cambiado exitosamente"})
        this.listarGrupos();
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
          this.setState({mensaje_error_modal_cambiarNombre: "Ups, error..."})
        }
      });
  }

  desactivarGrupoAlert(){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui' id="alert_fondo">
            <h1>Desactivar grupo</h1>
            <p>El grupo se desactivará para siempre y no se podrá recuperar <br></br> ¿está seguro que desea continuar?</p>
            <Button className="btn btn-warning"
              onClick={onClose}
            >
              NO
            </Button>&nbsp;
            <Button className="btn btn-danger"
              onClick={() => {this.desactivarGrupo(); onClose()}}
            >
              Sí, desactivar
            </Button>
          </div>
        );
      }
    });
  }

  desactivarGrupo(){
    const token = JSON.parse(localStorage.getItem("token"))
    var id = this.state.grupo_id;

    if(id!=null){
      axios.put(API_BASE_URL+`group/${id}/groupstatus/2`, null, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          this.setState({nombreSeleccionado: null }) 
          this.listarGrupos();

        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });
    }
  }

  crearGrupo(){
    this.setState({nombre: this.state.nombre})
    this.setState({mensaje_error_modal: null})
    this.setState({mensaje_exito_modal: null})

    const data = {
      name: this.state.nombre
    };

    const token = JSON.parse(localStorage.getItem("token"))

    axios.post(API_BASE_URL+`group`, 
      {data}, 
      {headers: {
        Authorization: `Bearer ${token}`
      }})
      .then(res => {
        this.setState({mensaje_exito_modal: "Nombre de grupo creado exitosamente"})
        this.listarGrupos();
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
          this.setState({mensaje_error_modal: "Ups, error..."})
        }
      });
  }

  handleChangeNombre = event => {
    this.setState({ nombre: event.target.value });
  }

  render() {
    const { selectedFilter } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
              <title>Pacientes / Grupos</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Pacientes / Grupos" breadcrumbItem="Pacientes / Grupos" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                  <div>
                      <Modal
                        isOpen={this.state.modal_cambiarNombre}
                        toggle={this.tog_cambiarNombre}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                            Cambiar nombre de grupo
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_cambiarNombre: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Label
                            htmlFor="clasenombre"
                            className="col-12 col-form-label"
                          >
                            Nuevo nombre del grupo
                          </Label>
                          <input
                            defaultValue={this.state.nombreSeleccionado}
                            className="form-control"
                            type="text"
                            onChange={this.handleChangeNombre}
                            placeholder="Ingrese nombre del nuevo grupo"
                          />
                          <br></br>
                          <Button className="btn btn-warning"
                            onClick={() => this.cambiarNombre(selectedFilter)}
                          >
                            Cambiar nombre de grupo
                          </Button>
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              {this.state.mensaje_error_modal_cambiarNombre && <Alert color="danger" >{this.state.mensaje_error_modal_cambiarNombre}</Alert>}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              {this.state.mensaje_exito_modal_cambiarNombre && <Alert color="success" >{this.state.mensaje_exito_modal_cambiarNombre}</Alert>}
                            </div>
                          </Col>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={this.tog_cambiarNombre}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Cerrar
                          </button>
                        </div>
                      </Modal>
                    </div>
                    <div>
                      <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                            Crear nombre de grupo
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_standard: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Label
                            htmlFor="clasenombre"
                            className="col-12 col-form-label"
                          >
                            Nombre del nuevo grupo
                          </Label>
                          <input
                            className="form-control"
                            type="text"
                            onChange={this.handleChangeNombre}
                            placeholder="Ingrese nombre del nuevo grupo"
                          />
                          <br></br>
                          <Button className="btn btn-primary"
                            onClick={() => {this.crearGrupo();}}
                          >
                            Crear nombre de grupo
                          </Button>
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              {this.state.mensaje_error_modal && <Alert color="danger" >{this.state.mensaje_error_modal}</Alert>}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              {this.state.mensaje_exito_modal && <Alert color="success" >{this.state.mensaje_exito_modal}</Alert>}
                            </div>
                          </Col>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={this.tog_standard}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Cerrar
                          </button>
                        </div>
                      </Modal>
                    </div>
                    <Row>
                      <Col className="col-12">
                        <h4>Grupos existentes</h4><hr></hr>
                        <Label
                          htmlFor="clasenombre"
                          className="col-12 col-form-label"
                        >
                          Nombre del grupo
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-6">
                        <Select
                            value={{ label: this.state.nombreSeleccionado}}
                            onChange={this.handleChangeGrupo}
                            options={this.state.grupos}
                            placeholder="Seleccione..."
                        />
                      </Col>
                      <Col className="col-2">
                        <button 
                          className="btn btn-primary col-12"
                          onClick={this.tog_standard}
                        >
                          Crear nombre
                        </button>
                      </Col>
                      <Col className="col-2">
                        <button
                          className="btn btn-warning col-12"
                          onClick={this.tog_cambiarNombre}
                        >
                          Cambiar nombre
                        </button>
                      </Col>
                      <Col className="col-2">
                        <button
                          className="btn btn-danger col-12"
                          onClick={() => this.desactivarGrupoAlert()}
                        >
                          Desactivar grupo
                        </button>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col className="col-12">
                        <h4>Gestión integrantes grupo</h4>
                      </Col>
                      <Col className="col-6">
                        <Label
                          htmlFor="clasenombre"
                          className="col-12 col-form-label"
                        >
                          Pacientes disponibles
                        </Label>
                      </Col>
                      <Col className="col-6">
                        <Label
                          htmlFor="clasenombre"
                          className="col-12 col-form-label"
                        >
                          Pacientes en el grupo
                        </Label>
                      </Col>
                    </Row>
                    <DualListBox
                      canFilter
                      filterPlaceholder="Buscar..."
                      options={this.state.usuarios}
                      selected={selectedFilter}
                      onChange={this.onFilterChange}
                      
                    />
                    <Row>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.actualizarGrupo(selectedFilter)}
                          >
                            Actualizar integrantes del grupo
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Col className="col-12">
                      <div className="mt-3 d-grid">
                        {this.state.mensaje_error_general && <Alert color="danger" >{this.state.mensaje_error_general}</Alert>}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mt-3 d-grid">
                        {this.state.mensaje_exito_general && <Alert color="success" >{this.state.mensaje_exito_general}</Alert>}
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PacientesGrupos