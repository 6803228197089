import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"
import { CSVLink } from "react-csv";

import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  InputGroup,
  Button,
  Alert
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class ReportesExportar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mensaje_error: null,
      listaPhysicalevaluations: [],
      listaLabtest: [],
      listaNutritionalevaluations: [],
    }
  }

  exportarDatos(fecha){
    if(fecha==0){
      var ini_date = "2021-01-01" 
      var fecha = new Date();
      var ano = fecha.getFullYear();
      var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
      var dia = ("0" + fecha.getDate()).slice(-2);
      var hasta = ano+"-"+mes+"-"+dia;
      var end_date = hasta 
    }
    else{
      var ini_date = this.state.ini_date
      var end_date = this.state.end_date 
    }

    this.setState({ mensaje_error: null }) 
    var listaLabtest = []
    var listaNutritionalevaluations = []
    var listaPhysicalevaluations = []
    const token = JSON.parse(localStorage.getItem("token"))
    console.log("ini_date: ",ini_date," / end_date: ",end_date)
    axios.get(API_BASE_URL+`statistic/exportdata/${ini_date}/${end_date}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("datos: ",res.data.data)
        for (var i = 0; i < res.data.data.length; i++) {
          var Nutritionalevaluations = {}
          var Physicalevaluations = {}
          var Labtest = {}
          Labtest["rut"] = res.data.data[i].rut
          Labtest["name"] = res.data.data[i].name
          Nutritionalevaluations["rut"] = res.data.data[i].rut
          Nutritionalevaluations["name"] = res.data.data[i].name
          Physicalevaluations["rut"] = res.data.data[i].rut
          Physicalevaluations["name"] = res.data.data[i].name
          for (var e = 0; e < res.data.data[i].Labtest.length; e++) {
            Labtest["id"+e] = res.data.data[i].Labtest[e].id
            Labtest["evaluation_date"+e] = res.data.data[i].Labtest[e].evaluation_date
            Labtest["label"+e] = res.data.data[i].Labtest[e].label
            Labtest["c_reactive_protein"+e] = res.data.data[i].Labtest[e].c_reactive_protein
            Labtest["cholesterol"+e] = res.data.data[i].Labtest[e].cholesterol
            Labtest["glycemia"+e] = res.data.data[i].Labtest[e].glycemia
            Labtest["hdl"+e] = res.data.data[i].Labtest[e].hdl
            Labtest["ldl"+e] = res.data.data[i].Labtest[e].ldl
            Labtest["triglycerides"+e] = res.data.data[i].Labtest[e].triglycerides
            Labtest["insulin"+e] = res.data.data[i].Labtest[e].insulin
          }
          for (var e = 0; e < res.data.data[i].Nutritionalevaluations.length; e++) {
            Nutritionalevaluations["id"+e] = res.data.data[i].Nutritionalevaluations[e].id
            Nutritionalevaluations["evaluation_date"+e] = res.data.data[i].Nutritionalevaluations[e].evaluation_date
            Nutritionalevaluations["label"+e] = res.data.data[i].Nutritionalevaluations[e].label
            Nutritionalevaluations["bmi"+e] = res.data.data[i].Nutritionalevaluations[e].bmi
            Nutritionalevaluations["body_fat_mass"+e] = res.data.data[i].Nutritionalevaluations[e].body_fat_mass
            Nutritionalevaluations["body_fat_percent"+e] = res.data.data[i].Nutritionalevaluations[e].body_fat_percent
            Nutritionalevaluations["height"+e] = res.data.data[i].Nutritionalevaluations[e].height
            Nutritionalevaluations["hips_circunference"+e] = res.data.data[i].Nutritionalevaluations[e].hips_circunference
            Nutritionalevaluations["lean_body_mass"+e] = res.data.data[i].Nutritionalevaluations[e].lean_body_mass
            Nutritionalevaluations["lean_body_percent"+e] = res.data.data[i].Nutritionalevaluations[e].lean_body_percent
            Nutritionalevaluations["neck_circunference"+e] = res.data.data[i].Nutritionalevaluations[e].neck_circunference
            Nutritionalevaluations["waist_circunference"+e] = res.data.data[i].Nutritionalevaluations[e].waist_circunference
            Nutritionalevaluations["weight"+e] = res.data.data[i].Nutritionalevaluations[e].weight
          }
          for (var e = 0; e < res.data.data[i].Physicalevaluations.length; e++) {
            Physicalevaluations["id"+e] = res.data.data[i].Physicalevaluations[e].id
            Physicalevaluations["evaluation_date"+e] = res.data.data[i].Physicalevaluations[e].evaluation_date
            Physicalevaluations["label"+e] = res.data.data[i].Physicalevaluations[e].label
            Physicalevaluations["abdominal_evaluation"+e] = res.data.data[i].Physicalevaluations[e].abdominal_evaluation
            Physicalevaluations["balance"+e] = res.data.data[i].Physicalevaluations[e].balance
            Physicalevaluations["pressure_force_right"+e] = res.data.data[i].Physicalevaluations[e].pressure_force_right
            Physicalevaluations["pressure_force_left"+e] = res.data.data[i].Physicalevaluations[e].pressure_force_left
            Physicalevaluations["sit_stand"+e] = res.data.data[i].Physicalevaluations[e].sit_stand
            Physicalevaluations["hips_circunferesix_walk_testnce"+e] = res.data.data[i].Physicalevaluations[e].hips_circunferesix_walk_testnce
            Physicalevaluations["upperlimb_id"+e] = res.data.data[i].Physicalevaluations[e].upperlimb_id
            Physicalevaluations["vo2_max"+e] = res.data.data[i].Physicalevaluations[e].vo2_max
          }
          listaLabtest.push(Labtest);
          listaNutritionalevaluations.push(Nutritionalevaluations);
          listaPhysicalevaluations.push(Physicalevaluations);
        }
        /*console.log(listaLabtest)
        console.log(listaNutritionalevaluations)
        console.log(listaPhysicalevaluations)*/
        this.setState({listaLabtest})
        this.setState({listaNutritionalevaluations})
        this.setState({listaPhysicalevaluations})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
          this.setState({ mensaje_error: error.response.data.message }) 
        }
      });
  }
  
  handleChangeIniDate = event => { 
    this.setState({ ini_date: event.ini_date })
    this.exportarDatos(1)
  }
  handleChangeEndDate = event => { 
    this.setState({ end_date: event.end_date })
    this.exportarDatos(1)
  }

  componentDidMount(){
    this.exportarDatos(0)
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Reportes / Exportar</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Reportes / Exportar" breadcrumbItem="Reportes / Exportar" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4>Filtro fechas</h4><hr></hr>
                    <FormGroup className="row mb-1">
                      <Label className="col-sm-2 col-form-label">
                        Fecha inicio 
                      </Label>
                      <Col className="col-sm-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Ingrese fecha"
                            onChange={(date, ini_date) => {this.handleChangeIniDate({ini_date})}}
                            options={{
                              dateFormat: "Y-m-d",
                              allowInput: true,
                              locale: {
                                ...Spanish
                              }
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-calendar-outline" />
                            </span>
                          </div>
                        </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row mb-1">
                      <Label className="col-sm-2 col-form-label">
                        Fecha término
                      </Label>
                      <Col className="col-sm-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Ingrese fecha"
                            onChange={(date, end_date) => {this.handleChangeEndDate({end_date})}}
                            options={{
                              dateFormat: "Y-m-d",
                              allowInput: true,
                              locale: {
                                ...Spanish
                              }
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-calendar-outline" />
                            </span>
                          </div>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup className="row mb-1">
                      <Label className="col-sm-2 col-form-label">
                        Exportar datos
                      </Label>
                      <Col className="col-sm-4">
                        {/* <button
                          type="button"
                          onClick={() => {this.exportarDatos();}}
                          className="btn btn-success"
                        >
                          Descargar .CSV
                        </button> */}
                        <CSVLink filename={"Labtest.csv"} className="btn btn-outline-success" data={this.state.listaLabtest}>Muestra laboratorio</CSVLink>
                        <CSVLink filename={"Nutritionalevaluations.csv"} className="btn btn-outline-success" data={this.state.listaNutritionalevaluations}>Evaluación Nutricional</CSVLink>
                        <CSVLink filename={"Physicalevaluations.csv"} className="btn btn-outline-success" data={this.state.listaPhysicalevaluations}>Evaluación física</CSVLink>
                      </Col>
                    </FormGroup>
                    {this.state.mensaje_error &&
                      <Col className="col-12">
                        <div className="mt-1 d-grid">
                          <Alert color="danger" >{this.state.mensaje_error}</Alert>
                        </div>
                      </Col>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ReportesExportar