import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import { API_BASE_URL } from "helpers/url_helper"

import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Alert,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class OperadoresEditar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      grupos:[]
    }
  }

  componentDidMount(){
    this.listarComunas()
    document.getElementById("link_operadores").style.color = "white";
    document.getElementById("icono_operadores").style.color = "white";
    //console.log("operador_id: ", this.props.match.params.id);
    const operador_id = this.props.match.params.id
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})
    this.setState({operador_id: operador_id})

    axios.get(API_BASE_URL+`teacher/${operador_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data.data);
        if(res.data.data.Teacher_BelongsTo_User.name!=null){
          this.setState({nombre: res.data.data.Teacher_BelongsTo_User.name});
        }
        if(res.data.data.Teacher_BelongsTo_User.lastname!=null){
          this.setState({apellido: res.data.data.Teacher_BelongsTo_User.lastname});
        }
        if(res.data.data.Teacher_BelongsTo_User.rut!=null){
          this.setState({rut: res.data.data.Teacher_BelongsTo_User.rut});
        }
        if(res.data.data.Teacher_BelongsTo_User.email!=null){
          this.setState({email: res.data.data.Teacher_BelongsTo_User.email});
        }
        if(res.data.data.profesion!=null){
          this.setState({profesion: res.data.data.profesion});
        }
        if(res.data.data.cesfam_id!=null){
          this.seleccionarCesfam(res.data.data.cesfam_id)
        }
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  seleccionarCesfam(id){
    this.setState({cesfam_id: id});

    this.state.cesfams=[]
    const token = JSON.parse(localStorage.getItem("token"))
    
    axios.get(API_BASE_URL+`cesfam`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("CESFAM res.data: ",res.data.data);
        var largoLista = res.data.data.length;
        for (var i = 0; i < largoLista; i++) {
          var cesfam = {};
          cesfam["value"] = res.data.data[i].id;
          cesfam["label"] = res.data.data[i].name;
          this.state.cesfams.push(cesfam);
          if(res.data.data[i].id==id){
            this.setState({cesfam_name: res.data.data[i].name});
          }
        }
        this.setState({cesfams: this.state.cesfams});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  editarOperador(){
    this.setState({mensaje_error_name: null})
    this.setState({mensaje_error_apellido: null})
    this.setState({mensaje_error_rut: null})
    this.setState({mensaje_error_email: null})
    this.setState({mensaje_error_profesion: null})
    this.setState({mensaje_error_cesfam: null})
    this.setState({mensaje_error: null})
    this.setState({mensaje_exito: null})

    const data = {
      name: this.state.nombre,
      lastname: this.state.apellido,
      rut: this.state.rut,
      email: this.state.email,
      cesfam_id: this.state.cesfam_id,
      profesion: this.state.profesion
    };
 
    axios.put(API_BASE_URL+`teacher/${this.state.operador_id}`, {data}, {headers: {Authorization: `Bearer ${this.state.token}`}})
      .then(res => {
        console.log("res.data: ",res.data);
        this.setState({mensaje_exito: "Operador editado exitosamente"})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
          for (var i = 0; i < error.response.data.errors.length; i++) {
            if (error.response.data.errors[i]["data.name"]) {
              this.setState({mensaje_error_name: error.response.data.errors[i]["data.name"]})
            }
            if (error.response.data.errors[i]["data.lastname"]) {
              this.setState({mensaje_error_apellido: error.response.data.errors[i]["data.lastname"]})
            }
            if (error.response.data.errors[i]["data.rut"]) {
              this.setState({mensaje_error_rut: error.response.data.errors[i]["data.rut"]})
            }
            if (error.response.data.errors[i]["data.email"]) {
              this.setState({mensaje_error_email: error.response.data.errors[i]["data.email"]})
            }
            if (error.response.data.errors[i]["data.profesion"]) {
              this.setState({mensaje_error_profesion: error.response.data.errors[i]["data.profesion"]})
            }
            if (error.response.data.errors[i]["data.cesfam_id"]) {
              this.setState({mensaje_error_cesfam: error.response.data.errors[i]["data.cesfam_id"]})
            }
          }
          this.setState({mensaje_error: "Ups, error..."})
        }
      });
  }

  listarComunas(){
    this.state.comunas=[]
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`region`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data.data);
        var largoLista = res.data.data.communes.length;
        for (var i = 0; i < largoLista; i++) {
          var comuna = {};
          comuna["value"] = res.data.data.communes[i].id;
          comuna["label"] = res.data.data.communes[i].name;
          this.state.comunas.push(comuna);
        }
        this.setState({comunas: this.state.comunas});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data);
        }
      });
  }

  handleChangeNombre = event => {
    this.setState({ nombre: event.target.value });
  }
  handleChangeApellido = event => {
    this.setState({ apellido: event.target.value });
  }
  handleChangeRut = event => {
    this.setState({ rut: event.target.value });
  }
  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  }
  handleChangeProfesion = event => {
    this.setState({ profesion: event.target.value });
  }
  handleChangeCesfam = (event) => {
    //alert("Grupo id: "+event.value+"/ label: "+event.label);
    this.setState({cesfam_id: event.value})
    this.setState({cesfam_name: event.label})

  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Operadores / Editar</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Operadores / Editar" breadcrumbItem="Operadores / Editar" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          Nombre
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="nombre"
                            value={this.state.nombre}
                            placeholder="Ingrese nombre"
                            type="text"
                            errorMessage="Ingrese nombre"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeNombre}
                          />
                          {this.state.mensaje_error_name && <Alert color="danger">{this.state.mensaje_error_name}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          Apellido
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="apellido"
                            value={this.state.apellido}
                            placeholder="Ingrese apellido"
                            type="text"
                            errorMessage="Ingrese apellido"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeApellido}
                          />
                          {this.state.mensaje_error_apellido && <Alert color="danger">{this.state.mensaje_error_apellido}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          RUT
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="rut"
                            value={this.state.rut}
                            placeholder="Ingrese RUT"
                            type="text"
                            errorMessage="Ingrese RUT"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeRut}
                          />
                          {this.state.mensaje_error_rut && <Alert color="danger">{this.state.mensaje_error_rut}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          Email
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="email"
                            value={this.state.email}
                            placeholder="Ingrese email"
                            type="text"
                            errorMessage="Ingrese email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeEmail}
                          />
                          {this.state.mensaje_error_email && <Alert color="danger">{this.state.mensaje_error_email}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          Profesión
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="profesion"
                            value={this.state.profesion}
                            placeholder="Ingrese profesión"
                            type="text"
                            errorMessage="Ingrese profesión"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={this.handleChangeProfesion}
                          />
                          {this.state.mensaje_error_email && <Alert color="danger">{this.state.mensaje_error_email}</Alert>}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          Cesfams
                        </Label>
                        <Col className="col-sm-10">
                          <Select
                              value={{ label: this.state.cesfam_name}}
                              //defaultValue={{ label: this.state.comuna_name }}
                              onChange={this.handleChangeCesfam}
                              options={this.state.cesfams}
                              placeholder="Seleccione..."
                          />
                          {this.state.mensaje_error_cesfam && <Alert color="danger">{this.state.mensaje_error_cesfam}</Alert>}
                        </Col>
                      </FormGroup>
                      <Col className="col-12">
                          <div className="mt-3 d-grid">
                              <button 
                                  className="btn btn-primary btn-block"
                                  onClick={() => this.editarOperador()}
                              >
                              Editar
                              </button> 
                          </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_error && <Alert color="danger" >{this.state.mensaje_error}</Alert>}
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.mensaje_exito && <Alert color="success" >{this.state.mensaje_exito}</Alert>}
                        </div>
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default OperadoresEditar