import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class SolicitudesLista extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      usuarios: [],
      sizePerPage: 10
    }
  }

  listarPacientes(){
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})

    axios.get(API_BASE_URL+`applications`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
      //console.log("res.data: ",res.data.data);
        this.setState({usuarios: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  cambiarEstadoPaciente(id) {
    //1:en espera, 2:activado, 3:desactivado
    var textBoton = document.getElementById("botonId_"+id).innerHTML;
    if(textBoton=="Aceptar"){
      axios.put(API_BASE_URL+`applications/${id}/patient/2`, null, {headers: {Authorization: `Bearer ${this.state.token}`}})
      .then(res => {
        document.getElementById("botonId_"+id).className = "btn btn-outline-success";
        document.getElementById("botonId_"+id).innerHTML = "Aceptado";
        var row_index = this.state.usuarios.findIndex( patiente_id => patiente_id.id === id);
        this.state.usuarios[row_index].Patient_BelongsTo_User.userstatus_id=2;
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
    }
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.id;
    if(row.Patient_BelongsTo_User.userstatus_id==1){
      return (
        <Col>
          <Button id={"botonId_"+id} className="btn btn-success"
            onClick={() => {
              this.cambiarEstadoPaciente(id);
            }}
          >
            Aceptar
          </Button>
        </Col>
      )
    }
    else{
      return (
        <Col>
          <Button color="success" outline>
            Aceptado
          </Button>
        </Col>
      )
    }
  };

  componentDidMount(){
    this.listarPacientes();
  }

  render() {
    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'Patient_BelongsTo_User.name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'Patient_BelongsTo_User.lastname',
      text: 'Apellido',
      sort: true
    }, {
      dataField: 'Patient_BelongsTo_User.rut',
      text: 'RUT',
      sort: true
    }, {
      dataField: 'Patient_BelongsTo_User.email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      sort: false
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      //totalSize: usuarios.length, // replace later with size(customers),
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Solicitudes / Lista</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Solicitudes / Lista" breadcrumbItem="Solicitudes / Lista" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.usuarios}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.usuarios}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder={"Buscar"}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      //rowEvents={this.eventosRowOnClick} 
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SolicitudesLista