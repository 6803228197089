import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import DualListBox from 'react-dual-listbox';
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { API_BASE_URL } from "helpers/url_helper"
import ReactApexChart from "react-apexcharts"

import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Alert,
  Input,
  InputGroup
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class EncuestasEditar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fechaIni: null,
      fechaFin: null,
      questions: [{
        question:null,
        alternative:[""],
        series: []
      }],
      cesfams: [],
      showGrpah: false,
    }
  }

  componentDidMount(){
    var cesfams=[]
    document.getElementById("link_encuestas").style.color = "white";
    document.getElementById("icono_encuestas").style.color = "white";
    console.log("encuesta_id: ", this.props.match.params.id);
    const encuesta_id = this.props.match.params.id
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})
    this.setState({encuesta_id: encuesta_id})

    axios.get(API_BASE_URL+`poll/${encuesta_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data.data);
        if(res.data.data.name!=null){
          this.setState({nombre: res.data.data.name});
        }
        if(res.data.data.initial_date!=null){
          var fechaIni = res.data.data.initial_date.substr(0,10)
          this.setState({fechaIni: fechaIni});
        }
        if(res.data.data.end_date!=null){
          var fechaFin = res.data.data.end_date.substr(0,10)
          this.setState({fechaFin: fechaFin});
        }
        if(res.data.data.Poll_Cesfam!=null){
          for (var i = 0; i < res.data.data.Poll_Cesfam.length; i++) {
            var cesfam = res.data.data.Poll_Cesfam[i].name;
            cesfams.push(cesfam);
          }
          this.setState({cesfams: cesfams});
        }
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });

      axios.get(API_BASE_URL+`poll/${encuesta_id}/result`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        var noRespondieron = res.data.data.surveys_sent-res.data.data.surveys_answered;
        var siRespondieron = res.data.data.surveys_answered;
        this.setState({ series: [noRespondieron, siRespondieron] })
        this.setState({ options: {
            labels: ["No", "Sí"],
            colors: ["#f46a6a","#34c38f"],
          },
        })
        this.setState({ showGrpah: true })
        var preguntas = []
        for (var i = 0; i < res.data.data.results.length; i++) {
          var alternative = []
          var series = []
          var labels = []
          var question = res.data.data.results[i].text
          for (var e = 0; e < res.data.data.results[i].Question_Has_Alternative.length; e++) {
            var serie = res.data.data.results[i].Question_Has_Alternative[e].count
            var textoAlternativa = res.data.data.results[i].Question_Has_Alternative[e].text
            alternative.push(textoAlternativa)
            series.push(serie)
            var label = "Alternativa "+(i+1)+"."+(e+1)
            labels.push(label)
            var showAltGrpah = true 
          }
          preguntas.push({question, alternative, series, labels, showAltGrpah});
        }
        this.setState({questions: preguntas});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Encuesta / Detalle</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Encuesta / Detalle" breadcrumbItem="Encuesta / Detalle" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Col className="col-12">
                          <h4>Nombre</h4>
                        </Col>
                        <Col className="col-sm-12">
                          <Label className="col-sm-2 col-form-label">
                            {this.state.nombre}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Col className="col-12">
                          <h4>Rango de fechas</h4>
                        </Col>
                        <Col className="col-12 mb-1">
                          <Label className="col-12">
                            Fecha inicio: {this.state.fechaIni}
                          </Label>
                        </Col>
                        <Col className="col-12 mb-1">
                          <Label className="col-12">
                            Fecha término: {this.state.fechaFin}
                          </Label>
                        </Col>
                      </FormGroup>
                      <Row>
                        <Col className="col-12">
                          <h4>Cesfams</h4>
                        </Col>
                        <Col className="col-12">
                        {this.state.cesfams.map((cesfam, indexCesfam) => {
                          return (
                            <Label key={indexCesfam} className="col-12">
                              {cesfam}
                            </Label>
                          )
                        })}
                        </Col>
                      </Row>
                      {this.state.showGrpah &&
                        <div>
                          <hr></hr>
                          <Col className="col-12">
                            <h4>Cuantos han respondido?</h4>
                          </Col>
                          <ReactApexChart
                            options={this.state.options}
                            series={this.state.series}
                            type="pie"
                            height="250"
                            className="apex-charts"
                          />
                        </div>
                      }
                      <hr></hr>
                      <Col className="col-12">
                        <h4>Preguntas - Alternativas</h4>
                      </Col>
                      {
                        this.state.questions.map((pregunta, indexPregunta) => {
                          return (
                            <FormGroup key={indexPregunta} className="row mb-1">
                              <div className="row mb-1">
                              <Label className="col-12 col-form-label">
                                Pregunta N° {indexPregunta+1} : {pregunta.question}
                              </Label>
                              </div>
                            {
                              pregunta.alternative.map((alternativa, indexAlternativa) => {
                                return (
                                  <FormGroup key={indexAlternativa} className="row mb-1">
                                    <Label className="col-sm-11 offset-1 col-form-label">
                                      Alternativa N° {indexPregunta+1}.{indexAlternativa+1}: {alternativa} 
                                    </Label>
                                  </FormGroup>
                                )
                              })
                            }
                            {pregunta.showAltGrpah &&
                              <ReactApexChart
                                options={{labels: pregunta.labels}}
                                series={pregunta.series}
                                type="pie"
                                height="250"
                                className="apex-charts"
                              />
                            }
                            <hr></hr>
                            </FormGroup>
                          )
                        })
                      }
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default EncuestasEditar