import React, { Component } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from "reactstrap"


   

class TerminosCondiciones extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <MetaTags>
            <title>POLÍTICA DE PRIVACIDAD</title>
          </MetaTags>
          <Container>
            <Row>
              <Col lg="12">
                <div className=" mb-5">
                  <h1 className="text-center">POLÍTICA DE PRIVACIDAD</h1>
                  <h2 className="text-center">
                    POLÍTICA DE PRIVACIDAD DE APPTIVATE
                  </h2>
                  <p className="text-justify">
                    Para la operación de la aplicación App-tivate, desarrollada
                    por el Centro de Vida Saludable (CVS) de Universidad de
                    Concepción, Chile que estén alojadas enApp Store, Google
                    Play Store, Apple Store o cualquier otra tienda de
                    aplicaciones en la que puedan estar publicadas. Mediante el
                    presente informamos sobre nuestras políticas en relación a
                    la recopilación, uso, almacenamiento y protección de los
                    datos personales que recibimos de los y las usuarias del
                    sitio. En conformidad con lo dispuesto en el artículo 19 Nº
                    4 de la Constitución Política de la República, la Ley Nº
                    19.628, dispone de la siguiente política de privacidad, a
                    fin de resguardar la seguridad, confidencialidad y
                    privacidad de sus usuarios. <br></br>
                    Estas políticas tienen por finalidad asegurar la correcta
                    utilización de la información recopilada a través de las
                    visitas de las plataformas digitales y de sus contenidos.
                  </p>
                  <br />
                  <p>
                    <b>Interpretación y definiciones</b>
                  </p>
                  <br />
                  <p>
                    <b>Interpretación:</b>
                    <br />
                    Las palabras cuya letra inicial está en mayúscula tienen
                    significados definidos bajo las siguientes condiciones.Las
                    siguientes definiciones tendrán el mismo significado
                    independientemente de que aparezcan en singular o en plural.
                  </p>
                  <br />
                  <p>
                    <b>Definiciones</b>
                    <br />A los efectos de esta Política de privacidad:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>Cuenta, </b>
                        significa una cuenta única creada para que usted acceda
                        a nuestro Servicio o partes de nuestro Servicio.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Aplicación o app, </b>
                        significa el programa de software proporcionado por la
                        Compañía descargado por Usted en cualquier dispositivo
                        electrónico.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          Compañía(denominada “la Compañía”, “Nosotros”, “Nos” o
                          “Nuestro” en este Acuerdo),{" "}
                        </b>
                        se refiere a Centro de Vida Saludable de Universidad de
                        Concepción, Concepción, Región del Bio bio.  País,se
                        refiere a Chile.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Dispositivo, </b>
                        significa cualquier dispositivo que pueda acceder al
                        Servicio, como una computadora, unteléfono celularo una
                        tableta digital.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Los datos personales, </b>
                        son cualquier información que se relacione con un
                        individuo identificado o identificable.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>El servicio, </b>
                        se refiere a la Aplicación.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Proveedor de servicios, </b>
                        significa cualquier persona física o jurídica que
                        procesa los datos en nombre de la Compañía.Se refiere a
                        empresas de terceros o personas empleadas por la
                        Compañía para facilitar el Servicio, para proporcionar
                        el Servicio en nombre de la Compañía, para realizar
                        servicios relacionados con el Servicio o para ayudar a
                        la Compañía aanalizarcómo se utiliza el Servicio.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>El Servicio deredes sociales deterceros, </b>
                        refiere a cualquier sitio web o cualquier sitio web de
                        red social a través del cual un Usuario puede iniciar
                        sesión o crear una cuenta para utilizar el Servicio.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Los Datos de uso, </b>
                        se refieren a los datos recopilados automáticamente, ya
                        sea generadospor el uso delServicio o por la propia
                        infraestructura del Servicio (por ejemplo, la duración
                        de una visita a la página).
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Usted, </b> significala persona que accede o usa el
                        Servicio, o la empresa u otra entidad legal en nombre de
                        la cual dicha persona accede o usa el Servicio, según
                        corresponda.
                      </p>
                    </li>
                  </ul>
                  <ol>
                    <li>
                      Al acceder y usar la plataforma App-tivate, solicitaremos
                      que los usuarios entreguen cierta información, la cual se
                      indica en los Términos y Condiciones. <br /> De este modo,
                      App-tivate, recopila datos de los suscriptores y/o
                      usuarios que hagan uso de las plataformas digitales, a
                      través de dos mecanismos: <br />
                      <ol type="a">
                        <li>
                          <b>Mecanismos Automáticos: </b>
                          Son aquellos procesos informáticos realizados para
                          generar registros de las actividades de los usuarios
                          de las plataformas digitales y cuyo objeto es
                          establecer patrones de actividad, navegación y
                          audiencia, que no implican la identificación personal
                          de aquellos suscriptores y usuarios que accedan las
                          referidas plataformas. App-tivate, se reserva el
                          derecho de recopilar y usar dicha información general,
                          a fin de establecer criterios que mejoren los
                          contenidos de sus plataformas, en todo caso siempre
                          disociados de la persona que dejó los datos en su
                          navegación.
                        </li>
                        <li>
                          <b>Mecanismos Manuales: </b>
                          Son requerimientos formales y expresos de información
                          a los suscriptores y usuarios que implican la
                          recolección de datos personales tales como nombre,
                          apellidos, correo electrónico, comuna, ciudad, región,
                          etc. App-tivate, se reserva el derecho de usar y
                          recopilar estos datos con el objeto de mejorar la
                          calidad de información del portal y segmentar de mejor
                          manera los temas de interés de sus usuarios en
                          general.
                        </li>
                        <li>
                          <b>Cookies y tracking: </b>
                          Al igual que muchos sitios web, utilizamos cookies y
                          tecnologías similares para recopilar datos adicionales
                          de uso del sitio web y administrar nuestros servicios.
                          Algunos de nuestros servicios pueden no funcionar
                          correctamente si deshabilita las cookies. Cuando su
                          navegador o dispositivo lo permiten utilizamos tanto
                          cookies de sesión como cookies permanentes para
                          conocer mejor cómo interactúa usted con la plataforma,
                          obtener patrones de uso agregado y personalizar y
                          administrar de otras formas nuestros servicios, como
                          proporcionando seguridad a la cuenta, personalizando
                          el contenido que le mostramos, incluyendo los
                          anuncios, y recordando sus preferencias de idioma.
                          Nuestra plataforma no es compatible con la opción No
                          realizar seguimiento (DNT) del navegador. Nuestras
                          plataformas no hacen un seguimiento a los usuarios,
                          como tampoco a través de sitios de terceros, por lo
                          que no responde a las opciones de No realizar
                          seguimiento (DNT) de los navegadores. Sin embargo,
                          algunos sitios de terceros sí hacen seguimiento de las
                          actividades de navegación de los usuarios al ofrecer
                          su contenido, permitiéndoles diseñar y administrar los
                          contenidos presentados. Es responsabilidad de cada
                          usuario/a revisar las políticas ofrecidas por dichos
                          sitios, habilitando de ser necesario o deseado por
                          cada usuario la señal de DNT en sus navegadores para
                          que terceros (especialmente sitios de anuncios) tengan
                          conocimiento de que el usuario no desea que su
                          actividad sea registrada ni seguida.
                        </li>
                        <li>
                          <b>Notificaciones por correo y Newsletter: </b>
                          Para efectos de proveer a los usuarios información
                          relevante y de interés para los mismos, Apptivate, se
                          reserva el derecho de mantener la información antes
                          señalada bajo su cuidado, elaborando una lista de
                          correos a los cuales se enviará información a los
                          usuarios sobre sus cuentas, cambios en el servicio o
                          nuevas políticas. Los usuarios no podrán optar por
                          eliminar sus correos de dicha lista (salvo en caso de
                          solicitar la cancelación de sus cuentas). Sin embargo,
                          podrán solicitar la cancelación de su suscripción a
                          correos correspondientes a boletines (“newsletters”),
                          cápsulas informativas y otros correos
                          no-administrativos. Al interactuar con los correos
                          electrónicos enviados por App-tivate (por ejemplo, al
                          abrir el correo o al hacer click en los links de su
                          contenido), App-tivate, podrá recopilar información
                          sobre dicha interacción. En ningún caso enviaremos
                          correos a los usuarios solicitando que ingresen su
                          clave y otra información personal ni sobre sus
                          cuentas.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>
                        Por qué y para qué usamos los datos. <br />
                      </b>
                      En general, usamos los datos personales de cada usuario/a
                      para proveer y mejorar las plataformas. Mediante el uso
                      del sitio o de la aplicación móvil, los usuarios han
                      aceptado la recopilación de determinados datos personales,
                      de acuerdo con la presente política y los términos y
                      condiciones de uso. La información recopilada por medio de
                      los mecanismos automáticos antes señalados, y que no
                      contenga identificación personal de los suscriptores y/o
                      usuarios de las plataformas digitales, podrá ser utilizada
                      para informar a entidades públicas, gubernamentales o a
                      terceros sobre patrones de actividad, navegación,
                      audiencia y caracterización general de este sistema. Los
                      datos personales de los usuarios recolectados a través de
                      mecanismos manuales u otros medios, serán tratados con
                      objeto de mejorar la calidad de información y contenido de
                      las plataformas digitales, procurando generar mayor
                      interés de la ciudadanía en temas asociados a la política
                      del país, bajo temáticas nacionales, regionales o
                      comunales.
                    </li>
                    <li>
                      <b>
                        Libertad, elección y acceso a la información recopilada.{" "}
                        <br />
                      </b>
                      Al aceptar la presente política de privacidad, los
                      usuarios autorizan a App-tivate, para comunicar a terceros
                      información estadística elaborada a partir de los datos
                      personales de sus usuarios, cuando de dichos datos no sea
                      posible identificar individualmente a los titulares (datos
                      disociados), de conformidad a la Ley y toda normativa
                      aplicable a nivel nacional para la protección de datos
                      personales. En todo momento, el usuario podrá ejercer los
                      derechos otorgados por la Ley Nº 19.628 y sus
                      modificaciones posteriores. Esto incluye lo siguiente:
                      <ol type="a">
                        <li>
                          Solicitar información respecto de los bancos de datos
                          de que sea responsable el organismo, el fundamento
                          jurídico de su existencia, su finalidad, tipos de
                          datos almacenados y descripción del universo de
                          personas que comprende.{" "}
                        </li>
                        <li>
                          Solicitar información sobre los datos relativos a su
                          persona, su procedencia y destinatario, el propósito
                          del almacenamiento y la individualización de las
                          personas u organismos a los cuales sus datos son
                          transmitidos regularmente.{" "}
                        </li>
                        <li>
                          Solicitar que se modifiquen sus datos personales
                          cuando ellos no sean correctos o no estén
                          actualizados, si fuere procedente.
                        </li>
                        <li>
                          Solicitar la eliminación o cancelación de los datos
                          entregados cuando así lo desee, en tanto fuere
                          procedente.
                        </li>
                      </ol>
                      Las plataformas, tanto a través del sitio como de las apps
                      puede vincularse con servidores de terceros, fuera de
                      nuestro control y dominio, por lo que será responsabilidad
                      de cada usuario/a revisar las políticas de privacidad de
                      dichos sitios App-tivate, no se hace responsable por la
                      recolección, uso, tratamiento y traspaso de los datos
                      obtenidos por servidores de terceros.
                    </li>
                    <li>
                      <b>
                        Revelación del contenido. <br />
                      </b>
                      En las situaciones limitadas en las que App-tivate, revele
                      datos personales privados, se hará bajo control de cada
                      usuario/a, porque es importante para administrar los
                      servicios o porque lo exige la ley. App-tivate, no se hace
                      responsable por la revelación de los contenidos
                      compartidos en la plataforma hecha por terceros. Sin
                      perjuicio de lo anterior, reiteramos el compromiso de
                      estricto cumplimiento de la presente política de
                      privacidad, conforme a las medidas de cuidado y de
                      publicidad acordadas con cada usuario.
                    </li>
                    <li>
                      <b>
                        Seguridad. <br />
                      </b>
                      Aparte de las autorizaciones establecidas anteriormente,
                      App-tivate, mantendrá la debida confidencialidad de los
                      datos personales y no los trasmitirá a terceros, salvo
                      cuando se deban entregar en razón de un mandato legal o
                      una orden emanada de los Tribunales de Justicia que así lo
                      requiera. Sin perjuicio de lo anterior, App-tivate, podrá
                      utilizar la información que se describe en esta Política
                      de Privacidad como ayuda para que nuestra publicidad sea
                      más relevante para los usuarios, para medir su efectividad
                      y para ayudarnos a reconocer sus dispositivos para
                      proporcionarle anuncios dentro y fuera de las aplicaciones
                      de App-tivate para dichos efectos, App-tivate, podrá
                      compartir información a socios publicitarios y afiliados,
                      que a la vez comparten información con App-tivate, como ID
                      de cookies de navegador, ID  de dispositivos móviles,
                      datos demográficos, y contenido visualizado o acciones
                      realizadas en la página web o la aplicación. La presente
                      comunicación de los datos para efectos publicitarios, se
                      regirá por la presente política, especialmente en lo
                      referido a la responsabilidad del usuario al aceptar
                      políticas de privacidad, términos y condiciones de
                      terceros ajenos a la aplicación. Con el objeto de evitar
                      toda pérdida, mal uso, alteración, acceso no autorizado
                      y/o robo de datos personales o información confidencial
                      facilitados por los usuarios, App-tivate, ha adoptado
                      niveles de seguridad y de protección de datos personales
                      según los estándares de la industria, garantizando su
                      resguardo bajo el grado de diligencia legalmente exigido.
                      En consecuencia, los usuarios declaran comprender que, en
                      caso de vulneración mediante acciones ilícitas, por
                      causales de fuerza mayor, o por requerimientos de
                      autoridad, App-tivate, no podrá garantizar la total y
                      absoluta inviolabilidad de su base de datos, ni el
                      perfecto funcionamiento de sus mecanismos de protección y
                      resguardo. La seguridad de los datos personales de los
                      usuarios es muy importante para App-tivate, sin embargo,
                      informamos que ningún método de transmisión a través de
                      internet, o de almacenamiento electrónico es totalmente
                      infalible. Sin perjuicio de lo anterior, App- tivate, se
                      compromete a implementar la presente política de
                      privacidad y seguridad de forma estricta.
                    </li>
                    <li>
                      <b>Cambios en la presente política de privacidad.</b>
                      <br />
                      La presente política de privacidad tendrá vigencia a
                      partir de 1 de julio de 2022 y de por tiempo indefinido,
                      salvo en lo referido a cualquier cambio en sus cláusulas
                      que se produzca en el futuro. En caso de cualquier
                      modificación, aquella tendrá efecto a partir de su
                      publicación en la presente página. Nos reservamos el
                      derecho de actualizar o modificar la política de
                      privacidad en cualquier momento, y será responsabilidad de
                      cada usuario/a revisar periódicamente sus cambios. Se
                      entenderá que cualquier actividad y/o uso de la
                      plataforma, sea a través de la página web o de su formato
                      en la aplicación móvil, constituirá la aceptación tácita
                      de las modificaciones realizadas a la presente política, y
                      su consentimiento para la aplicación de la misma en su
                      totalidad. En caso de realizar cualquier modificación a la
                      presente política, notificaremos a los usuarios a través
                      de un correo electrónico al correo que haya sido informado
                      por los mismos, o a través de una notoria publicación en
                      la página web{" "}
                      <a href="http://vidasaludable.udec.cl/">
                        http://vidasaludable.udec.cl/
                      </a>
                    </li>
                  </ol>
                  <p>
                    <b>Contacto.</b>
                    En caso de consultas sobre la presente política, o presentar
                    problemas relacionados con la protección de sus datos por
                    App-tivate, solicitamos escribir un correo electrónico a
                    rafaelzapata@udec.cl
                  </p>
                  <p>
                    <b>Fecha de última actualización: Enero 2024.</b>
                  </p>
                </div>
                <div className="mt-5 text-center">
                  <Link className="btn btn-primary" to="/">
                    Volver
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default TerminosCondiciones
