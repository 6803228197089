import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Flatpickr from "react-flatpickr"
import BootstrapTable from 'react-bootstrap-table-next';
import { API_BASE_URL } from "helpers/url_helper"
import ReactApexChart from "react-apexcharts"
import Select from "react-select"
import cara1 from "../../assets/images/Asset_1.svg"
import cara2 from "../../assets/images/Asset_2.svg"
import cara3 from "../../assets/images/Asset_3.svg"
import cara4 from "../../assets/images/Asset_4.svg"
import cara5 from "../../assets/images/Asset_5.svg"
import { Spanish } from "flatpickr/dist/l10n/es.js";

// datatable related plugins
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Alert
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { times } from "lodash";

class PacientesDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showGraph: false,
      evaluacion: 0,
      modal: false,
      modalInsert: false,
      page: 1,
      listaMovilidad : [
        { value: 1, label: "Restricción severa" },
        { value: 2, label: "Restricción moderada" },
        { value: 3, label: "No hay restricción" },
      ],
      nutritionalList : [
        { value: "weight", label: "Peso" },
        { value: "height", label: "Altura" },
        { value: "bmi", label: "IMC" },
        { value: "body_fat_mass", label: "Grasa corporal" },
        { value: "lean_body_mass", label: "Masa magra" },
        { value: "body_fat_percent", label: "% grasa" },
        { value: "lean_body_percent", label: "% magra" },
        { value: "waist_circunference", label: "Cintura" },
        { value: "hips_circunference", label: "Cadera" },
        { value: "neck_circunference", label: "Cuello" }
      ],
      physicalList : [
        { value: "abdominal_evaluation", label: "Abdominal" },
        { value: "balance", label: "Balance" },
        { value: "pressure_force_right", label: "Fuerza presión derecha" },
        { value: "pressure_force_left", label: "Fuerza presión izquierda" },
        { value: "sit_stand", label: "Sentarse/Pararse" },
        { value: "six_walk_test", label: "Test marcha" },
        { value: "vo2_max", label: "VO2 Max" },
      ],
      labtestList : [
        { value: "c_reactive_protein", label: "Proteína c-reactiva" },
        { value: "cholesterol", label: "Colesterol" },
        { value: "glycemia", label: "Glicemia" },
        { value: "hdl", label: "HDL" },
        { value: "insulin", label: "Insulina" },
        { value: "ldl", label: "LDL" },
        { value: "triglycerides", label: "Triglicéridos" },
      ],
      clases: [],
      sizePerPage: 10,
      nutritionalEvaluations: [],
      physicalevaluations: [],
      labtestevaluations: []
    }
    this.toggle = this.toggle.bind(this)
    this.toggleInsert = this.toggleInsert.bind(this)
  }

  componentDidMount(){
    document.getElementById("link_pacientes").style.color = "white";
    document.getElementById("icono_pacientes").style.color = "white";
    console.log("patient_id: ", this.props.match.params.id);
    const patient_id = this.props.match.params.id
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`patient/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data.data);
        if(res.data.data.name!=null){
          this.setState({nombre: res.data.data.name});
        }
        if(res.data.data.lastname!=null){
          this.setState({apellido: res.data.data.lastname});
        }
        if(res.data.data.rut!=null){
          this.setState({rut: res.data.data.rut});
        }
        if(res.data.data.User_Has_Patient[0].address!=null){
          this.setState({direccion: res.data.data.User_Has_Patient[0].address});
        }
        if(res.data.data.email!=null){
          this.setState({email: res.data.data.email});
        }
        if(res.data.data.User_Has_Patient[0].telephone!=null){
          this.setState({telefono: res.data.data.User_Has_Patient[0].telephone});
        }
        if(res.data.data.User_Has_Patient[0].dateofbirth!=null){
          this.setState({fechanacimiento: res.data.data.User_Has_Patient[0].dateofbirth});
        }
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });

    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    const hasta = ano+"-"+mes+"-"+dia;
    axios.get(API_BASE_URL+`statistic/patient/${patient_id}/2021-01-01/${hasta}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("statistic: ",res.data.data);
        this.setState({ clases: res.data.data.class_history });
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });

    this.listarPhysicalevaluation()
    this.listarLabtest()
    this.listarNutritionalEvaluation()
  }

  listarNutritionalEvaluation(){
    const token = JSON.parse(localStorage.getItem("token"))
    const patient_id = this.props.match.params.id
    axios.get(API_BASE_URL+`nutritionalevaluation/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        //console.log("res.data: ",res.data.data);
        this.setState({ nutritionalEvaluations: res.data.data})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  listarPhysicalevaluation(){
    const token = JSON.parse(localStorage.getItem("token"))
    const patient_id = this.props.match.params.id
    axios.get(API_BASE_URL+`physicalevaluation/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("physicalevaluation.data: ",res.data.data);
        this.setState({ physicalevaluations: res.data.data})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  listarLabtest(){
    const token = JSON.parse(localStorage.getItem("token"))
    const patient_id = this.props.match.params.id
    axios.get(API_BASE_URL+`labtest/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("labtest.data: ",res.data.data);
        this.setState({ labtestevaluations: res.data.data})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  /* Handling the modal state */
  toggle = () => {
    this.setState({ showGraph: false})
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  /* Handling the modal state */
  toggleInsert = () => {
    this.setState({ mensaje_exito: null })
    this.setState({ mensaje_error: null })
    this.setState(prevState => ({
      modalInsert: !prevState.modalInsert,
    }))
  }

  handleLabtestGraph = event => {
    const patient_id = this.props.match.params.id
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`labtest/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        var datos = []
        var fechas = []
        for (var i = 0; i < res.data.data.length; i++) {
          if(event.value=="c_reactive_protein"){
            datos.push(res.data.data[i].c_reactive_protein);
          }
          if(event.value=="cholesterol"){
            datos.push(res.data.data[i].cholesterol);
          }
          if(event.value=="glycemia"){
            datos.push(res.data.data[i].glycemia);
          }
          if(event.value=="hdl"){
            datos.push(res.data.data[i].hdl);
          }
          if(event.value=="insulin"){
            datos.push(res.data.data[i].insulin);
          }
          if(event.value=="ldl"){
            datos.push(res.data.data[i].ldl);
          }
          if(event.value=="triglycerides"){
            datos.push(res.data.data[i].triglycerides);
          }
          fechas.push(res.data.data[i].evaluation_date);
        }
        this.setState({series: [{name: event.label, data: datos}] });
        this.setState({ options: {
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 3,
            },
            colors: ["#556ee6", "#34c38f"],
            xaxis: {
              type: "date",
              categories: fechas
            },
            grid: {
              borderColor: "#f1f1f1",
            },
            tooltip: {
              x: {
                format: "Y-m-d",
              },
            }
          }
        })
        this.setState({ showGraph: true})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }
  
  handlePhysicalGraph = event => {
    const patient_id = this.props.match.params.id
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`physicalevaluation/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        var datos = []
        var fechas = []
        for (var i = 0; i < res.data.data.length; i++) {
          if(event.value=="abdominal_evaluation"){
            datos.push(res.data.data[i].abdominal_evaluation);
          }
          if(event.value=="balance"){
            datos.push(res.data.data[i].balance);
          }
          if(event.value=="pressure_force_right"){
            datos.push(res.data.data[i].pressure_force_right);
          }
          if(event.value=="pressure_force_left"){
            datos.push(res.data.data[i].pressure_force_left);
          }
          if(event.value=="sit_stand"){
            datos.push(res.data.data[i].sit_stand);
          }
          if(event.value=="six_walk_test"){
            datos.push(res.data.data[i].six_walk_test);
          }
          if(event.value=="vo2_max"){
            datos.push(res.data.data[i].vo2_max);
          }
          fechas.push(res.data.data[i].evaluation_date);
        }
        this.setState({series: [{name: event.label, data: datos}] });
        this.setState({ options: {
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 3,
            },
            colors: ["#556ee6", "#34c38f"],
            xaxis: {
              type: "date",
              categories: fechas
            },
            grid: {
              borderColor: "#f1f1f1",
            },
            tooltip: {
              x: {
                format: "Y-m-d",
              },
            }
          }
        })
        this.setState({ showGraph: true})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  handleNutritionalGraph = event => {
    const patient_id = this.props.match.params.id
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`nutritionalevaluation/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        var datos = []
        var fechas = []
        for (var i = 0; i < res.data.data.length; i++) {
          if(event.value=="weight"){
            datos.push(res.data.data[i].weight);
          }
          if(event.value=="body_fat_mass"){
            datos.push(res.data.data[i].body_fat_mass);
          }
          if(event.value=="body_fat_percent"){
            datos.push(res.data.data[i].body_fat_percent);
          }
          if(event.value=="height"){
            datos.push(res.data.data[i].height);
          }
          if(event.value=="hips_circunference"){
            datos.push(res.data.data[i].hips_circunference);
          }
          if(event.value=="lean_body_mass"){
            datos.push(res.data.data[i].lean_body_mass);
          }
          if(event.value=="lean_body_percent"){
            datos.push(res.data.data[i].lean_body_percent);
          }
          if(event.value=="neck_circunference"){
            datos.push(res.data.data[i].neck_circunference);
          }
          if(event.value=="waist_circunference"){
            datos.push(res.data.data[i].waist_circunference);
          }
          if(event.value=="bmi"){
            datos.push(res.data.data[i].bmi);
          }
          fechas.push(res.data.data[i].evaluation_date);
        }
        this.setState({series: [{name: event.label, data: datos}] });
        this.setState({ options: {
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 3,
            },
            colors: ["#556ee6", "#34c38f"],
            xaxis: {
              type: "date",
              categories: fechas
            },
            grid: {
              borderColor: "#f1f1f1",
            },
            tooltip: {
              x: {
                format: "Y-m-d",
              },
            }
          }
        })
        this.setState({ showGraph: true})
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  mostrarGraficos(evaluacion){
    this.toggle()
    this.setState({ evaluacion: evaluacion })
    //nutrition = 1
    //physical = 2
    //labtest = 3
  }

  formatAsistencia = (cell, row, rowIndex, formatExtraData) => {
    //0:no asistio, 1:asistio
    if(row.attendance==0){
      var asistencia = "No";
    }
    else{
      var asistencia = "Si";
    }
    return (
      <>{asistencia}</>
    );
  };

  formatEvaluacion1 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.evaluations[0]){
      evaluacion = "Respuesta: "+ row.evaluations[0].point_id
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };
  formatEvaluacion2 = (cell, row, rowIndex, formatExtraData) => {
    var evaluacion = ''
    if(row.evaluations[1]){
      var cara = ''
      if(row.evaluations[1].point_id==1){
        cara = cara1
      }
      if(row.evaluations[1].point_id==2){
        cara = cara2
      }
      if(row.evaluations[1].point_id==3){
        cara = cara3
      }
      if(row.evaluations[1].point_id==4){
        cara = cara4
      }
      if(row.evaluations[1].point_id==5){
        cara = cara5
      }
      evaluacion = <img
          src={cara}
          alt=""
          height="19"
        />
    }else{
      evaluacion = "No evaluada"
    }
    return (
      <>{evaluacion}</>
    );
  };

  formatFecha = (cell, row, rowIndex, formatExtraData) => {
    var fecha ='';
    if(row.date!=null){
      var fecha = row.date.substr(0,10); 
      //console.log("fecha: ",row.User_Has_Patient[0].dateofbirth);
    }
    return (
      <>{fecha}</>
    );
  };

  formatTiempo = (cell, row, rowIndex, formatExtraData) => {
    var tiempo=0
    if(row.time_in_class>0){
      tiempo = Math.round(row.time_in_class/60)
    }
    return (
      <>{tiempo+" minutos"}</>
    );
  };

  eliminarEvaluacion(id, tipo){
    //nutrition = 1
    //physical = 2
    //labtest = 3
    if (confirm('Está seguro que desea eliminarlo?')) {
      if(tipo==1){
        const token = JSON.parse(localStorage.getItem("token"))
        axios.delete(API_BASE_URL+`nutritionalevaluation/${id}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          this.listarNutritionalEvaluation()
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });
      }
      if(tipo==2){
        const token = JSON.parse(localStorage.getItem("token"))
        axios.delete(API_BASE_URL+`physicalevaluation/${id}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          this.listarPhysicalevaluation()
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });
      }
      if(tipo==3){
        const token = JSON.parse(localStorage.getItem("token"))
        axios.delete(API_BASE_URL+`labtest/${id}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          this.listarLabtest()
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
          }
        });
      }
    }
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.id;
    //console.log("ROW: ",row, "/EXTRA: ",formatExtraData)
    return (
      <Col>
        <Button id={"botonId_"+id} className="btn btn-danger"
          onClick={() => {
            this.eliminarEvaluacion(id, formatExtraData);
          }}
        >
          Eliminar
        </Button>
      </Col>
    );
  }

  showModalInsert(evaluacion){
    this.toggleInsert()
    this.setState({ evaluacion: evaluacion })
    //nutrition = 1
    //physical = 2
    //labtest = 3
  }

  insertData(tipo){
    this.setState({ mensaje_exito: null })
    this.setState({ mensaje_error: null })
    console.log("tipo: ",tipo)
    const patient_id = this.props.match.params.id
    if(tipo==1){
      const data = {
        patient_id: patient_id,
        label: this.state.label,
        evaluation_date: this.state.evaluation_date,
        weight: this.state.weight,
        height: this.state.height,
        bmi: this.state.bmi,
        body_fat_mass: this.state.body_fat_mass,
        lean_body_mass: this.state.lean_body_mass,
        body_fat_percent: this.state.body_fat_percent,
        lean_body_percent: this.state.lean_body_percent,
        waist_circunference: this.state.waist_circunference,
        hips_circunference: this.state.hips_circunference,
        neck_circunference: this.state.neck_circunference
      };
  
      const token = JSON.parse(localStorage.getItem("token"))
      axios.post(API_BASE_URL+`nutritionalevaluation`, {data}, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          //console.log("res.data: ",res.data);
          this.setState({mensaje_exito: "Datos ingresados correctamente"})
          this.listarNutritionalEvaluation()
        })
        .catch(error => {
          if (error.response) {
            this.setState({mensaje_error: "Error: por favor complete y revise los datos"})
            console.log("ERROR: ",error.response.data);
          }
        });
    }
    if(tipo==2){
      const data = {
        patient_id: patient_id,
        label: this.state.label,
        evaluation_date: this.state.evaluation_date,
        vo2_max: this.state.vo2_max,
        six_walk_test: this.state.six_walk_test,
        pressure_force_right: this.state.pressure_force_right,
        pressure_force_left: this.state.pressure_force_left,
        sit_stand: this.state.sit_stand,
        abdominal_evaluation: this.state.abdominal_evaluation,
        balance: this.state.balance,
        upperlimb_id: this.state.upperlimb_id
      };
  
      const token = JSON.parse(localStorage.getItem("token"))
      axios.post(API_BASE_URL+`physicalevaluation`, {data}, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          //console.log("res.data: ",res.data);
          this.setState({mensaje_exito: "Datos ingresados correctamente"})
          this.listarPhysicalevaluation()
        })
        .catch(error => {
          if (error.response) {
            this.setState({mensaje_error: "Error: por favor complete y revise los datos"})
            console.log("ERROR: ",error.response.data);
          }
        });
    }
    if(tipo==3){
      const data = {
        patient_id: patient_id,
        label: this.state.label,
        evaluation_date: this.state.evaluation_date,
        cholesterol: this.state.cholesterol,
        hdl: this.state.hdl,
        ldl: this.state.ldl,
        triglycerides: this.state.triglycerides,
        insulin: this.state.insulin,
        glycemia: this.state.glycemia,
        c_reactive_protein: this.state.c_reactive_protein
      };
  
      const token = JSON.parse(localStorage.getItem("token"))
      axios.post(API_BASE_URL+`labtest`, {data}, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          //console.log("res.data: ",res.data);
          this.setState({mensaje_exito: "Datos ingresados correctamente"})
          this.listarLabtest()
        })
        .catch(error => {
          if (error.response) {
            this.setState({mensaje_error: "Error: por favor complete y revise los datos"})
            console.log("ERROR: ",error.response.data);
          }
        });
    }
  }

  handleChangeCholesterol = event => { 
    this.setState({ cholesterol: event.target.value }); 
  }
  handleChangeHdl = event => { 
    this.setState({ hdl: event.target.value }); 
  }
  handleChangeLdl = event => { 
    this.setState({ ldl: event.target.value });
  }
  handleChangeTriglycerides = event => { 
    this.setState({ triglycerides: event.target.value });
  }
  handleChangeInsulin = event => { 
    this.setState({ insulin: event.target.value });
  }
  handleChangeGlycemia = event => { 
    this.setState({ glycemia: event.target.value });
  }
  handleChangeC_reactive_protein = event => {  
    this.setState({ c_reactive_protein: event.target.value });
  }
  handleChangeUpperlimb_id = event => { 
    this.setState({ upperlimb_id: event.value });  
  }
  handleChangeBalance = event => { 
    this.setState({ balance: event.target.value }); 
  }
  handleChangeAbdominal_evaluation = event => { 
    this.setState({ abdominal_evaluation: event.target.value }); 
  }
  handleChangeSit_stand = event => { 
    this.setState({ sit_stand: event.target.value });
  }
  handleChangePressure_force_right = event => { 
    this.setState({ pressure_force_right: event.target.value });
  }
  handleChangePressure_force_left = event => { 
    this.setState({ pressure_force_left: event.target.value });
  }
  handleChangeSix_walk_test = event => {
    this.setState({ six_walk_test: event.target.value });
  }
  handleChangeVo2max = event => {
    this.setState({ vo2_max: event.target.value });
  }
  handleChangeLabel = event => {
    this.setState({ label: event.target.value });
  }
  handleChangeEvaluation_date = event => { 
    this.setState({ evaluation_date: event.evaluation_date });
  }
  handleChangeWeight = event => {
    this.setState({ weight: event.target.value });
  }
  handleChangeHeight = event => {
    this.setState({ height: event.target.value });
  }
  handleChangeBmi = event => {
    this.setState({ bmi: event.target.value });
  }
  handleChangeBody_fat_mass = event => {
    this.setState({ body_fat_mass: event.target.value });
  }
  handleChangeLean_body_mass = event => {
    this.setState({ lean_body_mass: event.target.value });
  }
  handleChangeLean_body_percent = event => {
    this.setState({ lean_body_percent: event.target.value });
  }
  handleChangeBody_fat_percent = event => {
    this.setState({ body_fat_percent: event.target.value });
  }
  handleChangeNeck_circunference = event => {
    this.setState({ neck_circunference: event.target.value });
  }
  handleChangeHips_circunference = event => {
    this.setState({ hips_circunference: event.target.value });
  }
  handleChangeWaist_circunference = event => {
    this.setState({ waist_circunference: event.target.value });
  }

  render() {

    const { ExportCSVButton } = CSVExport;

    const labtestColumns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'evaluation_date',
      text: 'Fecha',
      sort: true
    }, {
      dataField: 'label',
      text: 'Etiqueta',
      sort: true
    }, {
      dataField: 'c_reactive_protein',
      text: 'Proteína c-reactiva',
      sort: true
    }, {
      dataField: 'cholesterol',
      text: 'Colesterol',
      sort: true
    }, {
      dataField: 'glycemia',
      text: 'Glicemia',
      sort: true
    }, {
      dataField: 'hdl',
      text: 'HDL',
      sort: true
    }, {
      dataField: 'ldl',
      text: 'LDL',
      sort: true
    }, {
      dataField: 'triglycerides',
      text: 'Triglicéridos',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      formatExtraData: 3,
      sort: false
    }];

    const physicalColumns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'evaluation_date',
      text: 'Fecha',
      sort: true
    }, {
      dataField: 'label',
      text: 'Etiqueta',
      sort: true
    }, {
      dataField: 'abdominal_evaluation',
      text: 'Abdominal',
      sort: true
    }, {
      dataField: 'pressure_force_right',
      text: 'Fuerza presión derecha',
      sort: true
    }, {
      dataField: 'pressure_force_left',
      text: 'Fuerza presión izquierda',
      sort: true
    }, {
      dataField: 'sit_stand',
      text: 'Sentarse/Pararse',
      sort: true
    }, {
      dataField: 'six_walk_test',
      text: 'Test marcha',
      sort: true
    }, {
      dataField: 'vo2_max',
      text: 'VO2 Max',
      sort: true
    }, {
      dataField: 'Upper_Limb_Mobility.category',
      text: 'Movilidad',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      formatExtraData: 2,
      sort: false
    }];

    const nutritionalColumns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'evaluation_date',
      text: 'Fecha',
      sort: true
    }, {
      dataField: 'label',
      text: 'Etiqueta',
      sort: true
    }, {
      dataField: 'weight',
      text: 'Peso',
      sort: true
    }, {
      dataField: 'height',
      text: 'Altura',
      sort: true
    }, {
      dataField: 'bmi',
      text: 'IMC',
      sort: true
    }, {
      dataField: 'body_fat_mass',
      text: 'Grasa corporal',
      sort: true
    }, {
      dataField: 'lean_body_mass',
      text: 'Masa magra',
      sort: true
    }, {
      dataField: 'body_fat_percent',
      text: '% grasa',
      sort: true
    }, {
      dataField: 'lean_body_percent',
      text: '% magra',
      sort: true
    }, {
      dataField: 'waist_circunference',
      text: 'Cintura',
      sort: true
    }, {
      dataField: 'hips_circunference',
      text: 'Cadera',
      sort: true
    }, {
      dataField: 'neck_circunference',
      text: 'Cuello',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      formatExtraData: 1,
      sort: false
    }];

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'date',
      text: 'Fecha',
      headerStyle: { width: '10%' },
      formatter: this.formatFecha,
      sort: true
    }, {
      dataField: 'class_name',
      text: 'Nombre clase',
      sort: true
    }, {
      dataField: 'description',
      text: 'Detalle',
      style: { textAlign: 'justify' },
      sort: true
    }, {
      dataField: 'Asistencia',
      text: 'Asistencia',
      formatter: this.formatAsistencia,
      sort: true
    }, {
      dataField: 'time_in_class',
      text: 'Tiempo asistido',
      formatter: this.formatTiempo,
      sort: true
    }, {
      dataField: 'evaluations1',
      text: 'Pregunta 1',
      formatter: this.formatEvaluacion1,
      sort: true
    }, {
      dataField: 'evaluations2',
      text: 'Pregunta 2',
      formatter: this.formatEvaluacion2,
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pacientes / Detalle</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Pacientes / Detalle" breadcrumbItem="Pacientes / Detalle" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4>Datos personales</h4><hr></hr>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientenombre"
                          className="col-sm-2 col-form-label"
                        >
                          Nombre
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="nombre"
                            disabled={true}
                            value={this.state.nombre}
                            type="text"
                            className="form-control"
                            id="paciente_nombre"
                          />
                        </Col>
                        
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteapellido"
                          className="col-sm-2 col-form-label"
                        >
                          Apellido
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="apellido"
                            disabled={true}
                            value={this.state.apellido}
                            type="text"
                            className="form-control"
                            id="paciente_apellido"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienterut"
                          className="col-sm-2 col-form-label"
                        >
                          RUT
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="rut"
                            disabled={true}
                            value={this.state.rut}
                            type="text"
                            className="form-control"
                            id="paciente_rut"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientedireccion"
                          className="col-sm-2 col-form-label"
                        >
                          Direccion
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="direccion"
                            disabled={true}
                            value={this.state.direccion}
                            type="text"
                            className="form-control"
                            id="paciente_direccion"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteemail"
                          className="col-sm-2 col-form-label"
                        >
                          Email
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="email"
                            disabled={true}
                            value={this.state.email}
                            type="text"
                            className="form-control"
                            id="paciente_email"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientetelefono"
                          className="col-sm-2 col-form-label"
                        >
                          Teléfono
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="telefono"
                            disabled={true}
                            value={this.state.telefono}
                            type="text"
                            className="form-control"
                            id="paciente_telefono"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientefechanacimiento"
                          className="col-sm-2 col-form-label"
                        >
                          Fecha nacimiento
                        </Label>
                        <Col className="col-sm-10">
                          <InputGroup>
                            <Flatpickr
                              disabled={true}
                              value={this.state.fechanacimiento}
                              className="form-control d-block"
                              options={{
                                dateFormat: "Y-m-d",
                                allowInput: true
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-calendar-outline" />
                              </span>
                            </div>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    </AvForm>
                    <br></br>
                    <h4>Seguimiento de clases</h4><hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.clases}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={this.state.clases}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-md-2 offset-6">
                                <div className="d-grid">
                                  <ExportCSVButton className="btn btn-outline-secondary" { ...toolkitProps.csvProps }>Exportar</ExportCSVButton>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle" /*table-nowrap*/
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    <br></br>
                    <h4>Evaluación Nutricional 
                      &nbsp;
                      <Button className="btn btn-success"
                        onClick={() => {
                          this.showModalInsert(1);
                        }}
                      >
                        Insertar datos
                      </Button>
                      &nbsp;
                      <Button className="btn btn-warning"
                        onClick={() => {
                          this.mostrarGraficos(1);
                        }}
                      >
                        Ver Gráficos
                      </Button>
                    </h4>
                    <hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={nutritionalColumns}
                      data={this.state.nutritionalEvaluations}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={nutritionalColumns}
                        data={this.state.nutritionalEvaluations}
                        search
                        exportCSV
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-md-2 offset-6">
                                <div className="d-grid">
                                  <ExportCSVButton className="btn btn-outline-secondary" { ...toolkitProps.csvProps }>Exportar</ExportCSVButton>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    //rowEvents={this.eventosRowOnClick} 
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    <br></br>
                    <h4>Evaluación Física 
                      &nbsp;
                      <Button className="btn btn-success"
                        onClick={() => {
                          this.showModalInsert(2);
                        }}
                      >
                        Insertar datos
                      </Button>
                      &nbsp;
                      <Button className="btn btn-warning"
                        onClick={() => {
                          this.mostrarGraficos(2);
                        }}
                      >
                        Ver Gráficos
                      </Button>
                    </h4>
                    <hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={physicalColumns}
                      data={this.state.physicalevaluations}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={physicalColumns}
                        data={this.state.physicalevaluations}
                        search
                        exportCSV
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-md-2 offset-6">
                                <div className="d-grid">
                                  <ExportCSVButton className="btn btn-outline-secondary" { ...toolkitProps.csvProps }>Exportar</ExportCSVButton>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    //rowEvents={this.eventosRowOnClick} 
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    <br></br>
                    <h4>Toma de muestra 
                        &nbsp;
                      <Button className="btn btn-success"
                        onClick={() => {
                          this.showModalInsert(3);
                        }}
                      >
                        Insertar datos
                      </Button>
                      &nbsp;
                      <Button className="btn btn-warning"
                        onClick={() => {
                          this.mostrarGraficos(3);
                        }}
                      >
                        Ver Gráficos
                      </Button>
                    </h4>
                    <hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={labtestColumns}
                      data={this.state.labtestevaluations}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={labtestColumns}
                        data={this.state.labtestevaluations}
                        search
                        exportCSV
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-md-2 offset-6">
                                <div className="d-grid">
                                  <ExportCSVButton className="btn btn-outline-secondary" { ...toolkitProps.csvProps }>Exportar</ExportCSVButton>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    //rowEvents={this.eventosRowOnClick} 
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    <Modal isOpen={this.state.modal} >
                      <ModalHeader toggle={this.toggle} tag="h4">Gráficos</ModalHeader>
                      <ModalBody>
                        <Row>
                          {this.state.evaluacion==1 &&
                            <Col className="col-12 mb-3">
                              <Select
                                onChange={this.handleNutritionalGraph}
                                options={this.state.nutritionalList}
                                placeholder="Seleccione..."
                              />
                            </Col>
                          }
                          {this.state.evaluacion==2 &&
                            <Col className="col-12 mb-3">
                              <Select
                                onChange={this.handlePhysicalGraph}
                                options={this.state.physicalList}
                                placeholder="Seleccione..."
                              />
                            </Col>
                          }
                          {this.state.evaluacion==3 &&
                            <Col className="col-12 mb-3">
                              <Select
                                onChange={this.handleLabtestGraph}
                                options={this.state.labtestList}
                                placeholder="Seleccione..."
                              />
                            </Col>
                          }
                          {this.state.showGraph &&
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              type="area"
                              height="350"
                              className="apex-charts"
                            />
                          }
                        </Row>
                      </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.modalInsert} >
                      <ModalHeader toggle={this.toggleInsert} tag="h4">Insertar datos</ModalHeader>
                      <ModalBody>
                        <Row>
                          {this.state.evaluacion==1 &&
                            <Col className="col-12 mb-3">
                              <AvForm className="needs-validation">
                              <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Etiqueta
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="etiqueta"
                                      placeholder="Ingrese etiqueta"
                                      type="text"
                                      errorMessage="Ingrese etiqueta"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeLabel}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Fecha 
                                  </Label>
                                  <Col className="col-sm-6">
                                    <InputGroup>
                                      <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Ingrese fecha"
                                        onChange={(date, evaluation_date) => {this.handleChangeEvaluation_date({evaluation_date})}}
                                        options={{
                                          dateFormat: "Y-m-d",
                                          allowInput: true,
                                          locale: {
                                            ...Spanish
                                          }
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i className="mdi mdi-calendar-outline" />
                                        </span>
                                      </div>
                                    </InputGroup>
                                   </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Peso (kg)
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="peso"
                                      placeholder="Ingrese peso"
                                      type="number"
                                      errorMessage="Ingrese peso"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeWeight}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Altura (cm)
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="altura"
                                      placeholder="Ingrese altura"
                                      type="number"
                                      errorMessage="Ingrese altura"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeHeight}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    IMC
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="bmi"
                                      placeholder="Ingrese IMC"
                                      type="number"
                                      errorMessage="Ingrese IMC"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeBmi}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Grasa corporal (kg)
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="grasaCorporal"
                                      placeholder="Ingrese grasa corporal"
                                      type="number"
                                      errorMessage="Ingrese grasa corporal"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeBody_fat_mass}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Masa magra (kg)
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="masaMagra"
                                      placeholder="Ingrese masa magra"
                                      type="number"
                                      errorMessage="Ingrese masa magra"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeLean_body_mass}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Porcentaje grasa
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="porcentajeGrasa"
                                      placeholder="Ingrese porcentaje grasa"
                                      type="number"
                                      errorMessage="Ingrese porcentaje grasa"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeBody_fat_percent}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Porcentaje masa magra 
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="porcentajeMasaMagra"
                                      placeholder="Ingrese porcentaje masa magra"
                                      type="number"
                                      errorMessage="Ingrese porcentaje masa magra"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeLean_body_percent}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Cintura (cm)
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="cintura"
                                      placeholder="Ingrese cintura"
                                      type="number"
                                      errorMessage="Ingrese cintura"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeWaist_circunference}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Caderas (cm)  
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="caderas"
                                      placeholder="Ingrese caderas"
                                      type="number"
                                      errorMessage="Ingrese caderas"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeHips_circunference}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Cuello (cm)  
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="cuello"
                                      placeholder="Ingrese cuello"
                                      type="number"
                                      errorMessage="Ingrese cuello"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeNeck_circunference}
                                    />
                                  </Col>
                                </FormGroup>
                              </AvForm>
                            </Col>
                          }
                          {this.state.evaluacion==2 &&
                            <Col className="col-12 mb-3">
                              <AvForm className="needs-validation">
                              <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Etiqueta
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="etiqueta"
                                      placeholder="Ingrese etiqueta"
                                      type="text"
                                      errorMessage="Ingrese etiqueta"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeLabel}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Fecha 
                                  </Label>
                                  <Col className="col-sm-6">
                                    <InputGroup>
                                      <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Ingrese fecha"
                                        onChange={(date, evaluation_date) => {this.handleChangeEvaluation_date({evaluation_date})}}
                                        options={{
                                          dateFormat: "Y-m-d",
                                          allowInput: true,
                                          locale: {
                                            ...Spanish
                                          }
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i className="mdi mdi-calendar-outline" />
                                        </span>
                                      </div>
                                    </InputGroup>
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    VO2 Max
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="vo2max"
                                      placeholder="Ingrese vo2 max"
                                      type="number"
                                      errorMessage="Ingrese vo2 max"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeVo2max}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Test marcha
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="testMarcha"
                                      placeholder="Ingrese cantidad"
                                      type="number"
                                      errorMessage="Ingrese cantidad"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeSix_walk_test} 
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Fuerza presión derecha
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="fuerzaPresionDerecha"
                                      placeholder="Ingrese fuerza presión derecha"
                                      type="number"
                                      errorMessage="Ingrese fuerza presión derecha"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangePressure_force_right}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Fuerza presión izquierda
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="fuerzaPresionIzquierda"
                                      placeholder="Ingrese fuerza presión izquierda"
                                      type="number"
                                      errorMessage="Ingrese fuerza presión izquierda"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangePressure_force_left}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Sentar / Pararse
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="sentarsePararse"
                                      placeholder="Ingrese cantidad"
                                      type="number"
                                      errorMessage="Ingrese cantidad"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeSit_stand} 
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Abdominal
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="abdominal"
                                      placeholder="Ingrese abdominal"
                                      type="number"
                                      errorMessage="Ingrese abdominal"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeAbdominal_evaluation} 
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Balance
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="balance"
                                      placeholder="Ingrese balance"
                                      type="number"
                                      errorMessage="Ingrese balance"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeBalance} 
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Movilidad
                                  </Label>
                                  <Col className="col-sm-6">
                                    <Select
                                      onChange={this.handleChangeUpperlimb_id}
                                      options={this.state.listaMovilidad}
                                      placeholder="Seleccione..."
                                    />
                                  </Col>
                                </FormGroup>
                              </AvForm>
                            </Col>
                          }
                          {this.state.evaluacion==3 &&
                            <Col className="col-12 mb-3">
                              <AvForm className="needs-validation">
                              <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Etiqueta
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="etiqueta"
                                      placeholder="Ingrese etiqueta"
                                      type="text"
                                      errorMessage="Ingrese etiqueta"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeLabel}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Fecha 
                                  </Label>
                                  <Col className="col-sm-6">
                                    <InputGroup>
                                      <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Ingrese fecha"
                                        onChange={(date, evaluation_date) => {this.handleChangeEvaluation_date({evaluation_date})}}
                                        options={{
                                          dateFormat: "Y-m-d",
                                          allowInput: true,
                                          locale: {
                                            ...Spanish
                                          }
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i className="mdi mdi-calendar-outline" />
                                        </span>
                                      </div>
                                    </InputGroup>
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Colesterol
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="colesterol"
                                      placeholder="Ingrese colesterol"
                                      type="number"
                                      errorMessage="Ingrese colesterol"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeCholesterol} 
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    HDL
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="hdl"
                                      placeholder="Ingrese hdl"
                                      type="number"
                                      errorMessage="Ingrese hdl"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeHdl}  
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    LDL
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="ldl"
                                      placeholder="Ingrese ldl"
                                      type="number"
                                      errorMessage="Ingrese ldl"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeLdl}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Trigliceridos
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="trigliceridos"
                                      placeholder="Ingrese trigliceridos"
                                      type="number"
                                      errorMessage="Ingrese trigliceridos"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeTriglycerides}  
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Insulina
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="insulina"
                                      placeholder="Ingrese insulina"
                                      type="number"
                                      errorMessage="Ingrese insulina"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeInsulin}  
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Glicemia
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="glicemia"
                                      placeholder="Ingrese glicemia"
                                      type="number"
                                      errorMessage="Ingrese glicemia"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeGlycemia} 
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="row mb-1">
                                  <Label className="col-sm-6 col-form-label">
                                    Proteina c-reactiva
                                  </Label>
                                  <Col className="col-sm-6">
                                    <AvField
                                      name="proteina"
                                      placeholder="Ingrese proteina c-reactiva"
                                      type="number"
                                      errorMessage="Ingrese proteina c-reactiva"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      onChange={this.handleChangeC_reactive_protein} 
                                    />
                                  </Col>
                                </FormGroup>
                              </AvForm>
                            </Col>
                          }
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              <button 
                                className="btn btn-primary btn-block"
                                onClick={() => this.insertData(this.state.evaluacion)}
                              >
                              Insertar datos
                              </button> 
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mt-3 d-grid">
                              {this.state.mensaje_exito && <Alert color="success" >{this.state.mensaje_exito}</Alert>}
                            </div>
                            <div className="mt-3 d-grid">
                              {this.state.mensaje_error && <Alert color="danger" >{this.state.mensaje_error}</Alert>}
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default PacientesDetalle