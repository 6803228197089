import React, { Component } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap"
import { API_BASE_URL } from "helpers/url_helper"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import MetaTags from 'react-meta-tags';
import axios from "axios"

class CambiarContrasena extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  cambiarContrasena(){
    this.setState({ errorMessage: null });
    this.setState({ successMessage: null });
    
    const data = {
      old_password: this.state.pass1,
      new_password: this.state.pass2,
      new_password_repeat: this.state.pass3
    };
    console.log("pass data: ",data);
  
    const token = JSON.parse(localStorage.getItem("token"))
    axios.post(API_BASE_URL+`user/password/change`, {data}, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data);
        this.setState({ successMessage: res.data.message });
      })
      .catch(error => {
        console.log("ERROR: ",error.response.data);
        if (error.response.data.errors) {
          for (var i = 0; i < error.response.data.errors.length; i++) {
            if (error.response.data.errors[i]["data.old_password"]) {
              this.setState({errorMessage: "Password antigua: "+error.response.data.errors[i]["data.old_password"]})
            }
            if (error.response.data.errors[i]["data.new_password"]) {
              this.setState({errorMessage: "Password nueva: "+error.response.data.errors[i]["data.new_password"]})
            }
            if (error.response.data.errors[i]["data.new_password_repeat"]) {
              this.setState({errorMessage: "Confirmación password nueva: "+error.response.data.errors[i]["data.new_password_repeat"]})
            }
          }
        }
        if (error.response.data.message) {
          this.setState({errorMessage: error.response.data.message })
        }
      });
  }

  handleChangePass1 = event => {
    this.setState({ pass1: event.target.value });
    console.log("password1 ",this.state.pass1);
  }
  handleChangePass2 = event => {
    this.setState({ pass2: event.target.value });
    console.log("password2 ",this.state.pass2);
  }
  handleChangePass3 = event => {
    this.setState({ pass3: event.target.value });
    console.log("password3 ",this.state.pass3);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Perfil / Cambiar contraseña</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Perfil / Cambiar contraseña" breadcrumbItem="Perfil / Cambiar contraseña" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <div className="mb-3">
                        <AvField
                          name="password1"
                          label="Password antigua"
                          type="password"
                          required
                          placeholder="Ingrese antigua password"
                          errorMessage="Ingrese antigua password"
                          onChange={this.handleChangePass1}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password2"
                          label="Password nueva"
                          type="password"
                          required
                          placeholder="Ingrese nueva password"
                          errorMessage="Ingrese nueva password"
                          onChange={this.handleChangePass2}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password3"
                          label="Confirmación password nueva"
                          type="password"
                          required
                          placeholder="Confirme nueva password"
                          errorMessage="Confirme nueva password"
                          onChange={this.handleChangePass3}
                        />
                      </div>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          <button 
                            className="btn btn-primary btn-block"
                            onClick={() => this.cambiarContrasena()}
                          >
                          Cambiar contraseña
                          </button> 
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.errorMessage && <Alert color="danger" >{this.state.errorMessage}</Alert>}
                          {this.state.successMessage && <Alert color="success" >{this.state.successMessage}</Alert>}
                        </div>
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default CambiarContrasena
