import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  InputGroup,
  Button,
  Alert
} from "reactstrap"
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class ReportesCesfam extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      cesfams: [],
      sizePerPage: 10,
      mensaje_error: null,
      showGraph: false,
    }
  }

  listarCesfams(){
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`cesfam`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
      //console.log("res.data: ",res.data.data);
        this.setState({cesfams: res.data.data});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  linkAcciones = (cell, row, rowIndex, formatExtraData) => {
    var id = row.id;
    return (
      <Col>
        <Button id={"botonId_"+id} className="btn btn-success"
          onClick={() => {
            this.verEstadistica(id);
          }}
        >
          Ver estadística
        </Button>
      </Col>
    )
  };

  verEstadistica(cesfam_id){
    if(cesfam_id){
      this.setState({ cesfam_id })
      this.setState({ mensaje_error: null }) 
      const token = JSON.parse(localStorage.getItem("token"))
      axios.get(API_BASE_URL+`statistic/cesfam/${cesfam_id}/${this.state.ini_date}/${this.state.end_date}`, {headers: {Authorization: `Bearer ${token}`}})
        .then(res => {
          //total_assistance
          //average_total_time
          console.log("res.data.data:", res.data.data)
          if(res.data.data.average_total_time==null){
            this.setState({ average_total_time: 0 });
          }else{
            this.setState({ average_total_time: Math.round(res.data.data.average_total_time/60) });
          }
          this.setState({ total_assistance: res.data.data.total_assistance });
          this.setState({ showGraph: true });
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ",error.response.data); 
            this.setState({ mensaje_error: error.response.data.message }) 
          }
        });
    }
  }
  
  handleChangeIniDate = event => { 
    this.setState({ ini_date: event.ini_date })
    this.verEstadistica(this.state.cesfam_id)
  }
  handleChangeEndDate = event => { 
    this.setState({ end_date: event.end_date })
    this.verEstadistica(this.state.cesfam_id)
  }

  componentDidMount(){
    this.listarCesfams();
    this.setState({ ini_date: "2021-01-01" })
    var fecha = new Date();
    var ano = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    var hasta = ano+"-"+mes+"-"+dia;
    this.setState({ end_date: hasta })
  }

  render() {

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }, {
      dataField: 'name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'Cesfam_BelongsTo_Commune.name',
      text: 'Comuna',
      sort: true
    }, {
      dataField: 'acciones',
      text: 'Acciones',
      formatter: this.linkAcciones,
      sort: false
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Reportes / Cesfam</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Reportes / Cesfam" breadcrumbItem="Reportes / Cesfam" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4>Lista profesores</h4><hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.cesfams}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={this.state.cesfams}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={"Buscar"}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                    <br></br>
                    <h4>Filtro fechas</h4><hr></hr>
                    <FormGroup className="row mb-1">
                      <Label className="col-sm-2 col-form-label">
                        Fecha inicio 
                      </Label>
                      <Col className="col-sm-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Ingrese fecha"
                            onChange={(date, ini_date) => {this.handleChangeIniDate({ini_date})}}
                            options={{
                              dateFormat: "Y-m-d",
                              allowInput: true,
                              locale: {
                                ...Spanish
                              }
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-calendar-outline" />
                            </span>
                          </div>
                        </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row mb-1">
                      <Label className="col-sm-2 col-form-label">
                        Fecha término
                      </Label>
                      <Col className="col-sm-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Ingrese fecha"
                            onChange={(date, end_date) => {this.handleChangeEndDate({end_date})}}
                            options={{
                              dateFormat: "Y-m-d",
                              allowInput: true,
                              locale: {
                                ...Spanish
                              }
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-calendar-outline" />
                            </span>
                          </div>
                        </InputGroup>
                        </Col>
                    </FormGroup>
                    <Col className="col-12">
                        {this.state.mensaje_error ? 
                          <div className="mt-3 d-grid">
                            <Alert color="danger" >{this.state.mensaje_error}</Alert>
                          </div>
                          :
                          this.state.showGraph &&
                            <div>
                              <br></br>
                              <h4>Estadística</h4><hr></hr>
                              <Col className="col-12">
                                <FormGroup className="row mb-3">
                                  <Label className="col-sm-12 col-form-label">
                                    Promedio tiempo de permanencia de pacientes en sus clases: {this.state.average_total_time} minutos
                                  </Label>
                                  <Label className="col-sm-12 col-form-label mb-1">
                                    Asistencia a sus clases: {this.state.total_assistance}%
                                  </Label>
                                </FormGroup>
                              </Col>
                            </div>
                        }
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ReportesCesfam