import React from "react"
import PropTypes from 'prop-types'
import { Col, Modal, ModalBody, Row } from "reactstrap"
import cara1 from "../../assets/images/Asset_1.svg"
import cara2 from "../../assets/images/Asset_2.svg"
import cara3 from "../../assets/images/Asset_3.svg"
import cara4 from "../../assets/images/Asset_4.svg"
import cara5 from "../../assets/images/Asset_5.svg"

const EvaluateModal = ({ show, onEvaluateClick, onCloseClick, onValueChangeBorg, onValueChangeAnimo }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col className="col-12">
            <div className="mt-4">
              <h5 className="font-size-14 mb-4">¿Como valorarías la percepción del esfuerzo durante la clase?</h5>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(230, 230, 230)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="0"
                  />
                  <label className="form-check-label">
                    0 - Reposo total
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(230, 230, 230)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="1"
                  />
                  <label className="form-check-label">
                    1 - Esfuerzo muy suave
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(230, 230, 230)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="2"
                  />
                  <label className="form-check-label">
                    2 - Suave
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(255, 253, 56)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="3"
                  />
                  <label className="form-check-label">
                    3 - Esfuerzo moderado
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(255, 253, 56)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="4"
                  />
                  <label className="form-check-label">
                    4 - Un poco duro
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(253, 216, 111)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="5"
                  />
                  <label className="form-check-label">
                    5 - Duro
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(253, 216, 111)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="6"
                  />
                  <label className="form-check-label">
                    6 - Duro
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(254, 191, 45)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="7"
                  />
                  <label className="form-check-label">
                    7 - Muy duro
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(254, 191, 45)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="8"
                  />
                  <label className="form-check-label">
                    8 - Muy duro
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(254, 191, 45)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="9"
                  />
                  <label className="form-check-label">
                    9 - Muy duro
                  </label>
                </div>
                <div className="form-check mb-1" style={{backgroundColor: "rgb(251, 13, 27)"}} >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    onChange={onValueChangeBorg}
                    value="10"
                  />
                  <label className="form-check-label">
                    10 - Esfuerzo máximo
                  </label>
                </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
              <div className="mt-4">
                <h5 className="font-size-14 mb-4">¿Cual es el estado de ánimo, luego de la clase?</h5>
                <div className="form-check mb-1" >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radiosAnimo"
                    onChange={onValueChangeAnimo}
                    value="1"
                  />
                  <img
                    src={cara1}
                    alt=""
                    height="34"
                  />
                </div>
                <div className="form-check mb-1" >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radiosAnimo"
                    onChange={onValueChangeAnimo}
                    value="2"
                  />
                  <img
                    src={cara2}
                    alt=""
                    height="34"
                  />
                </div>
                <div className="form-check mb-1" >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radiosAnimo"
                    onChange={onValueChangeAnimo}
                    value="3"
                  />
                  <img
                    src={cara3}
                    alt=""
                    height="34"
                  />
                </div>
                <div className="form-check mb-1" >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radiosAnimo"
                    onChange={onValueChangeAnimo}
                    value="4"
                  />
                  <img
                    src={cara4}
                    alt=""
                    height="34"
                  />
                </div>
                <div className="form-check mb-1" >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radiosAnimo"
                    onChange={onValueChangeAnimo}
                    value="5"
                  />
                  <img
                    src={cara5}
                    alt=""
                    height="34"
                  />
                </div>
              </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-lg me-2"
                onClick={onEvaluateClick}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-lg me-2"
                onClick={onCloseClick}
              >
                Cancelar
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

EvaluateModal.propTypes = {
  onCloseClick: PropTypes.func,
  onEvaluateClick: PropTypes.func,
  onValueChangeBorg: PropTypes.func,
  onValueChangeAnimo: PropTypes.func,
  show: PropTypes.any
}

export default EvaluateModal
