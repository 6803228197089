import React, { Component } from "react"
import PropTypes from 'prop-types'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"

// import images
//import profile from "../../assets/images/profile-img.png"
import lightlogo from "../../assets/images/logo-light.svg"
//import logo from "../../assets/images/logo.svg"
import logo from "../../assets/images/personalizadas/logo_healt.png"

import logo_apple from "../../assets/images/logo_apple.svg"
import logo_android from "../../assets/images/logo_android.svg"

class Soporte extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  handleChangeName = event => {
    this.setState({ name: event.target.value });
    console.log("name ",this.state.name);
  }
  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
    console.log("email ",this.state.email);
  }
  handleChangePhone = event => {
    this.setState({ phone: event.target.value });
    console.log("phone ",this.state.phone);
  }
  handleChangeComment = event => {
    this.setState({ comment: event.target.value });
    console.log("comment ",this.state.comment);
  }

  handleValidSubmit(event, values) {
    event.preventDefault();

    this.setState({errorMessage: null})
    this.setState({successMessage: null})

    const data = {
      email: this.state.email,
      phone: this.state.phone,
      name: this.state.name,
      comment: this.state.comment
    };
    console.log("contacts ",data);
    
    axios.post(API_BASE_URL+`contacts`, {data})
      .then(res => {
        console.log("res.data contacts: ",res.data)
        this.setState({successMessage: "Mensaje enviado exitosamente. Nos pondremos en contacto con usted!"})
      })
      .catch(error => {
        if (error.response) {
          for (var i = 0; i < error.response.data.errors.length; i++) {
            if (error.response.data.errors[i]["data.name"]) {
              this.setState({errorMessage: error.response.data.errors[i]["data.name"]})
            }
            if (error.response.data.errors[i]["data.phone"]) {
              this.setState({errorMessage: error.response.data.errors[i]["data.phone"]})
            }
            if (error.response.data.errors[i]["data.comment"]) {
              this.setState({errorMessage: error.response.data.errors[i]["data.comment"]})
            }
            if (error.response.data.errors[i]["data.email"]) {
              this.setState({errorMessage: error.response.data.errors[i]["data.email"]})
            }
          }
        }
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden" id="login_fondo">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-5">
                          <h5 className="text-bienvenida">Soporte</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-1">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="72"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <p>Si necesitas ayuda o tienes algun problema con la plataforma, escríbenos y te contactaremos</p>
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.state.errorMessage && <Alert color="danger" >{this.state.errorMessage}</Alert>}
                        {this.state.successMessage && <Alert color="success" >{this.state.successMessage}</Alert>}
                        <div className="mb-3">
                          <AvField
                            name="name"
                            className="form-control"
                            type="text"
                            required
                            placeholder="Igrese nombre"
                            errorMessage="Ingrese nombre"
                            onChange={this.handleChangeName}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            className="form-control"
                            type="email"
                            required
                            placeholder="Igrese email"
                            errorMessage="Ingrese email"
                            onChange={this.handleChangeEmail}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="phone"
                            className="form-control"
                            type="number"
                            required
                            placeholder="Igrese teléfono"
                            errorMessage="Ingrese teléfono"
                            onChange={this.handleChangePhone}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="comment"
                            className="form-control"
                            type="textarea"
                            required
                            placeholder="Igrese comentario"
                            errorMessage="Ingrese comentario"
                            onChange={this.handleChangeComment}
                          />
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Enviar
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    <a href="https://play.google.com/store/apps/details?id=cl.apptivate.app" target="_blank"><img src={logo_android} /></a>
                    <a href="https://apps.apple.com/us/app/app-tivate/id1571535977" target="_blank"><img src={logo_apple} /></a>
                  </p>
                  <p>
                    Necesitas ayuda? 
                    <Link to="/soporte">
                      &nbsp;Soporte
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} <a href="https://www.usercode.cl" target="_blank">UserCode SpA</a> todos los derechos reservados.
                  </p>          
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Soporte
