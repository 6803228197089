import React, { Component } from "react"
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"
import MetaTags from 'react-meta-tags';
import {
  Col,
  Container,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import logo_apple from "../../assets/images/logo_apple.svg"
import logo_android from "../../assets/images/logo_android.svg"

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      informedconsent: false
    };
  }

  componentDidMount(){
  }
  
  render() {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const token = JSON.parse(localStorage.getItem("token"))
    var comuna_name = JSON.parse(localStorage.getItem("comunaName"))
    var cesfam_name = JSON.parse(localStorage.getItem("cesfamName"))
    console.log("Ini:", obj)
    console.log("ROL: ", obj.rol_id)
    console.log("Token: ", token)
    console.log("comuna_name: ", comuna_name)
    console.log("cesfam_name: ", cesfam_name)

    const userId = JSON.parse(localStorage.getItem("userId"))
    console.log("userId: ", userId)

    if(obj.rol_id==3){
      return (
        <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Inicio</title>
            </MetaTags>
            <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Inicio" breadcrumbItem="Inicio" />
                <Row>
                    <Col className="col-12"><h1>Hola Admin</h1></Col>
                    <Col className="col-12"><br></br></Col>
                    <Col className="col-12"><h5>Nombe: {obj.name}</h5></Col>
                    <Col className="col-12"><h5>Apellido: {obj.lastname}</h5></Col>
                </Row>
            </Container>
            </div>
        </React.Fragment>
      )
    }
    else{
      if(obj.rol_id==2){
        return (
          <React.Fragment>
              <div className="page-content">
              <MetaTags>
                  <title>Inicio</title>
              </MetaTags>
              <Container fluid>
                  {/* Render Breadcrumbs */}
                  <Breadcrumbs title="Inicio" breadcrumbItem="Inicio" />
                  <Row>
                      <Col className="col-12"><h1>Hola Operador</h1></Col>
                      <Col className="col-12"><br></br></Col>
                      <Col className="col-12"><h5>Nombe: {obj.name}</h5></Col>
                      <Col className="col-12"><h5>Apellido: {obj.lastname}</h5></Col>
                      <Col className="col-12"><h5>Comuna: {comuna_name}</h5></Col>
                      <Col className="col-12"><h5>Cesfam: {cesfam_name}</h5></Col>
                  </Row>
              </Container>
              </div>
          </React.Fragment>
        )
      }
      else{
        return (
          <React.Fragment>
              <div className="page-content">
              <MetaTags>
                  <title>Inicio</title>
              </MetaTags>
              <Container fluid>
                  {/* Render Breadcrumbs */}
                  <Breadcrumbs title="Inicio" breadcrumbItem="Inicio" />

                  <Row>
                      <Col className="col-12"><h1>Hola Usuario</h1></Col>
                      <Col className="col-12"><br></br></Col>
                      <Col className="col-12"><h5>Nombe: {obj.name}</h5></Col>
                      <Col className="col-12"><h5>Apellido: {obj.lastname}</h5></Col>
                      <Col className="col-12"><h5>Comuna: {comuna_name}</h5></Col>
                      <Col className="col-12"><h5>Cesfam: {cesfam_name}</h5></Col>
                      <hr></hr>
                      <Col className="col-12"><h5>Recuerda que puedes descargar la App en tu celular</h5></Col>
                      <p>
                        <a href="https://play.google.com/store/apps/details?id=cl.apptivate.app" target="_blank"><img src={logo_android} /></a>
                        <a href="https://apps.apple.com/us/app/app-tivate/id1571535977" target="_blank"><img src={logo_apple} /></a>
                      </p>
                  </Row>
              </Container>
              </div>
          </React.Fragment>
        )
      }
    }
  }
}

export default Inicio