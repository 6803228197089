import React, { Component } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from "reactstrap"


   

class TerminosCondiciones extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <MetaTags>
            <title>Términos y Condiciones</title>
          </MetaTags>
          <Container>
            <Row>
              <Col lg="12">
                <div className=" mb-5">
                  <h1 className="text-center">
                    Términos y Condiciones
                  </h1>
                  <h2 className="text-center">
                    TÉRMINO Y CONDICIONES DE APPTIVATE
                  </h2>
                  <p>
                    La lectura de estos Términos y Condiciones Generales es indispensable para que el USUARIO pueda disfrutar adecuadamente de los servicios ofrecidos por la aplicación Apptivate, así como para la exacta comprensión y uso de la misma. Por lo tanto, el contenido de este documento se debe leer con atención antes de registrarse en la aplicación y/o utilizar los servicios.
                  </p>
                  <h2 className="text-center">
                    Aceptación de los Términos y Condiciones Generales
                  </h2>
                  <div className="text-justify">
                  <p className="text-justify">
                    Lea atentamente estas condiciones generales de acceso y utilización. Definen las condiciones y restricciones de utilización que usted acepta cuando descarga, acceda y utiliza la aplicación APPTIVATE, incluyendo las limitaciones de garantía y responsabilidad.
                    <br></br>
                    Usted reconoce que estas condiciones generales son efectivas del mismo modo que cualquier otro contrato escrito y que se compromete a respetarlos. En su defecto, no esta autorizado a descargar y utilizar la aplicación móvil APPTIVATE. 
                    <br></br>
                    Este contrato describe los términos y condiciones generales (en adelante los "Términos y Condiciones”) aplicables al uso de los servicios ofrecidos dentro de la aplicación móvil “Apptivate” del Centro de Vida Saludable de la Universidad de Concepción, con domicilio en Concepción, Chile, RUT: 81494400-k (en adelante el "aplicación movil" o ”Apptivate”).
                  </p>
                  <h3>
                    Sección 1: Términos Generales
                  </h3>
                    <b>1.1 Servicios </b><br></br>
                    Apptivate es una aplicación móvil que permite a los USUARIOS la realización de sesiones de ejercicio físico en su centro de salud de manera presencial o virtual durante un periodo establecido.

                    <ol style={{ listStyle: "none" }}>
                      <li>
                        <b>1.1 Usuario: </b><br></br> Cualquier persona que desee acceder y/o usar la aplicación móvil APPTIVATE o los Servicios (en adelante "Usuario" o en plural "Usuarios") podrá hacerlo sujetándose a los Términos y Condiciones, junto con todas las demás políticas que son incorporados al presente documento por referencia. 
                      </li>
                    </ol>
                    <b>1.2 Uso de nuestros servicios </b><br></br>
                   <ol style={{ listStyle: "none" }}>
                      <li>
                        <b>1.2.1 Registro: </b><br></br>
                        <p className="text-justify">
                          El Usuario debe tener al menos 18 años de edad para acceder y utilizar la aplicación móvil o utilizar cualquiera de los Servicios. Deberá completar el formulario de registro con datos verdaderos, exactos y precisos ("Datos Personales") para poder utilizar Los Servicios y tiene la obligación de actualizar los Datos Personales cuando sea necesario.
                          Al utilizar la aplicación móvil, el USUARIO declara y garantiza que cumple con todos los requisitos anteriores. APPTIVATE no garantiza, ni se responsabiliza por la certeza de los Datos Personales provistos por sus Usuarios.
                          APPTIVATE se reserva el derecho de verificar la información proporcionada por otros medios o solicitarle que acompañe documentación para verificar los datos proporcionados (ejemplo: pasaporte, cédula de identidad, declaración jurada, certificados, etc.).
                          También, APPTIVATE se reserva el derecho a suspender temporal o definitivamente a aquellos USUARIOS cuyos datos den lugar a dudas y no hayan podido ser confirmados. En estos casos de inhabilitación, se podrá dar de baja todos los Avisos publicados, sin que ello genere algún derecho a resarcimiento. 
                        </p>
                      </li>
                      <li>
                        <b>1.2.2 Cuenta Personal: </b><br></br>
                        <p className="text-justify">
                          El USUARIO accede a su Cuenta Personal mediante el ingreso de su dirección de correo electrónico y clave de seguridad personal elegida ("Clave de Seguridad").
                          El USUARIO tiene la responsabilidad de mantener la confidencialidad de su clave de seguridad.
                          El USUARIO es el responsable por todas las operaciones efectuadas con su Cuenta Personal siendo esta única e intransferible, y está prohibido que un mismo Usuario registre o posea más de una Cuenta. 
                          En caso que APPTIVATE detecte que un USUARIO posee más de una cuenta conteniendo datos relacionados o que coincidan, podrá cancelar, suspender o inhabilitarlas. 
                          El USUARIO se obliga y compromete a avisar a APPTIVATE inmediatamente y por un medio idóneo y fehaciente, cualquier uso anómalo, no autorizado o que cause duda su procedencia.
                          APPTIVATE se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar cualquier registro de cuenta personal aceptada o en proceso de revisión o en cualquier estado que esta se encuentre, sin que ello genere algún derecho a indemnización o resarcimiento. 
                        </p>
                      </li>
                      <li>
                        <b>1.2.3 Los Servicios ofrecidos: </b><br></br>
                        <p className="text-justify">
                         El Usuario registrado debe ser usuario del Centro de Salud Familiar al cual se inscribió y este será autorizado por su profesor del programa correspondiente. El usuario podrá a través de la plataforma ingresar a su clase de ejercicio físico y en caso de no poder realizar la actividad dentro del horario estipulado, podrá ver la clase durante las 24 horas posterior a dicha programación. Además, dentro de la plataforma tendrá la opción de participar de encuestas con el fin de informar sobre su estado de salud actual, percepción del esfuerzo físico, salud mental, entre otros.
                          Es responsabilidad del Usuario que la información sea veraz y fidedigna. Apptivate no respalda, no selecciona ni controla las actividades publicadas a través de la aplicación móvil, ni las personas que participan en las actividades. Tampoco conoce ni tiene responsabilidad por el contenido cargado, sus actualizaciones o encuestas que puedan efectuarse en la aplicación móvil. En ningún caso será responsable de las actividades, programas y servicios, siendo exclusiva responsabilidad de los profesionales de cada establecimiento. 

                        </p>
                      </li>
                      <li>
                        <b>1.2.4 Usuarios: </b><br></br>
                        <p className="text-justify">
                         Apptivate es una aplicación móvil que ofrece la posibilidad de realizar las clases de ejercicio físico de los centros de salud familiar de manera remota a los Usuarios de cada programa. Los usuarios de Apptivate tienen la posibilidad de navegar, consultar, responder encuestas y revisar la información que este a disposición dentro de la aplicación. Los Usuarios se responsabilizan de la utilización de la plataforma de forma adecuada. Apptivate no conoce, corrobora ni controla los datos personales ofrecidos por los usuarios. 
                        </p>
                      </li>
                    </ol>
                    <h3>
                      Sección 2: Modificaciones del Acuerdo
                    </h3>
                   <ol style={{ listStyle: "none" }}>
                      <li><p>2.1 Apptivate podrá modificar en cualquier momento los acuerdos que conforman los Términos y Condiciones.</p></li>
                      <li><p>2.2 Los acuerdos entrarán en vigencia a partir del momento mismo que sean publicados en la Plataforma Web o desde la notificación al Usuario por cualquier medio, lo que ocurra primero.
                      </p></li>
                      <li><p>2.3 Los Usuarios que no estén de acuerdo con las modificaciones de los Términos y Condiciones podrán dar de baja su cuenta. 
                      </p></li>
                    </ol>
                    <h3>
                      Sección 3: Responsabilidad
                    </h3>
                    <b>3.1 Principio general</b><br></br>
                   <ol style={{ listStyle: "none" }}>
                      <li><p>3.1.1 Apptivate no es proveedor de los servicios ofrecidos. Apptivate pone a disposición de los Usuarios una plataforma virtual que les permite encontrar una forma de realizar sesiones de ejercicio físico de los programas de sus respectivos centros de salud de forma remota.
</p></li>
                      <li><p>3.1.2 Apptivate no interviene en las condiciones de los servicios estipulados por los Usuarios Anfitriones (profesionales de los centros de salud familiar), por ello no se hará responsable de la calidad, legitimidad y servicios ofrecidos, transferidos o adquiridos por los Usuarios, así como de la capacidad para ejecutar estas actividades por parte de los Usuarios o de la veracidad de los Datos Personales u otra información por ellos ingresados.
                      </p></li>
                      <li><p>3.1.3 El Usuario conoce y acepta que al realizar los servicios con otros Usuarios o terceros lo hace bajo su propio riesgo. En ningún caso Apptivate será responsable por cualquier tipo de daño que haya podido sufrir el Usuario, debido a las actividades realizadas o no realizadas a través de la Plataforma Web o por Apptivate. En caso que uno o más Usuarios, o algún tercero, inicien cualquier tipo de reclamo o acciones legales contra otro u otros Usuarios, todos y cada uno de los Usuarios involucrados en dichos reclamos o acciones liberan de toda responsabilidad a Apptivate y a sus directores, empleados, representantes, y profesionales. 
                      </p></li>
                    </ol>
                    <b>3.2 Responsabilidad por el contenido de los servicios </b><br></br>
                    <p>Apptivate no asume responsabilidad alguna por la calidad, veracidad, exactitud, características y otros elementos de los servicios publicados en la Plataforma Web. Apptivate tampoco garantiza la actualización de la información y desarrollo de los contenidos en las clases, encuestas o servicio elaborado por los Usuarios Anfitriones. En todos los casos aquel Usuario fuese o sintiere agraviado o sufriere un perjuicio debido a alguna información o dicho en la Plataforma Web o por sus redes sociales, deberá dirigir sus acciones o reclamos exclusivamente contra el Usuario responsable de dicha información, renunciando expresamente a intentar cualquier tipo de acción o reclamo contra Apptivate. 
                      <br></br>
                      En caso de existir cualquier acción legal o reclamo entre los Usuarios, estos eximen a Apptivate y a sus empleados o representantes de toda responsabilidad y lo mantendrán indemne de todo costo en el que éste deba incurrir por causa o en relación con su intervención, en cualquier calidad que le sea solicitada, en cualquier proceso legal o administrativo en el que debiere participar en conexión con dicho reclamo o acción legal. En consecuencia, los Usuarios renuncian expresamente a realizar cualquier tipo de reclamo o acción judicial contra Apptivate, que tenga origen en, o relación con, las vinculaciones contractuales o extracontractuales que entre ellos se generen en virtud del uso del Servicio.
                    </p>
                    <h3>
                     Sección 4: Alcance de los servicios de Apptivate
                    </h3>
                    <p>
                      Este acuerdo no crea ningún contrato de prestación de servicios, mandato, o relación laboral entre Apptivate y los Usuarios. El Usuario entiende y acepta que Apptivate no es parte de ninguna de las actividades celebradas entre los Usuarios, ni tiene control alguno sobre la seguridad, legalidad, veracidad, legitimidad o calidad de los servicios anunciados o ofrecidos. Apptivate no puede asegurar que un Usuario anfitrión cumplirá debidamente una operación ni podrá garantizar la identidad o datos personales ingresados por los Usuarios. Apptivate no será responsable por cualquier contrato, servicio o acuerdo que el Usuario celebre con dichos terceros o con otros Usuarios. 
                   </p>
                    <h3>
                     Sección 5: Indemnidad
                    </h3>
                   <ol style={{ listStyle: "none" }}>
                      <li><p>
                        5.1. El Usuario indemnizará y mantendrá indemne a Apptivate, sus directivos, administradores, representantes y empleados, por cualquier reclamo o demanda de otros Usuarios o terceros por sus actividades en la Plataforma Web o por su incumplimiento respecto de los Términos y Condiciones y/o cualquier modificación al mismo documento y/o demás documentos y/o anexos y/o secciones que se entienden incorporadas al presente o por la violación de cualesquiera leyes o derechos de terceros, incluyendo los honorarios de sus abogados. 
                      </p></li>
                      </ol>
                    <h3>
                    Sección 6: Fallas en el sistema 
                    </h3>
                   <ol style={{ listStyle: "none" }}>
                      <li><p>
                        6.1 Apptivate no garantiza el acceso y uso continuado o ininterrumpido de su Plataforma Web. El Usuario acepta y entiende que el sistema puede no estar disponible por fallas de Internet, fallas de servidor, dificultades técnicas, o por cualquier otra circunstancia. 
                      </p></li>
                      <li><p>
                        6.2 El uso de la Plataforma Web es a riesgo exclusivo de los propios Usuarios, voluntariamente asumido por éstos. En base de esto, Apptivate no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o cualquier otra causa asociada al uso de Internet, incluyendo cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso o uso de la Plataforma Web o cualquier transferencia de contenidos en el mismo. Los Usuarios renuncian a imputar responsabilidad alguna a Apptivate, exigir pago, pérdidas por lucro cesante o cualquier perjuicio causado por dificultades técnicas o fallas en los sistemas o en Internet. 
                      </p></li>
                      </ol>
                    <h3>
                    Sección 7: Propiedad Intelectual 
                    </h3>
                   <ol style={{ listStyle: "none" }}>
                      <li><p>
7.1 Todos los derechos del presente Plataforma Web están reservados y corresponden a Apptivate. El contenido del presente Plataforma Web, incluyendo y no limitado a los logos, imágenes/fotografías, gráficos, textos y todo el diseño en general. El base de dato y software están protegidos como derecho de autor y su titularidad corresponde al Centro de Vida Saludable de la Universidad de Concepción, y se encuentra protegido por la ley de propiedad intelectual, Ley 11.723. Queda totalmente prohibida el uso de cualquiera de estos contenidos por cualquier motivo, salvo previo y expreso autorización de Apptivate.                       </p></li>
                      <li><p>
7.2 Si el Usuario considera que la Plataforma Web viola o atenta de algún modo contra derechos de propiedad intelectual de terceros, deberá notificarlo a vidasaludable@udec.cl, acompañando toda la información necesaria para respaldarlo.
                      </p></li>
                      </ol>
                    <h3>
                   Sección 8: Domicilio, Jurisdicción y Ley Aplicable

                    </h3>
                   <ol style={{ listStyle: "none" }}>
                      <li><p>
8.1 Para todos los efectos legales del presente términos y condiciones, las partes fijan domicilio en la ciudad de Concepción y se someten a la jurisdicción de sus Tribunales de Justicia. En el caso que haya cualquier diferencia, desacuerdo o conflicto derivado de la interpretación, validez, alcance y/o aplicación de los presentes términos y condiciones, los usuarios podrán comunicarse con Apptivate al siguiente correo electrónico: vidasaludable@udec.cl. Todas las comunicaciones y notificaciones serán efectuadas al correo indicado. 
                      </p></li>
                        <li><p>
8.2. El nombre “Apptivate” es del Centro de Vida Saludable de la Universidda de Concepción.  El domicilio es: Victoria 580, barrio universitario, ciudad de Concepción, de la Region del Bio Bio.
                      </p></li>
                      </ol>
                  </div>
                  <div className="mt-5 text-center">
                    <Link
                      className="btn btn-primary"
                      to="/"
                    >
                      Volver
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default TerminosCondiciones
