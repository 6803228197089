import React, { Component } from "react"
import axios from "axios"
import MetaTags from 'react-meta-tags';
import Flatpickr from "react-flatpickr"
import BootstrapTable from 'react-bootstrap-table-next';
import { API_BASE_URL } from "helpers/url_helper"

// datatable related plugins
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  InputGroup,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class CesfamsDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      clases: [
        { "id": 1, "name": "Baile entretenido", "date": "20/05/2020"},
        { "id": 2, "name": "Trabajo core", "date": "28/06/2021"},
        { "id": 3, "name": "Sentadillas", "date": "12/01/2021"},
        { "id": 4, "name": "TRX", "date": "12/12/2020"},
        { "id": 5, "name": "Salsa", "date": "02/10/2020"},
      ],
      sizePerPage: 10,
    }
  }

  componentDidMount(){
    document.getElementById("link_pacientes").style.color = "white";
    document.getElementById("icono_pacientes").style.color = "white";
    console.log("patient_id: ", this.props.match.params.id);
    const patient_id = this.props.match.params.id
    const token = JSON.parse(localStorage.getItem("token"))
    axios.get(API_BASE_URL+`patient/${patient_id}`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        if(res.data.data.name!=null){
          this.setState({nombre: res.data.data.name});
        }
        if(res.data.data.lastname!=null){
          this.setState({apellido: res.data.data.lastname});
        }
        if(res.data.data.rut!=null){
          this.setState({rut: res.data.data.rut});
        }
        if(res.data.data.User_Has_Patient[0].address!=null){
          this.setState({direccion: res.data.data.User_Has_Patient[0].address});
        }
        if(res.data.data.email!=null){
          this.setState({email: res.data.data.email});
        }
        if(res.data.data.User_Has_Patient[0].telephone!=null){
          this.setState({telefono: res.data.data.User_Has_Patient[0].telephone});
        }
        if(res.data.data.User_Has_Patient[0].dateofbirth!=null){
          this.setState({fechanacimiento: res.data.data.User_Has_Patient[0].dateofbirth});
        }
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  render() {

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: false
    }, {
      dataField: 'name',
      text: 'Nombre clase',
      sort: true
    }, {
      dataField: 'date',
      text: 'Fecha realizada',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      //totalSize: usuarios.length, // replace later with size(customers),
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pacientes / Detalle</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Pacientes / Detalle" breadcrumbItem="Pacientes / Detalle" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4>Datos personales</h4><hr></hr>
                    <AvForm className="needs-validation">
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientenombre"
                          className="col-sm-2 col-form-label"
                        >
                          Nombre
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="nombre"
                            disabled={true}
                            value={this.state.nombre}
                            type="text"
                            className="form-control"
                            id="paciente_nombre"
                          />
                        </Col>
                        
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteapellido"
                          className="col-sm-2 col-form-label"
                        >
                          Apellido
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="apellido"
                            disabled={true}
                            value={this.state.apellido}
                            type="text"
                            className="form-control"
                            id="paciente_apellido"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienterut"
                          className="col-sm-2 col-form-label"
                        >
                          RUT
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="rut"
                            disabled={true}
                            value={this.state.rut}
                            type="text"
                            className="form-control"
                            id="paciente_rut"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientedireccion"
                          className="col-sm-2 col-form-label"
                        >
                          Direccion
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="direccion"
                            disabled={true}
                            value={this.state.direccion}
                            type="text"
                            className="form-control"
                            id="paciente_direccion"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacienteemail"
                          className="col-sm-2 col-form-label"
                        >
                          Email
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="email"
                            disabled={true}
                            value={this.state.email}
                            type="text"
                            className="form-control"
                            id="paciente_email"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientetelefono"
                          className="col-sm-2 col-form-label"
                        >
                          Teléfono
                        </Label>
                        <Col className="col-sm-10">
                          <AvField
                            name="telefono"
                            disabled={true}
                            value={this.state.telefono}
                            type="text"
                            className="form-control"
                            id="paciente_telefono"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="pacientefechanacimiento"
                          className="col-sm-2 col-form-label"
                        >
                          Fecha nacimiento
                        </Label>
                        <Col className="col-sm-10">
                          <InputGroup>
                            <Flatpickr
                              disabled={true}
                              value={this.state.fechanacimiento}
                              className="form-control d-block"
                              options={{
                                dateFormat: "Y-m-d",
                                allowInput: true
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-calendar-outline" />
                              </span>
                            </div>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    </AvForm>
                    <br></br>
                    <h4>Seguimiento de clases</h4><hr></hr>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.clases}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.clases}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder={"Buscar"}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      //rowEvents={this.eventosRowOnClick} 
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                      <br></br>
                      <h4>Datos de salud</h4><hr></hr>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default CesfamsDetalle