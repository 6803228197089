import React from "react"
import { Redirect } from "react-router-dom"

// Pages Component
import Chat from "../pages/Chat/Chat"

// Pages File Manager
import FileManager from "../pages/FileManager/index"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// User profile
import UserProfile from "../pages/Authentication/UserProfile"

//Tasks
import TasksList from "../pages/Tasks/tasks-list"
import TasksKanban from "../pages/Tasks/tasks-kanban"
import TasksCreate from "../pages/Tasks/tasks-create"

//Projects
import ProjectsGrid from "../pages/Projects/projects-grid"
import ProjectsList from "../pages/Projects/projects-list"
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"
import ProjectsCreate from "../pages/Projects/projects-create"

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailBasicTemplte from "../pages/Email/email-basic-templte"
import EmailAlertTemplte from "../pages/Email/email-template-alert"
import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import RecuperarContrasena from "../pages/Authentication/RecuperarContrasena"
import ResetearContrasena from "../pages/Authentication/ResetearContrasena"
import CambiarContrasena from "../pages/Authentication/CambiarContrasena"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPwd2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-saas/index"
import DashboardCrypto from "../pages/Dashboard-crypto/index"
import DashboardBlog from "../pages/Dashboard-blog/index"

//Crypto
import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet"
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell"
import CryptoExchange from "../pages/Crypto/crypto-exchange"
import CryptoLending from "../pages/Crypto/crypto-lending"
import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application"
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ToastUIChart from "../pages/Charts/ToastUIChart"
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"
import DragDropTables from "../pages/Tables/DragDropTables"
import DualListbox from "../pages/Tables/DualListbox"

// Forms
import FormElements from "../pages/Forms/FormElements/index"
import FormLayouts from "../pages/Forms/FormLayouts"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiDrawer from "../pages/Ui/UiDrawer"
import Breadcrumb from "../pages/Ui/UiBreadcrumb"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

//Blog
import BlogList from "../pages/Blog/BlogList/index"
import BlogGrid from "../pages/Blog/BlogGrid/index"
import BlogDetails from "../pages/Blog/BlogDetails"

//Inicio
import Inicio from "../pages/Inicio/Inicio"
import TerminosCondiciones from "../pages/Inicio/TerminosCondiciones"
import PoliticasPrivacidad from "../pages/Inicio/PoliticasPrivacidad"
import Soporte from "../pages/Inicio/Soporte"

//Operador-Clases
import ClasesHistorial from "../pages/Operador-Clases/ClasesHistorial"
import ClasesCalendario from "../pages/Operador-Clases/ClasesCalendario"
import ClasesTransmitir from "../pages/Operador-Clases/ClasesTransmitir"
//Operador-Pacientes
import PacientesLista from "../pages/Operador-Pacientes/PacientesLista"
import PacientesDetalle from "../pages/Operador-Pacientes/PacientesDetalle"
import PacientesRegistrar from "../pages/Operador-Pacientes/PacientesRegistrar"
import PacientesEditar from "../pages/Operador-Pacientes/PacientesEditar"
import PacientesGrupos from "../pages/Operador-Pacientes/PacientesGrupos"
import PacientesPiloto from "../pages/Operador-Pacientes/PacientesPiloto"

//Operador-Reportes
import ReportesPaciente from "../pages/Operador-Reportes/ReportesPaciente"
import ReportesClase from "../pages/Operador-Reportes/ReportesClase"

//Operador-Solicitudes
import SolicitudesLista from "../pages/Operador-Solicitudes/SolicitudesLista"

//Admin-Cesfam
import CesfamsLista from "../pages/Admin-Cesfams/CesfamsLista"
import CesfamsDetalle from "../pages/Admin-Cesfams/CesfamsDetalle"
import CesfamsCrear from "../pages/Admin-Cesfams/CesfamsCrear"
import CesfamsEditar from "../pages/Admin-Cesfams/CesfamsEditar"
//Admin-Operador
import OperadoresLista from "../pages/Admin-Operadores/OperadoresLista"
import OperadoresDetalle from "../pages/Admin-Operadores/OperadoresDetalle"
import OperadoresCrear from "../pages/Admin-Operadores/OperadoresCrear"
import OperadoresEditar from "../pages/Admin-Operadores/OperadoresEditar"
//Admin-Encuestas
import EncuestasCrear from "../pages/Admin-Encuestas/EncuestasCrear"
import EncuestasLista from "../pages/Admin-Encuestas/EncuestasLista"
import EncuestasEditar from "../pages/Admin-Encuestas/EncuestasEditar"
import EncuestasDetalle from "../pages/Admin-Encuestas/EncuestasDetalle"
//Admin-Reportes
import ReportesProfesor from "../pages/Admin-Reportes/ReportesProfesor"
import ReportesCesfam from "../pages/Admin-Reportes/ReportesCesfam"
import ReportesExportar from "../pages/Admin-Reportes/ReportesExportar"
//Admin-Reportes
import PacientesListaCompleta from "../pages/Admin-Pacientes/PacientesListaCompleta"

//Usuario-Calendario
import CalendarioUser from "../pages/Usuario-Calendario/CalendarioUser"
//Usuario-Tutorial
import TutorialUser from "../pages/Usuario-Tutorial/TutorialUser"
//Usuario-Historial
import HistorialUser from "../pages/Usuario-Historial/HistorialUser"
//Usuario-Perfil
import PerfilUser from "../pages/Usuario-Perfil/PerfilUser"

const authProtectedRoutesOperador = [
  //Clases
  { path: "/clases-historial", component: ClasesHistorial },
  { path: "/clases-calendario", component: ClasesCalendario },
  { path: "/clases-transmitir", component: ClasesTransmitir },
  //Pacientes
  { path: "/pacientes-lista", component: PacientesLista },
  { path: "/pacientes-registrar", component: PacientesRegistrar },
  { path: "/pacientes-editar/:id", component: PacientesEditar },
  { path: "/pacientes-grupos", component: PacientesGrupos },
  { path: "/pacientes-detalle/:id", component: PacientesDetalle },
  { path: "/pacientes-piloto", component: PacientesPiloto },
  //Reportes
  { path: "/reportes-paciente", component: ReportesPaciente },
  { path: "/reportes-clase", component: ReportesClase },
  //Solicitudes
  { path: "/solicitudes-lista", component: SolicitudesLista },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: Inicio },
]

const authProtectedRoutesUsuario = [
  //Historial
  { path: "/historial", component: HistorialUser },
  //Calendario
  { path: "/calendario", component: CalendarioUser },
  //Tutorial
  { path: "/tutorial", component: TutorialUser },
  //Perfil
  { path: "/perfil", component: PerfilUser },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: Inicio },
]

const authProtectedRoutesAdmin = [
  //Cesfams
  { path: "/cesfams-lista", component: CesfamsLista },
  { path: "/cesfams-crear", component: CesfamsCrear },
  { path: "/cesfams-editar/:id", component: CesfamsEditar },
  { path: "/cesfams-detalle/:id", component: CesfamsDetalle },
  //Operadores
  { path: "/operadores-lista", component: OperadoresLista },
  { path: "/operadores-crear", component: OperadoresCrear },
  { path: "/operadores-editar/:id", component: OperadoresEditar },
  { path: "/operadores-detalle/:id", component: OperadoresDetalle },
  //Encuestas
  { path: "/encuestas-crear", component: EncuestasCrear },
  { path: "/encuestas-lista", component: EncuestasLista },
  { path: "/encuestas-editar/:id", component: EncuestasEditar },
  { path: "/encuestas-detalle/:id", component: EncuestasDetalle },
  //Reportes
  { path: "/reportes-profesor", component: ReportesProfesor },
  { path: "/reportes-cesfam", component: ReportesCesfam },
  { path: "/reportes-exportar", component: ReportesExportar },
  //Pacientes
  { path: "/pacientes-lista-completa", component: PacientesListaCompleta },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: Inicio },
]

const authProtectedRoutes = [
  { path: "/inicio", component: Inicio },
  { path: "/profile", component: UserProfile },
  { path: "/cambiar-contrasena", component: CambiarContrasena },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: Inicio },
]

const publicRoutes = [
  { path: "/politicas-privacidad", component: PoliticasPrivacidad },
  { path: "/terminos-condiciones", component: TerminosCondiciones },
  { path: "/soporte", component: Soporte },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/recuperar-contrasena", component: RecuperarContrasena },
  { path: "/reset/:code", component: ResetearContrasena },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },

  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },

  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/pages-recoverpw-2", component: Recoverpw2 },

  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/pages-forgot-pwd-2", component: ForgetPwd2 },

  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export {
  authProtectedRoutesUsuario,
  authProtectedRoutesOperador,
  authProtectedRoutesAdmin,
  authProtectedRoutes,
  publicRoutes,
}
