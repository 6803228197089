import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { API_BASE_URL } from "helpers/url_helper"
import ReactPlayer from "react-player"

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  InputGroup,
  Label,
  Alert,
  Input,
  Table,
  FormGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import axios from "axios"
import EvaluateModal from "./EvaluateModal"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import allLocales from "@fullcalendar/core/locales-all"
//css
import "@fullcalendar/bootstrap/main.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class CalendarioUser extends Component {
  constructor(props) {
    super(props)
    this.calendarComponentRef = React.createRef()
    this.state = {
      modal: false,
      event: {},
      evaluateModal: false,
      timerClase: 0,
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    const beforeUnloadListener = event => {
      if (this.state.started) {
        this.setState({ tryToExit: true })
        event.preventDefault()
        return (event.returnValue = null)
      }
    }

    // window.addEventListener("beforeunload", beforeUnloadListener, {
    //   capture: true,
    // })
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", beforeUnloadListener, {
      capture: true,
    })
  }

  /**
   * Handling the modal state
   */
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  /**
   * Handling click on event on calendar
   */
  handleEventClick = arg => {
    this.toggle()
    this.setState({ started: false })
    //console.log("arg.event: ", arg.event._def.extendedProps)
    this.setState({
      profesor_nombre: arg.event._def.extendedProps.profesor_nombre,
    })
    this.setState({
      profesor_apellido: arg.event._def.extendedProps.profesor_apellido,
    })
    this.setState({ hora: arg.event._def.extendedProps.hora })
    this.setState({ fecha: arg.event._def.extendedProps.fecha })
    this.setState({ nombre: arg.event._def.title })
    this.setState({ descripcion: arg.event._def.extendedProps.descripcion })
    this.setState({ link: arg.event._def.extendedProps.link })
    this.setState({ classdate_id: arg.event._def.extendedProps.classdate_id })

    if (arg.event._def.extendedProps.evaluated > 0) {
      this.setState({ evaluated: true })
    } else {
      this.setState({ evaluated: false })
    }
  }

  contarTiempoClase = () => {
    //var timerClase = this.state.timerClase +1
    this.setState({ timerClase: this.state.timerClase + 1 })
    console.log("TIME: ", this.state.timerClase)
  }

  handleStarted = () => {
    if (this.state.started) {
      console.log("se apreto boton terminar")
      this.setState({ started: false })
      clearInterval(this.intervalID)

      const data = { time: this.state.timerClase }
      const token = JSON.parse(localStorage.getItem("token"))
      axios
        .post(
          API_BASE_URL + `classdate/${this.state.classdate_id}/attendance`,
          { data },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(res => {
          console.log("res.data: ", res.data.data)
          this.setState({ timerClase: 0 })
        })
        .catch(error => {
          if (error.response) {
            console.log("ERROR: ", error.response.data)
          }
        })
    } else {
      console.log("se apreto boton iniciar")
      this.setState({ started: true })
      this.intervalID = setInterval(this.contarTiempoClase, 1000)
    }
  }

  handleEvaluate = () => {
    this.setState({ evaluateModal: true })
  }

  handleFinishClass = () => {
    this.handleStarted()
    this.handleEvaluate()
  }

  handleEvaluateEvent = () => {
    const data = [
      {
        evaluationquestion_id: 1,
        point_id: this.state.evaluationBorg,
      },
      {
        evaluationquestion_id: 2,
        point_id: this.state.evaluationAnimo,
      },
    ]
    console.log("DATA: ", data)

    const token = JSON.parse(localStorage.getItem("token"))
    axios
      .post(
        API_BASE_URL + `class/${this.state.classdate_id}/evaluation`,
        { data },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(res => {
        console.log("res.data: ", res.data.data)
        this.listarClases()
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ", error.response.data)
        }
      })

    this.setState({ evaluateModal: false })
    this.toggle()
  }
  onValueChangeBorg = event => {
    console.log("BORG: ", event.target.value)
    this.setState({ evaluationBorg: event.target.value })
  }
  onValueChangeAnimo = event => {
    console.log("ANIMO: ", event.target.value)
    this.setState({ evaluationAnimo: event.target.value })
  }

  handleChangeFechasCalendario = event => {
    var fechaIni = event.startStr.substr(0, 10)
    var fechaFin = event.endStr.substr(0, 10)
    this.setState({ fechaIniCalendario: fechaIni })
    this.setState({ fechaFinCalendario: fechaFin })

    const token = JSON.parse(localStorage.getItem("token"))

    axios
      .get(API_BASE_URL + `class/me/${fechaIni}/${fechaFin}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log("res.data ini-fin: ", res.data.data)

        var clases = []
        for (var i = 0; i < res.data.data.length; i++) {
          var clase = {}
          clase["id"] = res.data.data[i].classdate_id
          clase["title"] = res.data.data[i].Classdates_belongsTo_class.name
          clase["date"] = res.data.data[i].date.substr(0, 16)
          clase["extendedProps"] = {
            profesor_nombre:
              res.data.data[i].Classdates_belongsTo_class
                .Class_belongsTo_Teacher.Teacher_BelongsTo_User.name,
            profesor_apellido:
              res.data.data[i].Classdates_belongsTo_class
                .Class_belongsTo_Teacher.Teacher_BelongsTo_User.lastname,
            fecha: res.data.data[i].date.substr(0, 10),
            hora: res.data.data[i].date.substring(11, 16),
            descripcion:
              res.data.data[i].Classdates_belongsTo_class.description,
            link: res.data.data[i].link,
            classdate_id: res.data.data[i].classdate_id,
            evaluated: res.data.data[i].Classdate_Has_Evaluation.length,
          }
          clases.push(clase)
        }
        this.setState({ clases: clases })
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ", error.response.data)
        }
      })
  }

  listarClases() {
    var fechaIni = this.state.fechaIniCalendario
    var fechaFin = this.state.fechaFinCalendario

    const token = JSON.parse(localStorage.getItem("token"))

    axios
      .get(API_BASE_URL + `class/me/${fechaIni}/${fechaFin}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log("res.data ini-fin: ", res.data.data)

        var clases = []
        for (var i = 0; i < res.data.data.length; i++) {
          var clase = {}
          clase["id"] = res.data.data[i].classdate_id
          clase["title"] = res.data.data[i].Classdates_belongsTo_class.name
          clase["date"] = res.data.data[i].date.substr(0, 16)
          clase["extendedProps"] = {
            profesor_nombre:
              res.data.data[i].Classdates_belongsTo_class
                .Class_belongsTo_Teacher.Teacher_BelongsTo_User.name,
            profesor_apellido:
              res.data.data[i].Classdates_belongsTo_class
                .Class_belongsTo_Teacher.Teacher_BelongsTo_User.lastname,
            fecha: res.data.data[i].date.substr(0, 10),
            hora: res.data.data[i].date.substring(11, 16),
            descripcion:
              res.data.data[i].Classdates_belongsTo_class.description,
            link: res.data.data[i].link,
            classdate_id: res.data.data[i].classdate_id,
            evaluated: res.data.data[i].Classdate_Has_Evaluation.length,
          }
          clases.push(clase)
        }
        this.setState({ clases: clases })
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ", error.response.data)
        }
      })
  }

  render() {
    return (
      <React.Fragment>
        <EvaluateModal
          show={this.state.evaluateModal}
          onEvaluateClick={this.handleEvaluateEvent}
          onCloseClick={() => this.setState({ evaluateModal: false })}
          onValueChangeBorg={this.onValueChangeBorg}
          onValueChangeAnimo={this.onValueChangeAnimo}
        />
        <div className="page-content">
          <MetaTags>
            <title>Calendario</title>
          </MetaTags>
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Calendario" breadcrumbItem="Calendario" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12">
                        {/* fullcalendar control */}
                        <FullCalendar
                          ref={this.calendarComponentRef}
                          plugins={[
                            BootstrapTheme,
                            dayGridPlugin,
                            interactionPlugin,
                          ]}
                          datesSet={args =>
                            this.handleChangeFechasCalendario(args)
                          }
                          locales={allLocales}
                          locale="es"
                          slotDuration={"00:15:00"}
                          handleWindowResize={true}
                          themeSystem="bootstrap"
                          events={this.state.clases}
                          editable={true}
                          selectable={true}
                          eventClick={this.handleEventClick}
                        />

                        <Modal
                          isOpen={this.state.modal}
                          id="event-modal"
                          size="lg"
                          className="modal-fullscreen-sm-down"
                        >
                          <ModalHeader toggle={this.toggle} tag="h4">
                            Detalle clase
                          </ModalHeader>
                          <ModalBody>
                            <div className="table-responsive">
                              <Table className="table-nowrap mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">Nombre: </th>
                                    <td>{this.state.nombre}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Descripcion: </th>
                                    <td>{this.state.descripcion}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Fecha / Hora: </th>
                                    <td>
                                      {this.state.fecha} / {this.state.hora}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Profesor: </th>
                                    <td>
                                      {this.state.profesor_nombre +
                                        " " +
                                        this.state.profesor_apellido}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              {this.state.link ? (
                                this.state.started && (
                                  <div className="position-relative modal-player-wrapper">
                                    <ReactPlayer
                                      url={this.state.link}
                                      controls={true}
                                      width="100%"
                                      height="100%"
                                      className="position-absolute"
                                    />
                                  </div>
                                )
                              ) : (
                                <Alert color="warning">
                                  Transmisión no disponible
                                </Alert>
                              )}
                            </div>

                            {this.state.tryToExit != null && (
                              <Row>
                                <Col className="text-center">
                                  <Alert color="warning">
                                    Por favor termine la clase antes de salir.
                                  </Alert>
                                </Col>
                              </Row>
                            )}
                            {this.state.link != null && (
                              <Row>
                                {!this.state.started && (
                                  <Col className="">
                                    <div className="mt-1 d-grid">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-block"
                                        onClick={this.handleStarted}
                                      >
                                        {this.state.started
                                          ? "Terminar"
                                          : "Iniciar"}
                                      </button>
                                    </div>
                                  </Col>
                                )}
                                <Col className="">
                                  <div className="mt-1 d-grid">
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-block"
                                      onClick={this.handleFinishClass}
                                      disabled={
                                        this.state.evaluated ? true : false
                                      }
                                    >
                                      Evaluar
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col className="col-12">
                                <div className="mt-1 d-grid">
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-block"
                                    onClick={this.toggle}
                                  >
                                    Cerrar
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </ModalBody>
                        </Modal>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CalendarioUser.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array,
}

export default CalendarioUser
