import PropTypes from "prop-types"
import React, { Component } from "react"
import { Row, Col, Button, Modal, Label } from "reactstrap"
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

/**Doc PDF */
import docPDF from "../../assets/docs/Consentimiento Derechos de Autor - Prod.pdf"

//i18n
import { withTranslation } from "react-i18next"

class SidebarOperador extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
    this.refDiv = React.createRef()
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem("token"))
    const response = await axios.post(API_BASE_URL + `me`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    if (response.status == 200) {
      console.log("ME: ", response.data)
      this.checkInformedConsent()
      this.initMenu()
    } else {
      window.location = "/login"
    }
  }

  async checkInformedConsent() {
    const token = JSON.parse(localStorage.getItem("token"))
    const response = await axios.get(API_BASE_URL + `teacher/informedconsent`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    if (response.status == 200) {
      if (response.data.data.informed_consent) {
        this.setState({ modal: false })
      } else {
        this.setState({ modal: true })
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (
        // HACK: don't pick the parent element
        !items[i].classList.contains("has-arrow") &&
        this.props.location.pathname === items[i].pathname
      ) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  setInformedConsent() {
    const data = {
      informed_consent: 1,
    }

    const token = JSON.parse(localStorage.getItem("token"))
    axios
      .put(
        API_BASE_URL + `teacher/informedconsent`,
        { data },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(response => {
        console.log("response: ", response)
        this.setState({ modal: false })
      })
      .catch(error => {
        if (error) {
          console.log("ERROR: ", error)
        }
      })
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          toggle={this.tog_center}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Consentimiento Informado</h5>
          </div>
          <div className="modal-body">
            <Row>
              <Col sm={12}>
                <Label>
                  Información detallada de Consentimiento informado. Pincha{" "}
                  <a
                    download="Consentimiento Derechos de Autor.pdf"
                    href={docPDF}
                  >
                    aquí
                  </a>{" "}
                  para poder revisar la declaración de responsabilidad sobre el
                  uso de material con derechos de autor. Si esta de acuerdo con
                  el consentimiento informado presiona aceptar.
                </Label>
              </Col>
            </Row>
            <Col xs={12}>
              <div className="d-grid">
                <Button
                  block
                  color="success"
                  onClick={() => {
                    this.setInformedConsent()
                  }}
                >
                  Aceptar
                </Button>
              </div>
            </Col>
          </div>
        </Modal>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title" id="menu_titulo">
                {this.props.t("MENU")}
              </li>
              <li>
                <Link to="/inicio" className="">
                  <i className="fas fa-home" />
                  <span>{this.props.t("Inicio")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="fas fa-video" />
                  <span>{this.props.t("Clases")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/clases-calendario">
                      {this.props.t("Calendario")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/clases-historial">
                      {this.props.t("Historial")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/clases-transmitir">
                      {this.props.t("Transmitir")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow" id="link_pacientes">
                  <i className="fas fa-users" id="icono_pacientes" />
                  <span>{this.props.t("Pacientes")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/pacientes-lista">{this.props.t("Lista")}</Link>
                  </li>
                  <li>
                    <Link to="/pacientes-registrar">
                      {this.props.t("Registrar")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pacientes-grupos">{this.props.t("Grupos")}</Link>
                  </li>
                  <li>
                    <Link to="/pacientes-piloto">
                      {this.props.t("Importar datos")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="far fa-chart-bar" />
                  <span>{this.props.t("Reportes")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/reportes-paciente">
                      {this.props.t("Paciente")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/reportes-clase">{this.props.t("Clase")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/solicitudes-lista" className="">
                  <i className="fas fa-user-plus" />
                  <span>{this.props.t("Solicitudes")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarOperador.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarOperador))
