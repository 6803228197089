import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Modal, Label, Alert } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import { API_BASE_URL } from "helpers/url_helper"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

class PacientesListaCompleta extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      usuarios: [],
      sizePerPage: 10,
      disabledButton: true,
    }
  }

  componentDidMount(){
    this.listarPacientes();
    this.listarCesfams();
  }

  listarPacientes(){
    const token = JSON.parse(localStorage.getItem("token"))
    this.setState({token: token})
    var listaPacientes = []
    axios.get(API_BASE_URL+`patient/all`, {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        console.log("res.data: ",res.data.data);
        for (var i = 0; i < res.data.data.length; i++) {
          if(res.data.data[i].rol_id==1){
            listaPacientes.push(res.data.data[i])
          }
        }
        this.setState({usuarios: listaPacientes});
      })
      .catch(error => {
        if (error.response) {
          console.log("ERROR: ",error.response.data); 
        }
      });
  }

  handleChangeCesfam = event => {
    this.setState({ new_cesfam_value: event.value });
    this.setState({ new_cesfam_label: event.label });
    this.setState({ disabledButton: false });
  };

  async listarCesfams(){
    const token = JSON.parse(localStorage.getItem("token"))
    const response = await axios.get(API_BASE_URL+`cesfam`, {headers: {Authorization: `Bearer ${token}`}})
    if (response.status == 200){
      let cesfams = [];
      for (let cesfam of response.data.data) {
        if(cesfam.cesfamstatus_id==2){
          cesfams.push( {value: cesfam.id, label: cesfam.name+" (Desactivado)" })
        }else{
          cesfams.push( {value: cesfam.id, label: cesfam.name })
        }
      }
      this.setState({ cesfams })
    } 
  }

  setCesfam(){
    /*console.log("new_cesfam_value:", this.state.new_cesfam_value)
    console.log("new_cesfam_label:", this.state.new_cesfam_label)
    console.log("patient_id:", this.state.patient_id)*/

    this.setState({message_modal_error: null})
    this.setState({message_modal_success: null})

    const data = {
      cesfam_id: this.state.new_cesfam_value
    };

    const token = JSON.parse(localStorage.getItem("token"))
    axios.put(API_BASE_URL+`patient/${this.state.patient_id}/cesfam`, {data}, {headers: {Authorization: `Bearer ${token}`}})
      .then(response => {
        //console.log("response: ",response);
        this.setState({message_modal_success: response.data.message})
        this.listarPacientes();
      })
      .catch(error => {
        if (error) {
          //console.log("ERROR: ",error);
          this.setState({message_modal_error: "Ups, error..."})
        }
      });
  }

  formatCesfam = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Col>
        <Button 
          color="primary"
          onClick={() => {
            this.setState({ patient_id: row.User_Has_Patient[0].id });
            this.setState({ name: row.name });
            this.setState({ lastname: row.lastname });
            this.setState({ rut: row.rut });
            this.setState({ cesfam: row.User_Has_Patient[0].Patient_BelongsTo_Cesfam.name });
            this.setState({ modal: true });
            this.setState({ message_modal_error: null});
            this.setState({ message_modal_success: null});
            this.setState({ disabledButton: true });
          }}
        >
          {row.User_Has_Patient[0].Patient_BelongsTo_Cesfam.name}
        </Button>
      </Col>
    )
  }

  render() {
    const columns = [{
      dataField: 'name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'lastname',
      text: 'Apellido',
      sort: true
    }, {
      dataField: 'rut',
      text: 'RUT',
      sort: true
    }, {
      dataField: 'email',
      text: 'e-mail',
      sort: true
    }, {
      dataField: 'User_Has_Patient[0].Patient_BelongsTo_Cesfam.name',
      text: 'Cesfam',
      sort: true,
      formatter: this.formatCesfam,
    }, {
      dataField: 'User_Has_Patient[0].Patient_BelongsTo_Cesfam.Cesfam_BelongsTo_Commune.name',
      text: 'Comuna',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      custom: true,
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pacientes / Lista</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Pacientes / Lista" breadcrumbItem="Pacientes / Lista" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.tog_center}
                      centered={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                          Cambiar cesfam
                        </h5>
                        <button
                          type="button"
                          onClick={() => this.setState({ modal: false })}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>
                          <Col sm={12}>
                            <Label>
                              Nombre: {this.state.name} 
                              <br></br>
                              Apellido: {this.state.lastname}
                              <br></br>
                              Rut: {this.state.rut}
                              <br></br>
                              Cesfam: {this.state.cesfam}
                            </Label>
                            <Col sm={12}>
                              <Label>
                                Nuevo cesfam
                              </Label>
                              <Select
                                name="cesfam"
                                onChange={this.handleChangeCesfam}
                                options={this.state.cesfams}
                                placeholder="Seleccione..."
                                id="cesfam"
                              />
                            </Col>
                          </Col>
                        </Row>
                        <Col xs={12}>
                          <div className="mt-1 d-grid">
                            {this.state.message_modal_error && <Alert color="danger" >{this.state.message_modal_error}</Alert>}
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="mt-1 d-grid">
                            {this.state.message_modal_success && <Alert color="success" >{this.state.message_modal_success}</Alert>}
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="d-grid">
                            <Button
                              block
                              color="success"
                              onClick={() => {
                                this.setCesfam();
                              }}
                              disabled={this.state.disabledButton}
                            >
                              Guardar
                            </Button>
                          </div>
                        </Col>
                      </div>
                      <div className="modal-footer">
                        <Row>
                          <Col>
                            <div className="d-grid">
                              <Button
                                block
                                type="button"
                                onClick={() =>
                                  this.setState({ modal: false })
                                }
                              >
                                Cerrar
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Modal>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.usuarios}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.usuarios}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder={"Buscar"}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      //rowEvents={this.eventosRowOnClick} 
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PacientesListaCompleta